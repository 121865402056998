import React, { useMemo } from 'react';
import { LicenseMapping } from '../../../types/licenses';
import { User } from '../../../types/user';
import { FlashMessage, MessageLevel } from '@axes4/react-common/src/components/FlashMessages';
import { useTranslation } from 'react-i18next';

export type UserLimitWarningProps = {
    licenseMapping: LicenseMapping;
    users: User[];
    limit: number;
}

export const UserLimitWarning: React.FunctionComponent<UserLimitWarningProps> = (props) => {
    const { t } = useTranslation();
    const assignedUsers = useMemo(() => {
        const assignedUserIds = props.licenseMapping.users.map(u => u.licenseUser);
        const assignedGroupIds = props.licenseMapping.usergroups.map(g => g.licenseUser);
        const assignedUsers = props.users.filter(user => {
            if (assignedUserIds.includes(user.id)) {
                return true;
            }
            if (user.userGroupIds.some(groupId => assignedGroupIds.includes(Number(groupId)))) {
                return true;
            }
            return false;
        });
        return assignedUsers.length;
    }, [ props.users, props.licenseMapping.users, props.licenseMapping.usergroups ]);

    if (assignedUsers > props.limit) {
        const message = t('licenseDetail.userLimitWarning', {
            actual: assignedUsers,
            limit: props.limit,
        });

        return (
            <FlashMessage
                message={message}
                variant={MessageLevel.Warning}
            />
        );
    }

    return null;
};
