import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { handledRequest } from '../../../hooks/api';
import { createUserAction, reinviteUserAction } from '../../../state/actions/users';
import { getPreferredAccountId } from '../../../state/reducers/application';
import { apiUserToDomain, PotentialUserData } from '../../../state/transformations/users';
import { multilineTranslate } from '../../../util';
import { SelectOrCreateDialogHandler } from '../../common/AddThroughAutocomplete/SelectOrCreateDialog';
import { User } from '../../../types/user';
import { MessageLevel, useFlashMessages } from '@axes4/react-common/src/hooks/flashmessage';
import { AppDispatch, Store } from '../../../state/store';

export interface SelectOrCreateUserProps {
    title?: string;
    buttonLabel?: string;
    options: User[];
    userExists: (email: string) => boolean;
    onCreate: (user: User) => void;
    onSelect: (user: User) => void;
}

/**
 * @param {SelectOrCreateUserProps} props
 */
export const SelectOrCreateUser: React.FunctionComponent<SelectOrCreateUserProps> = props => {
    const { t } = useTranslation();
    const { addMessage } = useFlashMessages();
    const [ busy, setBusy ] = useState(false);
    const mt = multilineTranslate(t);

    const handleCreate = useCallback((email: string) => {
        setBusy(true);
        handledRequest(`licensing:/accounts/${getPreferredAccountId()}/users`, {
            method: 'POST',
            body: JSON.stringify({
                email,
            }),
        })
            .then((data: PotentialUserData) => {
                const newUser = apiUserToDomain(data);
                props.onCreate(newUser);
                props.onSelect(newUser);
                addMessage({
                    variant: MessageLevel.Success,
                    message: t('user.status.invitationSent', { email }),
                });
            })
            .catch(e => {
                console.error(e);
                addMessage({
                    variant: MessageLevel.Error,
                    message: t('user.status.invitationError'),
                });
            })
            .then(() => {
                setBusy(false);
            });
    }, [ props.onCreate, props.onSelect, addMessage ]);

    const validateUser = (email: string) => {
        const isEmail = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
        if (!isEmail) {
            return t('licenseDetail.users.userSelection.invalidInput');
        }
        if (props.userExists(email)) {
            return t('licenseDetail.users.userSelection.existingUser');
        }
        return true;
    };

    return (
        <SelectOrCreateDialogHandler
            options={props.options}
            title={props.title || t('licenseDetail.users.userSelection.title')}
            content={mt('licenseDetail.users.userSelection.text')}
            inputLabel={t('licenseDetail.users.userSelection.inputLabel')}
            placeholder={input => t('licenseDetail.users.userSelection.placeholder', { input })}
            submitLabel={t('licenseDetail.users.userSelection.ok')}
            cancelLabel={t('licenseDetail.users.userSelection.cancel')}
            onSelect={props.onSelect}
            onCreate={handleCreate}
            getEntityLabel={user => user.email}
            validateCustomOption={validateUser}
            busy={busy}
        >
            {props.buttonLabel || t('licenseDetail.users.assignUser')}
        </SelectOrCreateDialogHandler>
    );
};

type SelectOrCreateUserContainerProps = Omit<SelectOrCreateUserProps, 'userExists'|'onCreate'>
    & Partial<Pick<SelectOrCreateUserProps, 'userExists'>>;

const mapStateToProps = (state: Store, ownProps: SelectOrCreateUserContainerProps): Omit<SelectOrCreateUserProps, 'onCreate'> => ({
    userExists: email => state.users.find(user => user.email === email) !== undefined,
    ...ownProps,
});

const mapDispatchToProps = (dispatch: AppDispatch): Pick<SelectOrCreateUserProps, 'onCreate'> => ({
    onCreate: user => {
        dispatch(reinviteUserAction(user));
        dispatch(createUserAction(user));
    },
});

export const SelectOrCreateUserContainer = connect(mapStateToProps, mapDispatchToProps)(SelectOrCreateUser);
