import React from 'react';
import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import PencilIcon from '@axes4/react-icons/jsx/Pencil';
import clsx from 'clsx';

type OutlineIconButtonProps = {
    buttonProps?: ButtonProps;
    icon?: React.ReactNode;
    label: React.ReactNode;
    onClick: ButtonProps['onClick'];
    className?: string;
    'aria-label'?: string;
}

const useStyles = makeStyles(theme => ({
    button: {
        padding: theme.spacing(1),
        minWidth: 'auto',
        '& > span > span': {
            margin: 0,
        },
    },
    label: {
        position: 'absolute',
        width: 1,
        height: 1,
        overflow: 'hidden',
        opacity: 0,
    },
}));

export const OutlineIconButton = React.forwardRef<HTMLButtonElement, OutlineIconButtonProps>((props, ref) => {
    const icon = props.icon || <PencilIcon/>;
    const classes = useStyles();

    return (
        <Button
            ref={ref}
            variant="outlined"
            color="primary"
            startIcon={icon}
            {...props.buttonProps}
            className={clsx(classes.button, props.className)}
            onClick={props.onClick}
            aria-label={props['aria-label']}
        >
            <span className={classes.label}>{props.label}</span>
        </Button>
    );
});
OutlineIconButton.displayName = 'OutlineIconButton';
