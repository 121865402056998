import React, { useCallback, useState } from 'react';
import Autocomplete, { AutocompleteProps, createFilterOptions } from '@material-ui/lab/Autocomplete';
import { TextField, Box, TextFieldProps } from '@material-ui/core';
import { FilterOptionsState } from '@material-ui/lab';

const filter = createFilterOptions();

export type UseSelectOrCreateOptions<T> = {
    options: T[],
    initialValue?: T,
    getEntityLabel: (entity: T) => string,
    getEntityDisabled?: (entity: T) => boolean,
    autoFocus?: boolean,
    disabled?: boolean,
    placeholder?: string | ((currentInput: string) => string),
    label: string,
    autocompleteProps?: Omit<AutocompleteProps<T>, 'onChange'|'renderInput'|'options'|'getOptionLabel'|'getOptionDisabled'>,
    textFieldProps?: TextFieldProps,
}

export type UseSelectOrCreateResponse<T> = {
    render: () => JSX.Element;
    value?: T & { newValue?: string };
    customValue?: string;
    hasValue: boolean;
    clear: () => void;
}

type AddedOption = { id: number|string, newValue: string, toString(): string, title: string };
type FilteredOption<T> = string | T & {title?: string};

/**
 * Hook to create an Autocomplete element with the possibility to insert a custom value
 */
export const useSelectOrCreate = <T extends { id: number|string, newValue?: string }>(props: UseSelectOrCreateOptions<T>): UseSelectOrCreateResponse<T> => {
    const { getEntityLabel } = props;

    const [ value, setValue ] = useState(props.initialValue || null);
    const [ customValue, setCustomValue ] = useState(null);
    const hasValue = !!value || !!customValue;

    const handleChange = useCallback((e: unknown, newValue: T) => {
        if (typeof newValue !== 'string') {
            setValue(newValue);
            setCustomValue(null);
        }
    }, []);

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
        setValue(null);
        setCustomValue(e.target.value);
    }, []);

    const autocompleteProps = props.autocompleteProps || {};
    const textFieldProps = props.textFieldProps || {};
    const placeholder = props.placeholder || props.autocompleteProps.noOptionsText;

    const clear = useCallback(() => {
        setValue(null);
        setCustomValue(null);
    }, []);

    const getOptionLabel = useCallback((option: string|T): string => {
        if (typeof option === 'string') {
            return option;
        }
        if (option.newValue) {
            return option.newValue;
        }
        return props.getEntityLabel(option);
    }, [ getEntityLabel ]);

    // an option derived from the custom value, i.e. if the user inserts the
    // label of an existing option, this option should be returned
    const lowerCustomValue = customValue ? customValue.toLowerCase() : customValue;
    const customOption = props.options.find(o => {
        const optionLabel = getOptionLabel(o);
        const lowerOptionLabel = optionLabel ? optionLabel.toLowerCase() : optionLabel;
        return lowerOptionLabel === lowerCustomValue;
    });

    const noOptionsText = useCallback((value = customValue) => {
        return typeof placeholder === 'function' ? placeholder(value) : placeholder;
    }, [ customValue, placeholder ]);

    const onRenderOption = useCallback((option: FilteredOption<T>) => {
        if (typeof option !== 'string' && 'title' in option && option.title) {
            return option.title;
        }
        return getOptionLabel(option);
    }, [ getOptionLabel ]);

    const onFilterOptions = useCallback((options: FilteredOption<T>[], params: FilterOptionsState<string | T>): Array<FilteredOption<T>|AddedOption> => {
        const filtered: Array<FilteredOption<T>|AddedOption> = (filter as ReturnType<typeof createFilterOptions<FilteredOption<T>>>)(options, params);
        if (params.inputValue !== '') {
            filtered.push({
                id: -1,
                newValue: params.inputValue,
                title: noOptionsText(`"${params.inputValue}"`),
                toString: () => params.inputValue,
            } as AddedOption);
        }
        return filtered;
    }, [ noOptionsText ]);

    const render = () => (
        <Box position="relative">
            <Autocomplete
                options={props.options}
                getOptionLabel={getOptionLabel}
                getOptionDisabled={props.getEntityDisabled}
                disabled={props.disabled}
                {...autocompleteProps}
                value={value}
                onChange={handleChange}
                freeSolo
                filterOptions={onFilterOptions as any}
                renderOption={onRenderOption}
                renderInput={params => (
                    <TextField
                        {...params}
                        label={props.label}
                        fullWidth
                        autoFocus={props.autoFocus}
                        {...textFieldProps}
                        // workaround as onInputChange throws an error
                        onChange={e => {
                            // @ts-ignore
                            params.inputProps.onChange(e);
                            handleInputChange(e);
                        }}
                    />
                )}
            />
        </Box>
    );
    return {
        render,
        value: customOption ? customOption : value,
        customValue: customOption ? null : customValue,
        hasValue,
        clear,
    };
};
