import { List, makeStyles } from '@material-ui/core';
import React from 'react';
import { CurrentAccount } from './CurrentAccount';
import { CurrentUser } from './CurrentUser';

const useStyles = makeStyles(theme => ({
    dividerTop: {
        paddingTop: theme.spacing(1),
        'li + &': {
            marginTop: theme.spacing(1),
            borderTop: `1px solid ${theme.palette.action.disabledBackground}`,
        },
    },
}));

/**
 * @typedef {{
 *   licenseAccount: string,
 *   user: import('../../../types/accounts').ApplicationUser,
 *   account: import('../../../types/accounts').Account,
 * }} LoginInformationProps
 */

/**
 * @param {LoginInformationProps} props
 */
export const LoginInformation = props => {
    const classes = useStyles();
    return (
        <>
            <List>
                {props.user && (
                    <CurrentUser user={props.user} />
                )}
                {props.account && (
                    <li className={classes.dividerTop}>
                        <CurrentAccount account={props.account}/>
                    </li>
                )}
            </List>
        </>
    );
};
