import React, { useCallback, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EnhancedTable } from '@axes4/react-common/src/components/EnhancedTable';
import { Link, Tooltip, makeStyles } from '@material-ui/core';
import { isPersonalAccount } from '../../../state/transformations/accounts';
import { Account } from '../../../types/accounts';
import { LicenseData, LicenseUuid, Product } from '../../../types/licenses';
import { Store } from '../../../state/store';
import { getProductsByLicense } from '../../../state/transformations/products';

const useStyles = makeStyles(() => ({
    inlineList: {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        '& > li': {
            display: 'inline-block',
        },
    },
}));

export interface LicenseListProps {
    currentAccount: Account;
    licenses: LicenseData[];
    assignedUserCount: {[licenseId: string]: number};
    assignedUsergroupCount: {[licenseId: string]: number};
    containedProducts: {[licenseId: string]: Product[]};
    sortable?: boolean;
    disabled?: boolean;
    onSelected?: (licenseUuids: LicenseUuid[]) => unknown;
    selectable?: boolean;
}

export const LicenseList: React.FunctionComponent<LicenseListProps> = props => {
    const classes = useStyles({});

    const { t } = useTranslation();

    const {
        sortable = true,
    } = props;

    const personalAccount = isPersonalAccount(props.currentAccount);

    const tableData = useMemo(() => props.licenses.map(license => ({
        id: license.id + '',
        selectable: true,
        columns: [
            <Link component={RouterLink} key="link" to={`/licenses/${license.uuid}`}>{license.name}</Link>,
            personalAccount ? null : props.assignedUserCount[license.uuid] || 0,
            personalAccount ? null : props.assignedUsergroupCount[license.uuid] || 0,
            (<ul key="products" className={classes.inlineList}>
                {(props.containedProducts[license.uuid] || []).map(product => (
                    <li key={product.id}>
                        <Tooltip title={product.title} aria-label={product.title}>
                            <img src={product.icon} alt="" width={16} height={16} />
                        </Tooltip>
                    </li>
                ))}
            </ul>),
        ].filter(c => c !== null),
        license: license,
    })), [ props.licenses, props.assignedUserCount, props.assignedUsergroupCount, personalAccount, classes, props.containedProducts ]);

    const handleSelectionChanged = useCallback((items: Array<typeof tableData[number]>) => {
        props.onSelected?.(items.map(item => item.license.uuid));
    }, [ props.onSelected ]);

    return (
        <EnhancedTable
            isCardContent
            getSelectionStatusLabel={(itemCount, totalCount) => t('licenseList.table.selectionStatus', { num: itemCount, total: totalCount })}
            columns={[
                {
                    label: t('licenseList.table.columns.title'),
                    sortable,
                    sorter: (a, b) => a.license.name.localeCompare(b.license.name),
                },
                personalAccount ? null : {
                    label: t('licenseList.table.columns.assignedUsers'),
                    sortable,
                    sorter: (a, b) => Number.parseInt(a.columns[1].toString()) - Number.parseInt(b.columns[1].toString()),
                },
                personalAccount ? null : {
                    label: t('licenseList.table.columns.assignedUsergroups'),
                    sortable,
                    sorter: (a, b) => Number.parseInt(a.columns[2].toString()) - Number.parseInt(b.columns[2].toString()),
                },
                {
                    label: t('licenseList.table.columns.containedProducts'),
                },
            ].filter(c => c !== null)}
            placeholder={t('licenseList.table.placeholder')}
            data={tableData}
            disabled={props.disabled}
            selectable={props.selectable}
            onSelectionChanged={handleSelectionChanged}
        />
    );
};

const mapStateToProps = (state: Store) => {
    const licenses = state.licenses;
    const assignedUserCount: { [licenseId: string]: number; } = {};
    const assignedUsergroupCount: { [licenseId: string]: number; } = {};
    state.licenseMapping.forEach(mapping => {
        assignedUserCount[mapping.licenseUuid] = mapping.users ? mapping.users.length : 0;
        assignedUsergroupCount[mapping.licenseUuid] = mapping.usergroups ? mapping.usergroups.length : 0;
    });
    return {
        licenses,
        assignedUserCount,
        assignedUsergroupCount,
        containedProducts: getProductsByLicense(state),
        currentAccount: state.application.account,
    };
};

export const LicenseListContainer = connect(mapStateToProps)(LicenseList);
