import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { createUsergroupAction } from '../../../state/actions/user-groups';
import { makeRequest } from '../../../hooks/api';
import { apiUsergroupToDomain } from '../../../state/transformations/usergroups';

/**
 * @typedef {{
 *   open: boolean,
 *   onCancel: () => void,
 *   onSubmit: (email: string) => void
 *   fetching: boolean,
 * }} AddUsergroupDialogProps
 */

/**
 * @param {AddUsergroupDialogProps} props
 */
export const AddUsergroupDialog = props => {
    const { t } = useTranslation();
    const [ title, setTitle ] = useState('');
    useEffect(() => {
        setTitle('');
    }, [ props.open ]);
    const onSubmit = e => {
        e.preventDefault();
        props.onSubmit(title);
    };
    return (
        <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="add-user-dialog-title">
            <DialogTitle id="add-user-dialog-title">{t('usergroupList.addUsergroupDialog.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('usergroupList.addUsergroupDialog.description')}</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label={t('usergroupList.addUsergroupDialog.value.label')}
                    autoComplete="false"
                    value={title}
                    onSubmit={onSubmit}
                    onChange={e => setTitle(e.target.value)}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={props.fetching} onClick={props.onCancel}>{t('usergroupList.addUsergroupDialog.cancel')}</Button>
                <Button disabled={props.fetching} onClick={onSubmit} color="primary">{t('usergroupList.addUsergroupDialog.add')}</Button>
            </DialogActions>
        </Dialog>
    );
};

/**
 * @typedef {{
 *   label: string,
 *   licenseAccount: import('../../../types/accounts').Account,
 *   createUsergroup: (usergroup: import('../../../types/usergroup').Usergroup) => void,
 * }} AddUsergroupDialogHandlerProps
 */

/**
 * @param {AddUsergroupDialogHandlerProps} props
 */
export const AddUsergroupDialogHandler = props => {
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const [ fetching, setFetching ] = useState(false);
    const handleClose = () => setDialogOpen(false);
    const handleSubmit = title => {
        setFetching(true);
        makeRequest(`licensing:/accounts/${props.licenseAccount.uuid}/user-groups`, {
            method: 'POST',
            body: JSON.stringify({ name: title }),
        })
            .then(r => {
                setFetching(false);
                if (!r.ok) {
                    console.error('error updating usergroup');
                }
                return r.json();
            })
            .then(d => {
                if (d && d.data) {
                    props.createUsergroup(apiUsergroupToDomain(d.data));
                }
                setDialogOpen(false);
            })
            .catch(e => console.error('error creating usergroup', e));
    };
    return (
        <>
            <Button onClick={() => setDialogOpen(true)} variant="contained" color="primary">{props.label}</Button>
            <AddUsergroupDialog open={dialogOpen} onCancel={handleClose} fetching={fetching} onSubmit={handleSubmit} />
        </>
    );
};

const mapStateToProps = state => ({
    licenseAccount: state.application.account,
});

const mapDispatchToProps = {
    createUsergroup: createUsergroupAction,
};

export const AddUsergroupDialogHandlerContainer = connect(mapStateToProps, mapDispatchToProps)(AddUsergroupDialogHandler);
