export const apiUsergroupToDomain = usergroupData => ({
    id: usergroupData.id,
    title: usergroupData.name,
});

/**
 * @param {import("../../types/usergroup").Usergroup} usergroup
 */
export const domainUsergroupToApi = usergroup => ({
    id: usergroup.id,
    name: usergroup.title,
});
