import React, { useCallback } from 'react';
import { EnhancedTable } from '@axes4/react-common/src/components/EnhancedTable';
import { useTranslation } from 'react-i18next';
import { Button, Box } from '@material-ui/core';
import { handledRequest } from '../../../hooks/api';
import { getPreferredAccountId } from '../../../state/reducers/application';
import { useConfirmation } from '../../../hooks/confirmation';
import { User } from '../../../types/user';
import type { EnhancedTableColumnDefinition, EnhancedTableData } from '@axes4/react-common/src/components/EnhancedTable/EnhancedTable';

type TableData = EnhancedTableData & { user: User };

export interface UserListProps {
    users: User[];
    userSelectable?: (user: User) => boolean;
    columns: EnhancedTableColumnDefinition<TableData>[];
    userListRow: (user: User) => Array<string|React.ReactNode>;
    deleteUser?: (user: User) => void;
    deleteUserFunction?: (user: User) => void;
    reinviteUser: (user: User) => void;
    placeholder?: string;
    deleteUserButton?: string;
    deleteUserConfirmation?: (users: string) => string;
}

const UserList: React.FunctionComponent<UserListProps> = props => {
    const { t } = useTranslation();
    const { confirm, render: renderConfirmation } = useConfirmation();

    const deleteUser = useCallback((user: User) => {
        if (props.deleteUserFunction) {
            props.deleteUserFunction(user);
        } else {
            handledRequest(`licensing:/accounts/${getPreferredAccountId()}/users/${user.id}`, {
                method: 'DELETE',
            })
                .then(() => {
                    props.deleteUser(user);
                })
                .catch(e => {
                    console.error(e);
                });
        }
    }, [ props.deleteUserFunction, props.deleteUser ]);

    const deleteUserConfirmation = props.deleteUserConfirmation || (users => t('userList.table.confirmDelete', { users }));

    return (
        <>
        {renderConfirmation()}
        <EnhancedTable<TableData>
            isCardContent
            columns={props.columns}
            data={props.users.map(user => ({
                id: user.id.toString(),
                checkboxLabel: user.name || user.email,
                selectable: props.userSelectable ? props.userSelectable(user) : true,
                columns: props.userListRow(user),
                user: user,
            }))}
            placeholder={props.placeholder || t('userList.table.placeholder')}
            getSelectionStatusLabel={(itemCount, totalCount) => t('userList.table.selectionStatus', { num: itemCount, total: totalCount })}
            selectable
            tableHeadActions={selectedItems => {
                const deleteSelected = () =>
                    confirm(deleteUserConfirmation(selectedItems.map(user => user.user.email).join(', ')), '')
                        .then(confirmed => {
                            if (confirmed) {
                                selectedItems.forEach(user => deleteUser(user.user));
                            }
                        });
                const canInvite = () => selectedItems.length > 0 && selectedItems.every(user => !user.user.confirmed);
                const reinviteSelected = () => selectedItems.forEach(user => props.reinviteUser(user.user));
                return (
                    <>
                        {canInvite() && (
                            <Box marginRight={1} component="span">
                                <Button
                                    onClick={reinviteSelected}
                                    size="small"
                                    variant="contained"
                                >
                                    {t('userList.table.reinviteSelected')}
                                </Button>
                            </Box>
                        )}
                        {(
                            <Button
                                disabled={selectedItems.length == 0}
                                size="small"
                                variant="contained"
                                onClick={deleteSelected}
                            >
                                {props.deleteUserButton || t('userList.table.deleteSelected')}
                            </Button>
                        )}
                    </>
                );
            }}
        />
        </>
    );
};

export { UserList };
