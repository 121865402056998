import { Card, CardContent, LinearProgress, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../App/Layout';

/**
 * @typedef {{
 *   text: string,
 *   content?: string,
 * }} LoadingProps
 */

/**
 * @param {LoadingProps} props
 */
export const Loading = (props) => {
    return (
        <Card>
            <CardContent>
                <Typography paragraph variant="body1">{props.text}</Typography>
            </CardContent>
            <CardContent>
                <LinearProgress color="primary"/>
            </CardContent>
            {props.content && (
                <CardContent>
                    {props.content}
                </CardContent>
            )}
        </Card>
    );
};

/**
 * @param {Partial<LoadingProps>} props
 */
export const LoadingView = (props) => {
    const { t } = useTranslation();
    return (
        <Layout pageTitle={t('loading.pageTitle')}>
            <Loading text={props.text || t('loading.text')}/>
        </Layout>
    );
};
