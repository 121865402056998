import i18n from '../../i18n';
import { APPLICATION_ACTIONS, setFetchingAction } from '../actions/application';
import { initializeUsersAction } from '../actions/users';
import { initializeUsergroupsAction } from '../actions/user-groups';
import { ACCOUNT_ACTIONS } from '../actions/accounts';
import { initializeLicenseMappingsAction, initializeLicensesAction } from '../actions/licenses';

const initialApplicationState = {
    account: null,
    user: null,
    loading: true,
    fetching: 0,
    authenticated: false,
    loadingErrors: [],
};

const preferredAccountStorageKey = 'cla';

export const getPreferredAccountId = () => {
    return localStorage.getItem(preferredAccountStorageKey);
};

export const setPreferredAccountId = accountId => {
    localStorage.setItem(preferredAccountStorageKey, accountId);
};

export const unsetPreferredAccountId = () => {
    localStorage.removeItem(preferredAccountStorageKey);
};

export const application = (state = initialApplicationState, action) => {
    switch (action.type) {
        case ACCOUNT_ACTIONS.SWITCH_ACCOUNT:
            /** @type {import('../asyncDispatchMiddleware').ActionWithDispatch<import('../actions/accounts').SwitchAccountAction>} action */
            setPreferredAccountId(action.payload.uuid);
            action.asyncDispatch(setFetchingAction(4));
            action.asyncDispatch(initializeUsersAction(action.payload.uuid));
            action.asyncDispatch(initializeUsergroupsAction(action.payload.uuid));
            action.asyncDispatch(initializeLicensesAction(action.payload.uuid));
            action.asyncDispatch(initializeLicenseMappingsAction(action.payload.uuid));
            return { ...state, account: action.payload };
        case APPLICATION_ACTIONS.SET_CURRENT_LANGUAGE:
            i18n.changeLanguage(action.payload);
            return state;
        case APPLICATION_ACTIONS.SET_CURRENT_ACCOUNT:
            return { ...state, account: action.payload };
        case APPLICATION_ACTIONS.SET_CURRENT_USER:
            return { ...state, user: action.payload };
        case APPLICATION_ACTIONS.SET_AUTHENTICATED:
            return { ...state, authenticated: action.payload };
        case APPLICATION_ACTIONS.SET_LOADING:
            return { ...state, loading: action.payload };
        case APPLICATION_ACTIONS.SET_FETCHING:
            return { ...state, fetching: action.payload };
        case APPLICATION_ACTIONS.FETCH_COMPLETE:
            return { ...state, fetching: state.fetching - 1 };
        case APPLICATION_ACTIONS.ADD_LOADING_ERROR:
            return { ...state, loadingErrors: [ ...state.loadingErrors, action.payload ] };
    }
    return state;
};
