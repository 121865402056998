let config = null;
/**
 * @returns {{app: object, auth: object}}
 */
export const getConfig = () => {
    if (config === null) {
        config = window.__config;
    }
    return config;
};
