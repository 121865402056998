import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, LinearProgress, Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FlashMessage } from '@axes4/react-common/src/components/FlashMessages';
import { multilineTranslate } from '../../../util';
import { handledRequest } from '../../../hooks/api';
import { getPreferredAccountId } from '../../../state/reducers/application';

const STATES = {
    INITIAL: 1,
    NEW_KEY: 2,
    OLD_KEY: 3,
    ACTIVATION_SUCCESS: 4,
    ACTIVATION_ERROR: 5,
};

const useStyles = makeStyles(() => ({
    root: {
        width: 500,
    },
}));

/**
 * @typedef {{
 *   licenseUuid: import('../../../types/util').Uuid,
 *   accountUuid?: import('../../../types/util').Uuid,
 *   available: boolean,
 *   type?: "default"|"migration",
 *   hash?: string,
 * }} AddLicenseRequestResponse
 */

/**
 * @typedef {{
 *   open: boolean,
 *   onCancel: () => void,
 *   onSuccess: (license: import('../../../types/licenses').LicenseData) => void,
 *   accounts: import('../../../types/accounts').Account[],
 *   title: string,
 * }} AddLicenseDialogProps
 */

/**
 * @param {AddLicenseDialogProps} props
 */
export const AddLicenseDialog = props => {
    const { t, i18n } = useTranslation();
    const mt = multilineTranslate(t);
    const classes = useStyles();
    const [ state, setState ] = useState(STATES.INITIAL);
    const [ licenseKey, setLicenseKey ] = useState('');
    const [ isValidKey, setIsValidKey ] = useState(true);
    const [ checkedKey, setCheckedKey ] = useState(false);
    const [ checkKeyError, setCheckKeyError ] = useState('');
    const [ isActivating, setIsActivating ] = useState(false);
    const [ activationSuccess, setActivationSuccess ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ licenseRequestHash, setLicenseRequestHash ] = useState('');
    // const { loading, startRequest } = useApi('/registerLicense', { fetchOnLoad: false });
    useEffect(() => {
        if (props.open) {
            setLicenseKey('');
            setCheckedKey(false);
            setIsValidKey(true);
            setIsActivating(false);
            setState(STATES.INITIAL);
        }
    }, [ props.open ]);
    const checkKey = e => {
        e.preventDefault();
        setLoading(true);
        setIsValidKey(true);
        setCheckedKey(false);
        handledRequest(`licensing:/accounts/${getPreferredAccountId()}/licenses/${encodeURIComponent(licenseKey)}/request`)
            .then((/** @type {AddLicenseRequestResponse} d */ d) => {
                setLoading(false);
                setIsValidKey(d.available);
                setLicenseRequestHash(d.hash);
                setCheckedKey(true);
                if (!d.available) {
                    let customMessage = null;
                    if (d.reason) {
                        const translationKey = 'registerLicense.' + d.reason;
                        if (i18n.exists(translationKey)) {
                            customMessage = mt(translationKey);
                        }
                    }
                    if (customMessage) {
                        setCheckKeyError(customMessage);
                    } else if (d.accountUuid) {
                        if (d.accountUuid === getPreferredAccountId()) {
                            setCheckKeyError(mt('registerLicense.alreadyRegisteredThisAccount'));
                        } else {
                            const matchingAccount = (props.accounts || []).find(a => a.uuid === d.accountUuid);
                            if (matchingAccount) {
                                setCheckKeyError(mt('registerLicense.alreadyRegisteredAccount', { account: matchingAccount.name }));
                            } else {
                                setCheckKeyError(mt('registerLicense.alreadyRegistered'));
                            }
                        }
                    } else {
                        setCheckKeyError(mt('registerLicense.invalidKey'));
                    }
                }
                setState(d.available ? d.type === 'migration' ? STATES.OLD_KEY : STATES.NEW_KEY : STATES.INITIAL);
            })
            .catch(console.error);
    };
    const activateKey = e => {
        e.preventDefault();
        setIsActivating(true);
        setActivationSuccess(false);
        handledRequest(`licensing:/accounts/${getPreferredAccountId()}/licenses`, {
            method: 'POST',
            body: JSON.stringify({
                licenseUuid: licenseKey,
                hash: licenseRequestHash,
            }),
        })
            .then((/** @type {import('../../../types/licenses').LicenseData?} d */d) => {
                if (d) {
                    setIsActivating(false);
                    setActivationSuccess(true);
                    setState(STATES.ACTIVATION_SUCCESS);
                    if (d.uuid && d.id) {
                        props.onSuccess(d);
                    }
                }
            })
            .catch(e => {
                setIsActivating(false);
                setActivationSuccess(false);
                setState(STATES.ACTIVATION_ERROR);
                console.error(e);
            });
    };
    const handleClose = () => {
        if (!loading && checkedKey && isValidKey && activationSuccess && state !== STATES.ACTIVATION_ERROR) {
            props.onSuccess(null);
            return;
        }
        props.onClose();
    };
    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="register-license-dialog-title"
            PaperProps={{
                className: classes.root,
            }}
        >
            <DialogTitle id="register-license-dialog-title">{props.title}</DialogTitle>

            {state === STATES.INITIAL && (
                <form onSubmit={checkKey}>
                    <DialogContent>
                        <DialogContentText>{mt('registerLicense.description')}</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label={t('registerLicense.licenseKey')}
                            autoComplete="false"
                            value={licenseKey}
                            onSubmit={checkKey}
                            onChange={e => setLicenseKey(e.target.value)}
                            fullWidth
                        />
                        {!isValidKey && (
                            <FlashMessage variant="error" message={checkKeyError} action={null}/>
                        )}
                        {loading && (
                            <Box marginTop={1}>
                                <LinearProgress color="primary" />
                                <DialogContentText>
                                    {mt('registerLicense.checkingKey')}
                                </DialogContentText>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onCancel} disabled={loading}>{t('general.cancel')}</Button>
                        <Button onClick={checkKey} disabled={loading || !licenseKey} color="primary">{t('general.confirm')}</Button>
                    </DialogActions>
                </form>
            )}

            {state === STATES.OLD_KEY && (
                <form onSubmit={activateKey}>
                    <DialogContent>
                        <DialogContentText>
                            {mt('registerLicense.oldKey')}
                        </DialogContentText>
                        {isActivating && (
                            <Box marginTop={1}>
                                <LinearProgress color="primary" />
                                <DialogContentText>
                                    {mt('registerLicense.activating')}
                                </DialogContentText>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onCancel} disabled={isActivating}>{t('general.cancel')}</Button>
                        <Button onClick={activateKey} disabled={isActivating} color="primary">{t('general.confirm')}</Button>
                    </DialogActions>
                </form>
            )}

            {state === STATES.NEW_KEY && (
                <form onSubmit={activateKey}>
                    <DialogContent>
                        <DialogContentText>
                            {mt('registerLicense.validKey')}
                        </DialogContentText>
                        {isActivating && (
                            <Box marginTop={1}>
                                <LinearProgress color="primary" />
                                <DialogContentText>
                                    {mt('registerLicense.activating')}
                                </DialogContentText>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={props.onCancel} disabled={isActivating}>{t('general.cancel')}</Button>
                        <Button onClick={activateKey} disabled={isActivating} color="primary">{t('general.confirm')}</Button>
                    </DialogActions>
                </form>
            )}

            {state === STATES.ACTIVATION_SUCCESS && (
                <>
                    <DialogContent>
                        <DialogContentText>
                            {/* TODO */}
                            {mt('registerLicense.success')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} disabled={isActivating}>{t('general.ok')}</Button>
                    </DialogActions>
                </>
            )}

            {state === STATES.ACTIVATION_ERROR && (
                <>
                    <DialogContent>
                        <DialogContentText>
                            {mt('registerLicense.error')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} disabled={isActivating}>{t('general.cancel')}</Button>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
};

/**
 * @typedef {{
 *   accounts: import('../../../types/accounts').Account[],
 *   account: import('../../../types/accounts').Account,
 *   handleLicenseCreation: (licenseData: import('../../../types/licenses').LicenseData<Date>) => void,
 * }} AddLicenseDialogHandlerProps
 */

/**
 * @param {AddLicenseDialogHandlerProps} props
 */
export const AddLicenseDialogHandler = props => {
    const [ dialogOpen, setDialogOpen ] = useState(false);
    const { t } = useTranslation();
    const handleClose = () => setDialogOpen(false);
    const handleSuccess = license => {
        props.handleLicenseCreation(license);
        setDialogOpen(false);
    };
    return (
        <>
            <Button onClick={() => setDialogOpen(true)} variant="contained" color="primary">{props.children}</Button>
            <AddLicenseDialog
                open={dialogOpen}
                onCancel={handleClose}
                onSuccess={handleSuccess}
                accounts={props.accounts}
                title={t(`registerLicense.title.${props.account.type}`)}
            />
        </>
    );
};
