import { Dialog, DialogContent, DialogContentText, Menu, Avatar, List } from '@material-ui/core';
import React, { useState, forwardRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTraceUpdate } from '../../../hooks/misc';
import { AccountListContainer } from './AccountList';
import { AccountListItem } from './AccountListItem';
import UserIcon from '@axes4/react-icons/jsx/User';
import TeamIcon from '@axes4/react-icons/jsx/Users3';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/**
 * @typedef {{
 *   account: import('../../../types/accounts').Account,
 * }} CurrentAccountProps
 */

/**
 * @param {CurrentAccountProps} props
 */
export const CurrentAccount = forwardRef((props, ref) => {
    const { t } = useTranslation();
    useTraceUpdate(props);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const handleMenuOpen = ev => {
        setAnchorEl(ev.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    if (!props.account) {
        return (
            <Dialog
                open
                title={t('account.noAccountSelected.title')}
            >
                <DialogContent>
                    <DialogContentText>{t('account.noAccountSelected.text')}</DialogContentText>
                </DialogContent>
                <List>
                    <AccountListContainer/>
                </List>
            </Dialog>
        );
    }
    return (
        <>
            <AccountListItem
                button
                aria-controls="account-selection-menu"
                aria-haspopup="true"
                onClick={handleMenuOpen}
                title={props.account.name}
                subtitle={t(`account.type.${props.account.type}.title`)}
                avatar={
                    <Avatar>
                        {{
                            'personal': <UserIcon/>,
                            'team': <TeamIcon/>,
                        }[props.account.type]}
                    </Avatar>
                }
                after={<ExpandMoreIcon/>}
            />
            <Menu
                id="account-selection-menu"
                keepMounted
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                ref={ref}
            >
                <AccountListContainer onSelect={handleMenuClose}/>
            </Menu>
        </>
    );
});

/**
 * @typedef {{
 * }} CurrentAccountContainerProps
 */

/**
 * @param {} state
 * @returns {CurrentAccountProps}
 */
const mapStateToProps = (state) => {
    return {
        account: state.application.account,
    };
};

export const CurrentAccountContainer = connect(mapStateToProps, null, null, { forwardRef: true })(CurrentAccount);
