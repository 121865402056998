import React, { useState } from 'react';
import { AccountListItem } from './AccountListItem';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@material-ui/core';
import { userNameShorthand } from '@axes4/react-common/src/util/strings';
import { stringToHslColor } from '@axes4/react-common/src/util/colors';
import { ExternalNavigationItem, NavigationItem } from '@axes4/react-common/src/components/Layout';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { getConfig } from '../../../config';
const { profileUrl } = getConfig().app;

/**
 * @typedef {{
 *   user: import('../../../types/accounts').ApplicationUser,
 * }} CurrentUserProps
 */

/**
 * @param {CurrentUserProps} props
 */
export const CurrentUser = props => {
    const { t } = useTranslation();
    const [ showProfileActions, setShowProfileActions ] = useState(false);
    return (
        <>
            <li>
                <AccountListItem
                    subtitle={t('login.loggedin as')}
                    title={props.user.email}
                    avatar={
                        <Avatar style={{ backgroundColor: stringToHslColor(props.user.email, 40, 50) }}>
                            {userNameShorthand(props.user.email)}
                        </Avatar>
                    }
                    onClick={() => setShowProfileActions(s => !s)}
                    button
                    after={showProfileActions ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                />
            </li>
            {showProfileActions && (
                <>
                    <ExternalNavigationItem
                        url={profileUrl}
                        inset
                    >
                        {t('login.profileLink')}
                    </ExternalNavigationItem>
                    <NavigationItem path="/logout" inset>{t('login.logout')}</NavigationItem>
                </>
            )}
        </>
    );
};
