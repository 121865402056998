import { store } from '../store';


export const USERGROUPS_ACTIONS = Object.freeze({
    INITIALIZE: 'initialize_usergroups',
    SET: 'set_usergroups',
    CREATE: 'create_usergroup',
    UPDATE: 'update_usergroup',
    DELETE: 'delete_usergroup',
});

export const initializeUsergroupsAction = (accountId) => ({
    type: USERGROUPS_ACTIONS.INITIALIZE,
    payload: {
        accountId,
    },
});

/**
 * Initialize the store with the given usergroups
 * @param {import('../../types/usergroup').Usergroup[]} data
 */
export const setUsergroupsAction = (data) => ({
    type: USERGROUPS_ACTIONS.SET,
    payload: data,
});

/**
 * @param {import('../types/usergroup').Usergroup} usergroup
 */
export const createUsergroupAction = usergroup => ({
    type: USERGROUPS_ACTIONS.CREATE,
    payload: usergroup,
});

/**
 * @param {import('../types/usergroup').Usergroup} usergroup
 */
export const createUsergroup = usergroup => store.dispatch(createUsergroupAction(usergroup));

/**
 * @param {import('../types/usergroup').Usergroup} usergroup
 */
export const updateUsergroupAction = usergroup => ({
    type: USERGROUPS_ACTIONS.UPDATE,
    payload: usergroup,
});

/**
 * @param {import('../types/usergroup').Usergroup} usergroup
 */
export const updateUsergroup = usergroup => store.dispatch(updateUsergroupAction(usergroup));

/**
 * @param {import('../types/util').Uuid} usergroupId
 */
export const deleteUsergroupAction = usergroupId => ({
    type: USERGROUPS_ACTIONS.DELETE,
    payload: usergroupId,
});

/**
 * @param {import('../types/util').Uuid} usergroupId
 */
export const deleteUsergroup = usergroupId => store.dispatch(deleteUsergroupAction(usergroupId));

/**
 * @param {import('../../types/usergroup').Usergroup[]} usergroups
 */
export const sortUsergroupsByName = usergroups => usergroups.sort((a, b) => a.title.localeCompare(b.title));
