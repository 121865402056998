import React, { CSSProperties, useEffect, useState } from 'react';
import { EntityInformation } from '../../common/EntityInformation/EntityInformation';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, FormControlLabel, List, ListItem, makeStyles } from '@material-ui/core';
import { MessageLevel, useFlashMessages } from '@axes4/react-common/src/hooks/flashmessage';
import { useEditMode } from '../../../hooks/editMode';
import { EditCardButtonWrapper } from '../../common/EditCardButton';
import { handledRequest, makeRequest } from '../../../hooks/api';
import { getPreferredAccountId } from '../../../state/reducers/application';
import { CheckedStateIcon } from '../../common/CheckedStateIcon';
import { User } from '../../../types/user';
import { Role } from '../../../types/role';

const useStyles = makeStyles(theme => ({
    inviteButton: {
        marginLeft: theme.spacing(2),
    },
}));

export interface UserInfoProps {
    user: User;
    onSave: (user: User) => void;
    roles: Role[];
    editable: boolean;
}

const checkIconStyles: CSSProperties = { marginLeft: -8, marginRight: 8 };

export const UserInfo: React.FunctionComponent<UserInfoProps> = props => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [ busy, setBusy ] = useState(false);
    const [ invitationBusy, setInvitationBusy ] = useState(false);
    const { addMessage } = useFlashMessages();

    const {
        renderEditingButtons,
        renderTriggerButton: editModeButton,
        disable: disableEditMode,
        active: editMode,
        state: userInfo,
        set: setUserInfo,
        commit: updateEditMode,
    } = useEditMode(props.user);

    useEffect(() => {
        setUserInfo(props.user);
    }, [ props.user ]);

    const handleUpdateComplete = () => {
        disableEditMode(true);
        updateEditMode();
        setBusy(false);
        props.onSave(userInfo);
    };

    const handleSave = (e?: React.FormEvent) => {
        if (!props.editable) {
            return;
        }
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        const addRoles = userInfo.roles.filter(roleId => props.user.roles.indexOf(roleId) < 0);
        const removeRoles = props.user.roles.filter(roleId => userInfo.roles.indexOf(roleId) < 0);
        let necessaryRequests = addRoles.length + removeRoles.length;
        if (necessaryRequests > 0) {
            setBusy(true);
        } else {
            disableEditMode(true);
        }
        addRoles.forEach(role => {
            makeRequest(`licensing:/accounts/${getPreferredAccountId()}/users/${props.user.id}/roles`, {
                method: 'POST',
                body: JSON.stringify({ role }),
            })
                .then(r => {
                    if (r.ok) {
                        if (--necessaryRequests === 0) {
                            handleUpdateComplete();
                        }
                    }
                });
        });
        removeRoles.forEach(role => {
            makeRequest(`licensing:/accounts/${getPreferredAccountId()}/users/${props.user.id}/roles/${role}`, {
                method: 'DELETE',
            })
                .then(r => {
                    if (r.ok) {
                        if (--necessaryRequests === 0) {
                            handleUpdateComplete();
                        }
                    }
                });
        });
    };
    const handleReinvite = () => {
        setInvitationBusy(true);
        handledRequest(`licensing:/accounts/${getPreferredAccountId()}/users`, {
            method: 'POST',
            body: JSON.stringify({
                email: props.user.email,
            }),
        })
            .then(() => {
                setInvitationBusy(false);
                addMessage({
                    variant: MessageLevel.Success,
                    message: t('user.status.invitationSent', { email: props.user.email }),
                });
            })
            .catch(() => {
                setInvitationBusy(false);
                addMessage({
                    variant: MessageLevel.Error,
                    message: t('user.status.invitationError'),
                });
            });
    };
    const toggleRole = roleId => () => {
        if (userInfo.roles.indexOf(roleId) >= 0) {
            setUserInfo(info => ({ ...info, roles: info.roles.filter(id => id !== roleId) }));
        } else {
            setUserInfo(info => ({ ...info, roles: [ ...info.roles, roleId ] }));
        }
    };
    const roleSelection = () => {
        if (editMode) {
            return (
                <List dense>
                    {props.roles.map(role => (
                        <ListItem key={role}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={userInfo.roles.indexOf(role) >= 0}
                                        onChange={toggleRole(role)}
                                        disabled={!editMode}
                                    />
                                }
                                label={i18n.exists('user.roles.' + role) ? t('user.roles.' + role) : role}
                            />
                        </ListItem>
                    ))}
                </List>
            );
        } else {
            if (userInfo.roles.length === 0) {
                return <span>{t('userDetail.info.noRoles')}</span>;
            } else {
                return (
                    <List dense>
                        {userInfo.roles.map(role => {
                            return (
                                <ListItem key={role}>
                                    <CheckedStateIcon checked style={checkIconStyles}/>
                                    {i18n.exists('user.roles.' + role) ? t('user.roles.' + role) : role}
                                </ListItem>
                            );
                        })}
                    </List>
                );
            }
        }
    };
    return (
        <form onSubmit={handleSave}>
            {props.editable && <EditCardButtonWrapper isCardContent>{editModeButton({ label: t('general.edit') })}</EditCardButtonWrapper>}
            <EntityInformation
                data={{
                    [t('userDetail.info.email')]: props.user.email,
                    [t('userDetail.info.status')]: props.user.confirmed ? t('user.status.active') : (
                        <div>
                            {t('user.status.invited')}
                            <Button
                                className={classes.inviteButton}
                                onClick={handleReinvite}
                                size="small"
                                color="primary"
                                variant="contained"
                                disabled={invitationBusy}
                            >
                                {t('user.status.reinvite')}
                            </Button>
                        </div>
                    ),
                    [t('userDetail.info.roles')]: roleSelection(),
                }}
            />
            {renderEditingButtons(handleSave, busy)}
        </form>
    );
};
