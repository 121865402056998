module.exports = {
    account: {
        createAccount: {
            inputLabel: 'Team license account name',
            link: 'Create team account',
            submit: 'Create team license account',
            text: 'Enter a name for the new team license account.',
            title: 'Create team license account',
        },
        info: {
            defaultLanguage: {
                description: 'Invited users are addressed in this language.',
                label: 'Default language',
            },
            description: {
                description: 'To better differentiate your license accounts',
                label: 'Short description',
            },
            introText: 'Here you will find all settings for the currently selected license account.',
            name: {
                label: 'Name',
            },
            title: 'License account',
        },
        messages: {
            created: 'The team license account {{name}} has been created.',
            creationError: 'The team license account could not be created.',
            error: 'Changes could not be saved',
            saved: 'Changes have been saved.',
        },
        noAccountSelected: {
            text: 'No license account has been selected yet. Please choose a license account from the list or create a new one.',
            title: 'Select license account',
        },
        pageTitle: 'Settings',
        type: {
            personal: {
                title: 'Personal license account',
            },
            team: {
                title: 'Team license account',
            },
        },
    },
    application: {
        title: 'License administration',
    },
    errorPage: {
        description: 'There was an error. \nTry to reload the page. \n\nIf the error persists, contact support.',
        hideDetails: 'Hide error details',
        showDetails: 'Show error details',
        title: 'Internal error',
    },
    general: {
        add: 'Add',
        cancel: 'Cancel',
        changesSaved: 'Changes have been saved.',
        confirm: 'Confirm',
        delete: 'Delete',
        discard: 'Discard',
        discardChanges: 'There are unsaved changes. Should these be discarded?',
        edit: 'Edit',
        move: 'Move',
        no: 'No',
        ok: 'OK',
        save: 'Save',
        switchView: 'Switch to view "{{label}}"',
        yes: 'Yes',
    },
    licenseAssignment: {
        columns: {
            deactivate: 'Lizenz deaktivieren',
            license: 'License',
        },
        deactivate: {
            ariaLabel: 'deactivate {{license}}',
            confirmationAction: 'Deaktivieren',
            confirmationMessage1: 'If you click "Deactivate", the selected license will be deactivated for the current user. You can subsequently assign the license to another user.',
            confirmationMessage2: 'Note that this operation is allowed only once per user and license within an activation period. (The activation period is usually 30 days).',
            confirmationTitle: 'Deactivate license for current user',
            error: 'License <strong>{{license}}</strong> could not be deactivated.',
            success: 'License <strong>{{license}}</strong> has been deactivated.',
            tooltip: 'Deactivate',
        },
        messages: {
            notSaved: 'The license mappings could not be saved.',
            updated: 'The license mappings have been saved.',
        },
        usergroupsLink: 'User group {{label}}, edit assignment',
        usersLink: 'User {{label}}, edit assignment',
    },
    licenseDetail: {
        info: {
            activatedUserCount: 'Number of activated users',
            deviceCount: 'Maximum number of machines',
            devicesPerUserCount: 'Maximum number of machines per user',
            expireDate: 'Expiration date',
            key: 'License key',
            noExpireDate: 'None',
            products: 'Included products',
            title: 'License information',
            unlimited: 'Unlimited',
            userCount: 'Maximum number of users',
            userCountMethod: 'User type',
            userType: {
                1: 'axes4 ID',
                2: 'Windows Domain User',
                3: 'Manual',
            },
        },
        mapping: {
            productDisabled: 'Disabled',
            productEnabled: 'Enabled',
            table: {
                actionLabel: 'Action',
                dragHandleLabel: 'Drag',
                removeSelectedUsergroups: 'Remove user groups',
                removeSelectedUsers: 'Remove users',
                selectAll: 'Select all',
                selectionStatus: 'Selected {{num}} of {{total}}',
                usergroupsTitle: 'User group',
                usersTitle: 'User',
            },
            title: 'Mapping',
        },
        userLimitWarning: 'More users are mapped to this license than the maximum number permitted ({{actual}}/{{limit}}). Reduce the number of mapped users to avoid licensing problems.',
        usergroups: {
            addGroup: {
                label: 'Select user group',
                placeholder: 'No user groups have been found',
                submit: 'Define assignment',
            },
            assignUsergroup: 'Assign user group',
            createGroup: 'Create user group',
            title: 'User groups',
            usergroupSelection: {
                cancel: 'Cancel',
                existingUser: 'User group has been assigned already',
                inputLabel: 'Title',
                ok: 'Add user group',
                placeholder: 'User group {{input}} ist beeing created',
                text: 'Select a user group or create a new one.',
                title: 'Add user group to license',
            },
        },
        users: {
            addUser: {
                label: 'Select user',
                placeholder: 'No users found',
                submit: 'Define assignment',
            },
            assignUser: 'Add user',
            inviteUser: 'Invite user',
            title: 'User',
            userSelection: {
                cancel: 'Cancel',
                existingUser: 'User has been added already.',
                inputLabel: 'Email',
                invalidInput: 'No valid email address',
                ok: 'Add user',
                placeholder: 'User {{input}} is being invited to the system',
                text: 'Select a user',
                title: 'Add user to this license',
            },
        },
    },
    licenseList: {
        pageTitle: 'Licenses',
        table: {
            columns: {
                assignedUsergroups: 'Added user groups',
                assignedUsers: 'Added user',
                containedProducts: 'Included products',
                title: 'Licenses',
            },
            deleteSelected: 'Delete selected',
            linkTitle: 'Edit license {{item}}',
            placeholder: 'No licenses found',
            selectItem: 'Select {item}}',
            selectionStatus: '{{num}} of {{total}} have been selected.',
        },
    },
    loading: {
        authenticating: 'Logging in ...',
        errorFetching: 'The ressource {{resource}} could not be loaded ({{status}}).',
        fetching: 'License account is being loaded ...',
        loading: 'User is being loaded ...',
        pageTitle: 'Application is being loaded ...',
        text: 'Content is being loaded ...',
    },
    loggedOut: {
        action: 'To axes4 Login',
        text: 'Your session has expired. Log in again to continue.',
        title: 'Logged out',
    },
    login: {
        'loggedin as': 'Logged in as',
        logout: 'Logout',
        profileLink: 'Edit profile',
    },
    manualActivation: {
        activate: 'Activate',
        activationErrors: 'There were errors when processing the activation requests',
        description: 'You can get the activation files for your offline devices in three steps here.<br/><strong>Prerequisite</strong>: You have generated a file with an offline activation request there.',
        downloadFile: 'Save file "{{file}}"',
        downloadableFiles: 'Download the activation files',
        error: 'An error occurred.',
        files: {
            dropzone: 'Drop files here to select them',
            errors: {
                disallowedType: 'The type of file "{{file}}" is not supported. Please select a JSON file.',
                exists: 'The file "{{file}}" is already selected.',
                fileSize: 'The file "{{file}}" is too large. Please confirm whether you have selected the correct file.',
            },
            select: 'Select files',
            selectedFiles: 'Selected files',
        },
        loading: 'Activation files are being prepared',
        pageTitle: 'Manual activation',
        steps: '<li>Select the file with the activation request. You can also select multiple files (from multiple offline devices).</li><li>Select the products you want to activate on the offline computers.</li><li>Click Activate. You will receive one activation file per computer and product for download, which you can transfer to the offline computer via mobile data carrier.</li>',
    },
    notFound: {
        button: 'Back to start',
        pageTitle: '404',
        text: 'This page does not exist.',
        title: 'Page not found',
    },
    registerLicense: {
        'account:type:incompatible': 'The license is not compatible with the account type.',
        activating: 'License key is being activated\nThis may take a moment.',
        alreadyRegistered: 'The license key you entered is already registered.',
        alreadyRegisteredAccount: 'The license key you entered is already registered for license account "{{account}}".',
        alreadyRegisteredThisAccount: 'The key you entered is already registered for the license account "{{account}}".',
        checkingKey: 'License key is being checked',
        description: 'Enter license key',
        error: 'The license could not be registered for your license account.',
        invalidKey: 'The license key you entered is not valid or has already been registered. Check your entry and try again.',
        'license:type:incompatible:personal': 'You are trying to add a personal license to a team license account. This is not possible. Please add this license to a personal licensing account.',
        'license:type:incompatible:team': 'You are trying to add a team license to a personal license account. This is not possible. Please add this license to a team licensing account.',
        licenseKey: 'License key',
        oldKey: 'This is an old license key. Do you want to exchange it for a new one and add it to the license account?',
        submit: 'Check license key',
        success: 'The license {{name}} has been added to your license account.',
        title: {
            personal: 'Add personal license',
            team: 'Add team license',
        },
        validKey: 'We found your key. Do you want to add it to the license account now? \nThis can only be done once.',
    },
    user: {
        roles: {
            admin: 'Administrator',
            agent: 'Agent',
        },
        status: {
            active: 'Active',
            invitationError: 'No invitation could be sent.',
            invitationSent: 'An invitation has been sent to {{email}}.',
            invited: 'Invited',
            label: 'Status',
            reinvite: 'Reinvite',
        },
    },
    userDetail: {
        info: {
            email: 'Email',
            itsYou: 'You account',
            name: 'Name',
            noRoles: 'No roles assigned',
            roles: 'Roles',
            status: 'Status',
            title: 'User information',
        },
        licenses: {
            editLicensesLink: 'Edit licenses',
            noLicenses: 'This user is not assigned to any licenses.',
            title: 'Licenses',
        },
        usergroups: {
            noGroups: 'Not assigned to any group',
            title: 'User groups',
        },
    },
    userList: {
        addUserDialog: {
            add: 'Invite user',
            cancel: 'Cancel',
            description: 'Please enter the email address of the user to be added. This user will then receive an email to confirm his axes4 account.',
            email: {
                label: 'Email address',
            },
            title: 'Invite user',
        },
        inviteUserButton: 'Invite user',
        pageTitle: 'User',
        table: {
            columns: {
                assignedLicenses: 'Assigned licenses',
                groups: 'Groups',
                identifier: 'User',
                name: 'Name',
                roles: 'Roles',
                status: 'Status',
            },
            confirmDelete: 'Delete the following users: {{users}}',
            deleteSelected: 'Delete selected',
            linkTitle: 'Edit user {{item}}',
            placeholder: 'No users found',
            reinviteSelected: 'Reinvite',
            selectItem: 'Select {{item}}',
            selectionStatus: '{{num}} of {{total}} selected',
        },
    },
    usergroupDetail: {
        addUser: {
            button: 'Add user',
            title: 'Add user to group',
        },
        info: {
            assignedUsers: 'Assigned user',
            label: 'Name',
            title: 'User group information',
        },
        licenses: {
            editLicensesLink: 'Administer licenses',
            noLicenses: 'This user group doesn\'t have any licenses.',
            title: 'Licenses',
        },
        users: {
            confirmRemove: 'Remove the following users from the group: {{users}}',
            removeSelected: 'Remove selected users',
            title: 'Users',
        },
    },
    usergroupList: {
        addUsergroupDialog: {
            add: 'Add',
            cancel: 'Cancel',
            description: 'Please enter the new user group\'s name.',
            link: 'Add user group',
            messages: {
                error: 'An error occurred while creating the user group',
                success: 'User group has been created',
            },
            title: 'Create user group',
            value: {
                label: 'Name of the user group',
            },
        },
        pageTitle: 'User groups',
        table: {
            columns: {
                assignedLicenses: 'Assigned licenses',
                assignedUsers: 'Assigned users',
                title: 'User group',
            },
            confirmDelete: 'Delete the following groups: {{groups}}',
            deleteSelected: 'Delete selected',
            linkTitle: 'Edit user group {{item}}',
            placeholder: 'No user groups found',
            selectItem: 'Select {{item}}',
            selectionStatus: '{{num}} of {{total}} have been selected',
        },
    },
};
