import { combineReducers } from 'redux';
import { users } from './users';
import { usergroups } from './user-groups';
import { roles } from './roles';
import { licenses, products } from './licenses';
import { licenseMapping } from './license-mappings';
import { application } from './application';
import { accounts } from './accounts';

export default combineReducers({
    application,
    accounts,
    users,
    roles,
    usergroups,
    licenseMapping,
    licenses,
    products,
});
