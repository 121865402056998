import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ListItem, ListItemIcon, Checkbox, ListItemText, Link } from '@material-ui/core';
import CheckIcon from '@axes4/react-icons/jsx/NavigateCheck';

/**
 * @typedef {{
*   group: import('../../../types/usergroup').Usergroup,
*   onToggle: Function,
*   active: boolean,
*   disabled?: boolean,
* }} UsergroupListItemProps
*/

/**
* @param {UsergroupListItemProps} props
*/
export const UsergroupListItem = props => {
    const labelId = `usergroup-label-${props.group.id}`;
    return (
        <ListItem dense button={!props.disabled} onClick={props.disabled ? null : props.onToggle}>
            <ListItemIcon>
                {!props.disabled && (
                    <Checkbox
                    checked={props.active}
                    inputProps={{
                        'aria-labelledby': labelId,
                    }}
                    onChange={props.onToggle}
                    disabled={props.disabled}
                    />
                )}
                {props.disabled && props.active && (
                    <CheckIcon size="small"/>
                )}
            </ListItemIcon>
            <ListItemText id={labelId}>
                <Link component={RouterLink} to={`/groups/${props.group.id}`}>
                    {props.group.title}
                </Link>
            </ListItemText>
        </ListItem>
    );
};
