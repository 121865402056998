import {
    CardTitle,
    CardWithClearing,
    MultiPanelCardContent,
    MultiPanelCardPanel,
} from '@axes4/react-common/src/components/Card';
import GraphConnectionDirectedIcon from '@axes4/react-icons/jsx/GraphConnectionDirected';
import InformationIcon from '@axes4/react-icons/jsx/Information';
import EmailIcon from '@axes4/react-icons/jsx/Mail';
import WaxSealIcon from '@axes4/react-icons/jsx/WaxSeal';
import UserIcon from '@axes4/react-icons/jsx/User';
import UsergroupIcon from '@axes4/react-icons/jsx/Users3';
import { Box, Card, CardContent, makeStyles, Tooltip } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ErrorResponse, getNetworkErrorBody, handledRequest } from '../../../hooks/api';
import { useEditMode } from '../../../hooks/editMode';
import { addLicenseUserToMappingAction, overwriteLicenseMappingAction } from '../../../state/actions/licenses';
import { domainLicenseAssignmentToApi } from '../../../state/transformations/licenses';
import { Layout } from '../../common/App/Layout';
import { EditCardButtonWrapper } from '../../common/EditCardButton';
import { LoadingView } from '../../common/EmptyState/Loading';
import { LicenseMapping } from '@axes4/react-common/src/components/LicenseMapping';
import { SelectOrCreateUsergroupContainer } from '../UsergroupList/SelectOrCreateUsergroup';
import { SelectOrCreateUserContainer } from '../UserList/SelectOrCreateUser';
import { LicenseInfo } from './LicenseInfo';
import { createFlashMessage } from '../../../state/reducers/flashmessages';
import { isPersonalAccount } from '../../../state/transformations/accounts';
import { getProducts } from '../../../state/transformations/products';
import { Account } from '../../../types/accounts';
import {
    LicenseApiData,
    LicenseData,
    LicenseMapping as TLicenseMapping,
    LicenseMappingUsers,
    LicenseModule, LicenseUserType,
    Product,
} from '../../../types/licenses';
import { User } from '../../../types/user';
import { Usergroup } from '../../../types/usergroup';
import { Uuid } from '../../../types/util';
import { MessageLevel } from '@axes4/react-common/src/hooks/flashmessage';
import { AppDispatch, Store } from '../../../state/store';
import { LicenseUser } from '@axes4/react-common/src/components/LicenseMapping/types';
import { UserLimitWarning } from './UserLimitWarning';

const useStyles = makeStyles(theme => ({
    tab: {
        minHeight: 'auto',
        '& > span': {
            flexDirection: 'row',
        },
        '& svg': {
            marginRight: theme.spacing(1),
            marginBottom: 0,
        },
    },
    tabs: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    invitedIndicator: {
        marginLeft: theme.spacing(1),
        position: 'relative',
        bottom: -theme.spacing(0.5),
    },
    licenseCardIcon: {
        transform: 'translateY(4px)',
    },
}));

type LicenseDetailProps = {
    licensingAccount: Account;
    updateLicenseMapping: (mapping: TLicenseMapping) => void;
    license: LicenseData|LicenseApiData;
    products: Product[];
    users: User[];
    usergroups: Usergroup[];
    licenseMapping: TLicenseMapping;
    addUserToMapping: (userId: Uuid, modules: LicenseModule[]) => void;
    addUsergroupToMapping: (usergroupId: Uuid) => void;
}

const getLicenseUsers = <T extends { id: number|string }>(mapping: LicenseMappingUsers) =>
    (database: T[]) =>
        <R,>(transformation: (entity: T, modules: LicenseModule[]) => R): R[] =>
            (mapping || [])
                .map<[T, LicenseModule[]]>(({ licenseUser, modules }) => [ database.find(entry => entry.id.toString() === licenseUser.toString()), modules ])
                .filter(([ entity ]) => !!entity)
                .map(([ entity, modules ]) => transformation(entity, modules));

export const LicenseDetail: React.FunctionComponent<LicenseDetailProps> = props => {
    const { t } = useTranslation();
    const classes = useStyles(props);
    let initialView = 'users';
    if (/^#user(group)?s_\d+$/.test(document.location.hash)) {
        const parts = document.location.hash.split('_');
        const type = parts[0].slice(1);
        if (type === 'users' || type === 'usergroups') {
            initialView = type;
            // setTimeout(() => setCurrentView(type), 80);
        }
    }
    const [ currentView, setCurrentView ] = useState(initialView);
    const [ activatedUserCount, setActivatedUserCount ] = useState(null);

    useEffect(() => {
        if (props.license && props.licensingAccount) {
            handledRequest(`licensing:/accounts/${props.licensingAccount.uuid}/licenses/${props.license.uuid}/activated-user-count`)
                .then(data => setActivatedUserCount(data))
                .catch(() => setActivatedUserCount(null));
        }
    }, [ props.license, props.licensingAccount ]);

    const {
        renderTriggerButton: editModeButton,
        renderEditingButtons,
        active: editingMode,
        disable: disableEditMode,
        commit: updateEditMode,
        state: licenseMapping,
        set: setLicenseMapping,
        update: setEditModeData,
    } = useEditMode(props.licenseMapping);

    useEffect(() => {
        setEditModeData(props.licenseMapping);
    }, [ props.licenseMapping ]);

    const handleLicenseMappingUpdate = (licenseUserType: LicenseUserType) => () => (licenseUsers: LicenseUser[]) => {
        // updateLicenseMapping(licenseId, licenseUserType, licenseUserMapping);
        const licenseUserMapping = licenseUsers.map(user => ({ licenseUser: user.id, modules: user.activeModules }));
        setLicenseMapping(state => ({ ...state, [licenseUserType]: licenseUserMapping }));
    };

    const licenseUsers = getLicenseUsers<User>(licenseMapping.users)(props.users)((user, modules) => ({
        label: (
            <>
                {user.email}
                {!user.confirmed && (
                    <Tooltip title={t('user.status.invited')}>
                        <span className={classes.invitedIndicator}>
                            <EmailIcon aria-label={t('user.status.invited')} color="primary" size="small"/>
                        </span>
                    </Tooltip>
                )}
            </>
        ) as unknown as string,
        link: `/users/${user.id}`,
        id: user.id.toString(),
        activeModules: modules,
    }));

    const addableLicenseUsers = useMemo(() => {
        return props.users
            .filter(user => (licenseMapping.users || []).findIndex(u => u.licenseUser.toString() === user.id.toString()) < 0);
    }, [ props.users, licenseMapping.users ]);

    const addUserToMapping = useCallback((userId: Uuid, modules: LicenseModule[]) => {
        setLicenseMapping(state => ({ ...state, users: [ ...state.users, { licenseUser: Number(userId), modules } ] }));
    }, [ setLicenseMapping ]);

    const handleSelectUser = useCallback((user: User) => {
        addUserToMapping(user.id.toString(), props.license.modules);
    }, [ addUserToMapping, props.license ]);

    const licenseUsergroups = getLicenseUsers<Usergroup>(licenseMapping.usergroups)(props.usergroups)((usergroup, modules) => ({
        label: usergroup.title,
        link: `/groups/${usergroup.id}`,
        id: usergroup.id,
        activeModules: modules,
    }));

    const addableLicenseUsergroups = useMemo(() => {
        return props.usergroups
            .filter(usergroup => (licenseMapping.usergroups || []).findIndex(u => u.licenseUser.toString() === usergroup.id.toString()) < 0);
    }, [ props.usergroups, licenseMapping.usergroups ]);

    const addUsergroupToMapping = useCallback((usergroupId: Uuid, modules: LicenseModule[]) => {
        setLicenseMapping(state => ({ ...state, usergroups: [ ...state.usergroups, { licenseUser: Number(usergroupId), modules } ] }));
    }, [ setLicenseMapping ]);

    const updateLicenseMapping = () => {
        handledRequest(`licensing:/accounts/${props.licensingAccount.uuid}/license-assignments`, {
            method: 'POST',
            body: JSON.stringify(domainLicenseAssignmentToApi(licenseMapping)),
        })
            .then(() => {
                props.updateLicenseMapping(licenseMapping);
                createFlashMessage({
                    message: t('licenseAssignment.messages.updated'),
                    variant: MessageLevel.Success,
                });
                disableEditMode(true);
                updateEditMode();
            })
            .catch((e) => {
                getNetworkErrorBody<ErrorResponse>(e).then(body => {
                    let message = t('licenseAssignment.messages.notSaved');
                    if (body?.error?.code) {
                        message += ` (${body.error.code})`;
                    }
                    createFlashMessage({
                        message: message,
                        variant: MessageLevel.Error,
                    });
                });
            });
    };

    const userExists = useCallback((email: string) => {
        const sanitizedEmail = email.trim().toLowerCase();
        return props.users.some(user => user.email === sanitizedEmail);
    }, [ props.users ]);

    const usergroupExists = useCallback((title: string) => {
        const sanitizedTitle = title.trim().toLowerCase();
        return props.usergroups.some(group => group.title === sanitizedTitle);
    }, [ props.usergroups ]);

    if (!props.license) {
        return <LoadingView/>;
    }

    return (
        <Layout pageTitle={props.license.name} pageIcon={<WaxSealIcon/>}>
            <UserLimitWarning
                licenseMapping={props.licenseMapping}
                users={props.users}
                limit={props.license.userCount}
            />
            <CardWithClearing>
                <CardTitle icon={<InformationIcon/>} title={t('licenseDetail.info.title')}/>
                <CardContent>
                    <LicenseInfo
                        license={props.license}
                        products={props.products}
                        activatedUserCount={activatedUserCount}
                    />
                </CardContent>
            </CardWithClearing>
            {!isPersonalAccount(props.licensingAccount) && (
                <Box marginTop={4} marginBottom={2}>
                    <Card>
                        <CardTitle
                            title={t('licenseDetail.mapping.title')}
                            icon={(
                                <div className={classes.licenseCardIcon}>
                                    <GraphConnectionDirectedIcon/>
                                </div>
                            )}
                        />
                        <EditCardButtonWrapper>
                            {editModeButton({ label: t('general.edit') })}
                        </EditCardButtonWrapper>
                        <MultiPanelCardContent currentTab={currentView} onTabChange={setCurrentView}>
                            <MultiPanelCardPanel
                                title={`${t('licenseDetail.users.title')} (${licenseUsers.length})`}
                                icon={<UserIcon/>}
                                id="users"
                            >
                                <Box marginBottom={2}>
                                    {editingMode && (
                                        <CardContent>
                                            <SelectOrCreateUserContainer
                                                options={addableLicenseUsers}
                                                onSelect={handleSelectUser}
                                                userExists={userExists}
                                            />
                                        </CardContent>
                                    )}
                                    <LicenseMapping
                                        updateLicenseMapping={handleLicenseMappingUpdate('users')}
                                        licenseUsers={licenseUsers}
                                        license={props.license}
                                        products={props.products}
                                        id="users"
                                        table
                                        editable={editingMode}
                                        getSelectionStatusLabel={(num, total) => t('licenseDetail.mapping.table.selectionStatus', { num, total })}
                                        licenseUserLabel={t('licenseDetail.mapping.table.usersTitle')}
                                        licenseUserRemoveLabel={t('licenseDetail.mapping.table.removeSelectedUsers')}
                                        selectAllLabel={t('licenseDetail.mapping.table.selectAll')}
                                        rowActionLabel={t('licenseDetail.mapping.table.actionLabel')}
                                        dragHandleLabel={t('licenseDetail.mapping.table.dragHandleLabel')}
                                        productDisabledLabel={t('licenseDetail.mapping.productDisabled')}
                                        productEnabledLabel={t('licenseDetail.mapping.productEnabled')}
                                    />
                                </Box>
                            </MultiPanelCardPanel>
                            <MultiPanelCardPanel
                                title={`${t('licenseDetail.usergroups.title')} (${licenseUsergroups.length})`}
                                icon={<UsergroupIcon/>}
                                id="usergroups"
                            >
                                {editingMode && (
                                    <CardContent>
                                        <SelectOrCreateUsergroupContainer
                                            options={addableLicenseUsergroups}
                                            onSelect={group => addUsergroupToMapping(group.id, props.license.modules)}
                                            usergroupExists={usergroupExists}
                                        />
                                    </CardContent>
                                )}
                                <LicenseMapping
                                    updateLicenseMapping={handleLicenseMappingUpdate('usergroups')}
                                    licenseUsers={licenseUsergroups}
                                    license={props.license}
                                    products={props.products}
                                    id="usergroups"
                                    sortable
                                    table
                                    editable={editingMode}
                                    getSelectionStatusLabel={(num, total) => t('licenseDetail.mapping.table.selectionStatus', { num, total })}
                                    licenseUserLabel={t('licenseDetail.mapping.table.usergroupsTitle')}
                                    licenseUserRemoveLabel={t('licenseDetail.mapping.table.removeSelectedUsergroups')}
                                    selectAllLabel={t('licenseDetail.mapping.table.selectAll')}
                                    rowActionLabel={t('licenseDetail.mapping.table.actionLabel')}
                                    dragHandleLabel={t('licenseDetail.mapping.table.dragHandleLabel')}
                                    productDisabledLabel={t('licenseDetail.mapping.productDisabled')}
                                    productEnabledLabel={t('licenseDetail.mapping.productEnabled')}
                                />
                            </MultiPanelCardPanel>
                        </MultiPanelCardContent>
                        {editingMode && (
                            <CardContent>
                                {renderEditingButtons(updateLicenseMapping)}
                            </CardContent>
                        )}
                    </Card>
                </Box>
            )}
        </Layout>
    );
};

type LicenseDetailContainerProps = {
    licenseId: Uuid;
}

const mapStateToProps = (state: Store, ownProps: LicenseDetailContainerProps): Omit<LicenseDetailProps, 'addUserToMapping'|'addUsergroupToMapping'|'updateLicenseMapping'> => {
    const license = state.licenses.find(license => license.uuid === ownProps.licenseId);
    return {
        license: license,
        licensingAccount: state.application.account,
        products: license ? getProducts(license.modules)(state) : [],
        users: state.users,
        usergroups: state.usergroups,
        licenseMapping: state.licenseMapping.find(mapping => mapping.licenseUuid === ownProps.licenseId) || { licenseUuid: ownProps.licenseId, users: [], usergroups: [] },
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: LicenseDetailContainerProps): Pick<LicenseDetailProps, 'addUserToMapping'|'addUsergroupToMapping'|'updateLicenseMapping'> => ({
    addUserToMapping: (userId, modules = []) => dispatch(addLicenseUserToMappingAction(ownProps.licenseId, 'users', userId, modules)),
    addUsergroupToMapping: (usergroupId, modules = []) => dispatch(addLicenseUserToMappingAction(ownProps.licenseId, 'usergroups', usergroupId, modules)),
    updateLicenseMapping: mapping => dispatch(overwriteLicenseMappingAction(mapping)),
});

export const LicenseDetailContainer = connect(mapStateToProps, mapDispatchToProps)(LicenseDetail);
