import { getAuthenticatedUserId } from '@axes4/react-common/src/auth/auth0-helper';
import { CardWithClearing } from '@axes4/react-common/src/components/Card';
import { usePrompt } from '@axes4/react-common/src/hooks/prompt';
import UserIcon from '@axes4/react-icons/jsx/User';
import { Button, CardContent, Chip, Link } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { handledRequest } from '../../../hooks/api';
import { getAssignedLicenseIds } from '../../../state/actions/licenses';
import { sortUsergroupsByName } from '../../../state/actions/user-groups';
import { createUserAction, deleteUserAction, reinviteUserAction } from '../../../state/actions/users';
import { getPreferredAccountId } from '../../../state/reducers/application';
import { apiUserToDomain, PotentialUserData } from '../../../state/transformations/users';
import { Layout } from '../../common/App/Layout';
import { UserList } from './UserList';
import { User } from '../../../types/user';
import { Usergroup } from '../../../types/usergroup';
import { Role } from '../../../types/role';
import { Uuid } from '../../../types/util';
import { MessageLevel, useFlashMessages } from '@axes4/react-common/src/hooks/flashmessage';
import { AppDispatch, Store } from '../../../state/store';

export interface UserOverviewListProps {
    users: User[];
    usergroups: Usergroup[];
    roles: Role[];
    deleteUser: (user: User) => void;
    reinviteUser: (user: User) => void;
    createUser: (user: User) => void;
    getAssignedLicenses: (userId: Uuid, groupIds: Uuid[]) => Uuid[];
}

export const UserOverviewList: React.FunctionComponent<UserOverviewListProps> = props => {
    const { t, i18n } = useTranslation();
    const { addMessage } = useFlashMessages();
    const getUsergroups = useCallback((user: User) => {
        return sortUsergroupsByName(user.userGroupIds.map(groupId => props.usergroups.find(g => g.id === groupId)));
    }, [ props.usergroups ]);
    const { prompt: promptUserCreation, render: renderUserCreationPrompt } = usePrompt({
        submitLabel: t('userList.addUserDialog.add'),
        cancelLabel: t('userList.addUserDialog.cancel'),
    });
    const users = props.users.map(user => ({ ...user }));

    const createUserColumns = (user: User) => [
        <>
            <div>
                <Link key="action" to={`/users/${user.id}`} title={t('userList.table.linkTitle', { item: user.email })} component={RouterLink}>
                    <span>{user.email}</span>
                </Link>
            </div>
            {getUsergroups(user).map(group => (
                <Chip
                    key={group.id}
                    label={group.title}
                    size="small"
                />
            ))}
        </>,
        user.roles.map(role => (
            <Chip key={role} label={i18n.exists('user.roles.' + role) ? t('user.roles.' + role) : role} size="small"/>
        )),
        props.getAssignedLicenses(user.id.toString(), user.userGroupIds).length,
        user.confirmed ? t('user.status.active') : t('user.status.invited'),
    ];

    const handleReinvite = useCallback((email: string): Promise<PotentialUserData|null> =>
        handledRequest(`licensing:/accounts/${getPreferredAccountId()}/users`, {
            method: 'POST',
            body: JSON.stringify({
                email,
            }),
        })
            .then((data: PotentialUserData) => {
                addMessage({
                    variant: MessageLevel.Success,
                    message: t('user.status.invitationSent', { email }),
                });
                return data;
            })
            .catch(e => {
                console.error(e);
                addMessage({
                    variant: MessageLevel.Error,
                    message: t('user.status.invitationError'),
                });
                return null;
            }), [ addMessage ]);

    const handleUserInvite = () => {
        promptUserCreation(
            t('userList.addUserDialog.description'),
            '',
            t('userList.addUserDialog.title'),
            t('userList.addUserDialog.email.label')
        )
            .then(email => {
                if (email) {
                    handleReinvite(email)
                        .then(data => {
                            if (data) {
                                const newUser = apiUserToDomain(data);
                                props.createUser(newUser);
                            }
                        });
                }
            });
    };
    return (
        <Layout pageTitle={t('userList.pageTitle')} pageIcon={<UserIcon/>}>
            <>{renderUserCreationPrompt()}</>
            <Button color="primary" onClick={handleUserInvite} variant="contained">{t('userList.addUserDialog.title')}</Button>
            <CardWithClearing>
                <CardContent>
                    <UserList
                        users={users}
                        columns={[
                            {
                                label: t('userList.table.columns.identifier'),
                                sortable: true,
                                sorter: (a, b) => a.user.email.localeCompare(b.user.email),
                            },
                            {
                                label: t('userList.table.columns.roles'),
                                sortable: true,
                                sorter: (a, b) => (a.user.roles[0] || '').localeCompare((b.user.roles[0] || '')),
                            },
                            {
                                label: t('userList.table.columns.assignedLicenses'),
                                sortable: true,
                                sorter: (a, b) => Number.parseInt(a.columns[2].toString()) - Number.parseInt(b.columns[2].toString()),
                            },
                            {
                                label: t('userList.table.columns.status'),
                                sortable: true,
                            },
                        ]}
                        userListRow={createUserColumns}
                        userSelectable={user => user.oAuth2userId !== getAuthenticatedUserId()}
                        deleteUser={props.deleteUser}
                        reinviteUser={user => handleReinvite(user.email)}
                        placeholder={t('userList.table.placeholder')}
                    />
                </CardContent>
            </CardWithClearing>
        </Layout>
    );
};

const mapStateToProps = (state: Store): Omit<UserOverviewListProps, 'deleteUser'|'reinviteUser'|'createUser'> => ({
    users: state.users,
    usergroups: state.usergroups,
    roles: state.roles,
    getAssignedLicenses: (userId, groupIds) => {
        const licenseIds = [
            ...getAssignedLicenseIds(state.licenseMapping)('users')(userId),
            ...groupIds.reduce((c, groupId) => [ ...c, ...getAssignedLicenseIds(state.licenseMapping)('usergroups')(groupId) ], []),
        ];
        return licenseIds.filter((x, i, a) => a.indexOf(x) === i);
    },
});

const mapDispatchToProps = (dispatch: AppDispatch): Pick<UserOverviewListProps, 'deleteUser'|'reinviteUser'|'createUser'> => ({
    deleteUser: user => dispatch(deleteUserAction(user)),
    reinviteUser: user => dispatch(reinviteUserAction(user)),
    createUser: user => dispatch(createUserAction(user)),
});

export const UserOverviewListContainer = connect(mapStateToProps, mapDispatchToProps)(UserOverviewList);
