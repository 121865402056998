import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { NotFound } from '../common/EmptyState/NotFound';
import { InitializationHelperContainer } from './InitializationHelper';
import { LicenseDetailContainer } from './LicenseDetail/LicenseDetail';
import { LicenseListOverviewContainer } from './LicenseList/LicenseListOverview';
import { UserDetailContainer } from './UserDetail/UserDetail';
import { UsergroupDetailContainer } from './UsergroupDetail/UsergroupDetail';
import { UsergroupOverviewListContainer } from './UsergroupList/UsergroupOverviewList';
import { UserOverviewListContainer } from './UserList';
import { Logout } from './Logout';
import { LoadingView } from '../common/EmptyState/Loading';
import { LoggedOutView } from './LoggedOut/LoggedOut';
import { isAuthenticated } from '@axes4/react-common/src/auth/auth0-helper';
import { ErrorBoundary } from '@axes4/react-common/src/components/ErrorHandling';
import { ErrorPage } from './ErrorPage';
import { FlashMessageProvider } from '@axes4/react-common/src/hooks/flashmessage';
const RestRequester = React.lazy(() => import('../RestRequester'));
const Account = React.lazy(() => import('./Account'));
const ManualActivation = React.lazy(() => import('./ManualActivation'));

export const App = () => {
    const [ loading, setLoading ] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 100);
    }, []);
    return (
        <FlashMessageProvider>
            <ErrorBoundary fallback={error => <ErrorPage details={error.message}/>}>
                <InitializationHelperContainer/>
                {loading
                    ? (<LoadingView/>)
                    : (
                        <Switch>
                            <Route path="/users/:userId">
                                {({ match }) => (
                                    <React.Suspense fallback="loading">
                                        <UserDetailContainer userId={match.params.userId}/>
                                    </React.Suspense>
                                )}
                            </Route>
                            <Route path="/users">
                                <UserOverviewListContainer/>
                            </Route>
                            <Route path="/groups/:groupId">
                                {({ match }) => (
                                    <React.Suspense fallback="loading">
                                        <UsergroupDetailContainer groupId={match.params.groupId}/>
                                    </React.Suspense>
                                )}
                            </Route>
                            <Route path="/groups">
                                <UsergroupOverviewListContainer/>
                            </Route>
                            <Route path="/licenses/:licenseId">
                                {({ match }) => (
                                    <React.Suspense fallback="loading">
                                        <LicenseDetailContainer licenseId={match.params.licenseId}/>
                                    </React.Suspense>
                                )}
                            </Route>
                            <Route path="/licenses">
                                <LicenseListOverviewContainer/>
                            </Route>
                            <Route path="/manual-activation">
                                <React.Suspense fallback="loading">
                                    <ManualActivation/>
                                </React.Suspense>
                            </Route>
                            <Route path="/" exact>
                                <Redirect to="/licenses"/>
                            </Route>
                            <Route path="/logout">
                                <Logout/>
                            </Route>
                            {!isAuthenticated() && (
                                <Route path="/loggedout">
                                    <LoggedOutView/>
                                </Route>
                            )}
                            <Route path="/_api" exact>
                                <React.Suspense fallback="loading">
                                    <RestRequester/>
                                </React.Suspense>
                            </Route>
                            <Route path="/login">
                                <Redirect to="/"/>
                            </Route>
                            <Route path="/account">
                                <React.Suspense fallback={<LoadingView/>}>
                                    <Account/>
                                </React.Suspense>
                            </Route>
                            <Route>
                                <NotFound/>
                            </Route>
                        </Switch>
                    )}
            </ErrorBoundary>
        </FlashMessageProvider>
    );
};
