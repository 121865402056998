import React, { CSSProperties } from 'react';
import IconMinus from '@axes4/react-icons/jsx/NavigateMinus';
import IconCheck from '@axes4/react-icons/jsx/NavigateCheck';

export interface CheckedStateIconProps {
    checked: boolean;
    style?: CSSProperties;
}

export const CheckedStateIcon: React.FunctionComponent<CheckedStateIconProps> = ({ checked, ...props }) => {
    if (checked) {
        return <IconCheck size="small" {...props}/>;
    } else {
        return <IconMinus size="small" {...props}/>;
    }
};

export const checkboxStyle = (disabled: boolean): CSSProperties => disabled ? { opacity: 0, width: 0 } : {};
