import { store } from '../store';

export const USERS_ACTIONS = Object.freeze({
    INITIALIZE: 'initialize_users',
    SET: 'set_users',
    CREATE: 'create_user',
    UPDATE: 'update_user',
    DELETE: 'delete_user',
    REINVITE: 'reinvite_user',
});

export const initializeUsersAction = (accountId) => ({
    type: USERS_ACTIONS.INITIALIZE,
    payload: {
        accountId,
    },
});

/**
 * Initialize the store with the given users
 * @param {import('../../types/user').User[]} data
 */
export const setUsersAction = (data) => ({
    type: USERS_ACTIONS.SET,
    payload: data,
});

/**
 * @param {import('../../types/user').User} user
 */
export const reinviteUserAction = user => ({
    type: USERS_ACTIONS.REINVITE,
    payload: user,
});

/**
 * @param {import('../types/user').User} user
 */
export const createUserAction = user => ({
    type: USERS_ACTIONS.CREATE,
    payload: user,
});

/**
 * @param {import('../types/user').User} user
 */
export const createUser = user => store.dispatch(createUserAction(user));

/**
 * @param {import('../types/user').User} user
 */
export const updateUserAction = user => ({
    type: USERS_ACTIONS.UPDATE,
    payload: user,
});

/**
 * @param {import('../types/user').User} user
 */
export const updateUser = user => store.dispatch(updateUserAction(user));

/**
 * @param {import('../types/user').User} user
 * @param {import('../types/util').Uuid} usergroupId
 */
export const addUsergroup = (user, usergroupId) => store.dispatch({
    type: USERS_ACTIONS.UPDATE,
    payload: { ...user, userGroupIds: [ ...user.userGroupIds, usergroupId ] },
});

/**
 * @param {import('../types/user').User} user
 * @param {import('../types/util').Uuid} usergroupId
 */
export const addToUsergroupAction = (user, usergroupId) => ({
    type: USERS_ACTIONS.UPDATE,
    payload: { ...user, userGroupIds: [ ...user.userGroupIds, usergroupId ] },
});

/**
 * @param {import('../types/user').User} user
 * @param {import('../types/util').Uuid} usergroupId
 */
export const removeFromUsergroupAction = (user, usergroupId) => ({
    type: USERS_ACTIONS.UPDATE,
    payload: { ...user, userGroupIds: user.userGroupIds.filter(groupId => groupId !== usergroupId) },
});

/**
 * @param {import('../../types/user').User} user
 * @param {import('../../types/util').Uuid[]} usergroupIds
 */
export const setUsergroupsAction = (user, usergroupIds) => ({
    type: USERS_ACTIONS.UPDATE,
    payload: { ...user, userGroupIds: usergroupIds },
});

/**
 * @param {import('../../types/user').User} user
 * @param {import('../../types/util').Uuid[]} usergroupIds
 */
export const setUsergroups = (user, usergroupIds) => store.dispatch(setUsergroupsAction(user, usergroupIds));

/**
 * @param {import('../types/user').User} user
 * @param {import('../types/util').Uuid} usergroupId
 */
export const removeUsergroup = (user, usergroupId) => store.dispatch({
    type: USERS_ACTIONS.UPDATE,
    payload: { ...user, userGroupIds: user.userGroupIds.filter(groupId => groupId !== usergroupId) },
});

/**
 * @param {import('../../types/user').User} user
 * @param {import('../../types/util').Uuid[]} roleId
 */
export const setRolesAction = (user, roleIds) => ({
    type: USERS_ACTIONS.UPDATE,
    payload: { ...user, roles:roleIds },
});

/**
 * @param {import('../../types/user').User} user
 */
export const deleteUserAction = user => ({
    type: USERS_ACTIONS.DELETE,
    payload: user.id,
});

/**
 * @param {import('../../types/user').User} user
 */
export const deleteUser = user => store.dispatch(deleteUserAction(user));
