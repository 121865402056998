import { makeRequest } from '../../hooks/api';
import { ACCOUNT_ACTIONS, setAccountsAction, switchAccountAction } from '../actions/accounts';
import { setLoadingAction } from '../actions/application';
import { getPreferredAccountId, unsetPreferredAccountId } from './application';


/**
 * @param {import('../asyncDispatchMiddleware').ActionWithDispatch<import('../actions/accounts').SetAccountsAction>} action
 */
const initializeAccounts = action => {
    const accounts = action.payload;
    const preferredAccountId = getPreferredAccountId();
    let preferredAccount = null;
    if (preferredAccountId) {
        preferredAccount = accounts.find(account => account.uuid === preferredAccountId);
        if (preferredAccount) {
            action.asyncDispatch(switchAccountAction(preferredAccount));
        }
    }
    if (!preferredAccountId || !preferredAccount) {
        const personalAccount = accounts.find(a => a.type === 'personal');
        if (personalAccount) {
            action.asyncDispatch(switchAccountAction(personalAccount));
        } else {
            unsetPreferredAccountId();
        }
    }
    action.asyncDispatch(setLoadingAction(false));
    return accounts;
};

export const accounts = (state = [], action) => {
    switch (action.type) {
        case ACCOUNT_ACTIONS.CREATE_ACCOUNT:
            return [ ...state, action.payload ];
        case ACCOUNT_ACTIONS.UPDATE_ACCOUNT:
            return state.map(acc => acc.uuid === action.payload.uuid ? action.payload : acc);
        case ACCOUNT_ACTIONS.SET_ACCOUNTS:
            return initializeAccounts(action);
        case ACCOUNT_ACTIONS.INITIALIZE_ACCOUNTS:
            makeRequest('licensing:/accounts?role=admin')
                .then(r => r.json())
                .then(d => {
                    if (d.data) {
                        action.asyncDispatch(setAccountsAction(d.data));
                    }
                });
            break;
    }
    return state;
};
