import { handledRequest } from '../../hooks/api';
import { addLoadingErrorAction, fetchCompleteAction } from '../actions/application';
import { LICENSE_ACTIONS, setLicensesAction } from '../actions/licenses';

export const licenses = (state = [], action) => {
    switch (action.type) {
        case LICENSE_ACTIONS.INITIALIZE:
            handledRequest(`licensing:/accounts/${action.payload.accountId}/licenses`)
                .then(data => {
                    if (data) {
                        action.asyncDispatch(setLicensesAction(data));
                    } else {
                        action.asyncDispatch(setLicensesAction([]));
                    }
                    action.asyncDispatch(fetchCompleteAction());
                })
                .catch(e => {
                    action.asyncDispatch(addLoadingErrorAction('loading.errorFetching', { resource: '"licenses"', status: e.statusCode }));
                    action.asyncDispatch(setLicensesAction([]));
                    // action.asyncDispatch(fetchCompleteAction());
                });
            return state;
        case LICENSE_ACTIONS.SET:
            return action.payload;
        case LICENSE_ACTIONS.CREATE:
            return [ ...state, action.payload ];
        default:
            return state;
    }
};

export const products = (state = []) => {
    return state;
};
