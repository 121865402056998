export const ACCOUNT_ACTIONS = Object.freeze({
    INITIALIZE_ACCOUNTS: 'initialize_license_accounts',
    SET_ACCOUNTS: 'set_license_accounts',
    ADD_ACCOUNT: 'add_license_account',
    SWITCH_ACCOUNT: 'switch_license_account',
    CREATE_ACCOUNT: 'create_license_account',
    UPDATE_ACCOUNT: 'update_license_account',
});

export const initilizeAccountsAction = () => ({
    type: ACCOUNT_ACTIONS.INITIALIZE_ACCOUNTS,
});

/**
 * @param {import("../../types/accounts").Account[]} accounts
 */
export const setAccountsAction = (accounts) => ({
    type: ACCOUNT_ACTIONS.SET_ACCOUNTS,
    payload: accounts,
});

/**
 * @typedef {ReturnType<setAccountsAction>} SetAccountsAction
 */

export const switchAccountAction = (account) => ({
    type: ACCOUNT_ACTIONS.SWITCH_ACCOUNT,
    payload: account,
});

/**
 * @typedef {ReturnType<switchAccountAction>} SwitchAccountAction
 */

/**
 * @param {import("../../types/accounts").Account} account
 */
export const createAccountAction = (account) => ({
    type: ACCOUNT_ACTIONS.CREATE_ACCOUNT,
    payload: account,
});

export const updateAccountAction = (accountData) => ({
    type: ACCOUNT_ACTIONS.UPDATE_ACCOUNT,
    payload: accountData,
});
