import React from 'react';
import { Layout } from '@axes4/react-common/src/components/Layout';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography, Link } from '@material-ui/core';

export const LoggedOut = () => {
    const { t } = useTranslation();
    return (
        <Card>
            <CardContent>
                <Typography variant="h5" component="h2">{t('loggedOut.title')}</Typography>
                <Typography paragraph variant="body1">{t('loggedOut.text')}</Typography>
                <Link component="a" href="/">{t('loggedOut.action')}</Link>
            </CardContent>
        </Card>
    );
};

export const LoggedOutView = () => {
    return (
        <Layout noDrawer width={600}>
            <LoggedOut/>
        </Layout>
    );
};
