import { store } from '../store';

export const LICENSE_ACTIONS = Object.freeze({
    INITIALIZE: 'initialize_licenses',
    SET: 'set_licenses',
    CREATE: 'create_license',
});

export const LICENSE_MAPPING_ACTIONS = Object.freeze({
    INITIALIZE: 'initialize_license_mappings',
    SET: 'set_license_mappings',
    UPDATE: 'map_license_update',
    OVERWRITE: 'map_license_overwrite',
    ADD_USER: 'map_license_adduser',
});

export const initializeLicensesAction = (accountId) => ({
    type: LICENSE_ACTIONS.INITIALIZE,
    payload: {
        accountId,
    },
});

export const setLicensesAction = (data) => ({
    type: LICENSE_ACTIONS.SET,
    payload: data,
});

export const createLicenseAction = (data) => ({
    type: LICENSE_ACTIONS.CREATE,
    payload: data,
});

export const initializeLicenseMappingsAction = (accountId) => ({
    type: LICENSE_MAPPING_ACTIONS.INITIALIZE,
    payload: {
        accountId,
    },
});

export const setLicenseMappingsAction = (data) => ({
    type: LICENSE_MAPPING_ACTIONS.SET,
    payload: data,
});

/**
 * @param {import('../../types/util').Uuid} licenseUuid
 * @param {import('../../types/licenses').LicenseUserType} licenseUserType
 * @param {import('../../types/util').Uuid} licenseUserId
 * @param {import('../../types/licenses').LicenseModule[]} modules
 */
export const addLicenseUserToMappingAction = (licenseUuid, licenseUserType, licenseUserId, modules = []) => ({
    type: LICENSE_MAPPING_ACTIONS.ADD_USER,
    payload: {
        licenseUuid,
        licenseUserType,
        licenseUserId,
        modules,
    },
});

/**
 * @param {import('../../types/licenses').LicenseMapping} newMapping
 */
export const overwriteLicenseMappingAction = (newMapping) => ({
    type: LICENSE_MAPPING_ACTIONS.OVERWRITE,
    payload: newMapping,
});

/**
 * @param {import('../../types/util').Uuid} licenseUuid
 * @param {LicenseUserType} mappingType
 * @param {any} items
 */
export const updateLicenseMappingAction = (licenseUuid, mappingType, items) => ({
    type: LICENSE_MAPPING_ACTIONS.UPDATE,
    payload: {
        type: mappingType,
        licenseUuid: licenseUuid,
        data: items,
    },
});

/**
 * @param {import('../../types/util').Uuid} licenseUuid
 * @param {import('../../types/licenses').LicenseUserType} mappingType
 * @param {any} items
 */
export const updateLicenseMapping = (licenseUuid, mappingType, items) =>
    store.dispatch(updateLicenseMappingAction(licenseUuid, mappingType, items));

/**
 * @param {import('../../types/licenses').LicenseMapping[]} licenseMapping
 * @returns {(licenseUserType: import('../../types/licenses').LicenseUserType) => (licenseUserId: import('../../types/util').Uuid) => import('../../types/util').Uuid[]}
 */
export const getAssignedLicenseIds = licenseMapping => licenseUserType => licenseUserId =>
    licenseMapping.reduce((carry, mapping) => {
        const licenseId = mapping.licenseUuid;
        if (licenseUserType in mapping) {
            const userId = licenseUserId.toString();
            const user = mapping[licenseUserType].find(item => item.licenseUser.toString() === userId);
            if (user) {
                return [ ...carry, licenseId ];
            }
        }
        return carry;
    }, []).filter((c, i, a) => a.indexOf(c) === i);
