import { Box, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEditMode } from '../../../hooks/editMode';
import { EditCardButtonWrapper } from '../../common/EditCardButton';
import { EntityInformation } from '../../common/EntityInformation/EntityInformation';

/**
 * @typedef {{
 *   usergroup: import('../../../types/usergroup').Usergroup,
 *   updateUsergroup: (usergroup: import('../../../types/usergroup').Usergroup) => void,
 *   assignedUserCount: number,
 * }} UsergroupInfoProps
 */

/**
 * @param {UsergroupInfoProps} props
 */
export const UsergroupInfo = props => {
    const { t } = useTranslation();
    const {
        disable: disableEditMode,
        active: editModeActive,
        set: setDetails,
        state,
        renderEditingButtons,
        renderTriggerButton: editButton,
        commit: updateEditMode,
    } = useEditMode(props.usergroup.title);
    const submit = () => {
        props.updateUsergroup({
            ...props.usergroup,
            title: state,
        });
        updateEditMode();
        disableEditMode(true);
    };
    return (
        <Box>
            <EditCardButtonWrapper isCardContent>
                {editButton({ label: t('general.edit') })}
            </EditCardButtonWrapper>
            <EntityInformation
                data={{
                    [t('usergroupDetail.info.label')]: editModeActive ? (
                        <TextField
                            value={state}
                            onChange={e => setDetails(() => e.target.value)}
                            label={t('usergroupDetail.info.label')}
                        />
                    ) : (
                        state
                    ),
                    [t('usergroupDetail.info.assignedUsers')]: props.assignedUserCount.toString(),
                }}
            />
            {renderEditingButtons(submit)}
        </Box>
    );
};
