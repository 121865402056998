import PlusIcon from '@axes4/react-icons/jsx/Plus';
import { ListItem, ListItemIcon, ListItemText, Avatar } from '@material-ui/core';
import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { switchAccountAction, createAccountAction } from '../../../state/actions/accounts';
import { getPreferredAccountId } from '../../../state/reducers/application';
// import { makeRequest } from '../../../hooks/api';
import { AccountCreationDialog } from './AccountCreationDialog';
import { AccountListItem } from './AccountListItem';
import { handledRequest } from '../../../hooks/api';
import { createFlashMessage } from '../../../state/reducers/flashmessages';
import UserIcon from '@axes4/react-icons/jsx/User';
import TeamIcon from '@axes4/react-icons/jsx/Users3';
import { partition } from '../../../util/array';

/**
 * @typedef {{
 *   accounts: import('../../../types/accounts').Account[],
 *   selectAccount: (account: import('../../../types/accounts').Account) => void,
 *   createAccount: (account: import('../../../types/accounts').Account) => void,
 *   onSelect?: () => void,
 * }} AccountListProps
 */

/**
 * @param {AccountListProps} props
 */
const AccountListComponent = (props, ref) => {
    const { t } = useTranslation();
    const [ creatingNewAccount, setCreatingNewAccount ] = useState(false);
    const openAccountCreation = () => {
        setCreatingNewAccount(true);
    };
    const handleAccountCreationCancel = () => {
        setCreatingNewAccount(false);
    };
    const handleSelect = account => () => {
        props.selectAccount(account);
        if (props.onSelect) {
            props.onSelect(account);
        }
    };
    const handleAccountCreationSubmit = accountName =>
        handledRequest('licensing:/accounts', {
            method: 'POST',
            body: JSON.stringify({ name: accountName }),
        })
            .then(d => {
                setCreatingNewAccount(false);
                props.createAccount(d);
                handleSelect(d)();
                createFlashMessage({
                    message: t('account.messages.created', { name: accountName }),
                    variant: 'success',
                });
            })
            .catch(e => {
                console.error(e);
                createFlashMessage({
                    message: t('account.messages.creationError'),
                    variant: 'error',
                });
            });
    const selectedAccount = getPreferredAccountId();
    const accounts = partition(props.accounts, a => a.uuid === selectedAccount);
    return (
        <>
            {accounts.map(account => (
                <li key={account.uuid}>
                    <AccountListItem
                        title={account.name}
                        subtitle={t(`account.type.${account.type}.title`)}
                        onClick={handleSelect(account)}
                        button
                        disabled={account.uuid === getPreferredAccountId()}
                        avatar={
                            <Avatar>
                                {{
                                    'personal': <UserIcon/>,
                                    'team': <TeamIcon/>,
                                }[account.type]}
                            </Avatar>
                        }
                    />
                </li>
            ))}
            <li ref={ref}>
                <ListItem button onClick={openAccountCreation}>
                    <ListItemIcon>
                        <PlusIcon/>
                    </ListItemIcon>
                    <ListItemText>
                        {t('account.createAccount.link')}
                    </ListItemText>
                </ListItem>
            </li>
            <AccountCreationDialog
                open={creatingNewAccount}
                onClose={handleAccountCreationCancel}
                onSubmit={handleAccountCreationSubmit}
            />
        </>
    );
};

export const AccountList = forwardRef(AccountListComponent);

const mapStateToProps = (state) => ({
    accounts: state.accounts,
});

const mapDispatchToProps = (dispatch) => ({
    selectAccount: account => dispatch(switchAccountAction(account)),
    createAccount: account => dispatch(createAccountAction(account)),
});

export const AccountListContainer = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AccountList);
