export const apiLicenseAssignmentToDomain = data => {
    return {
        licenseUuid: data.licenseUuid,
        users: (data.users || []).map(lu => ({
            licenseUser: lu.userId,
            modules: lu.modules,
        })),
        usergroups: (data.userGroups || []).map(lu => ({
            licenseUser: lu.userGroupId,
            modules: lu.modules,
        })),
    };
};

export const domainLicenseAssignmentToApi = data => {
    return {
        licenseUuid: data.licenseUuid,
        users: (data.users || []).map(lu => ({
            userId: Number(lu.licenseUser),
            modules: lu.modules,
        })),
        userGroups: (data.usergroups || []).map(lu => ({
            userGroupId: Number(lu.licenseUser),
            modules: lu.modules,
        })),
    };
};
