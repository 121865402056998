import { ListItem, ListItemAvatar, ListItemText, makeStyles } from '@material-ui/core';
import React, { forwardRef } from 'react';

const useStyles = makeStyles(() => ({
    accountName: {
        display: 'flex',
        flexDirection: 'column-reverse',
    },
}));

/**
 * @typedef {{
 *   subtitle: string,
 *   title: string,
 *   avatar?: React.ReactNode,
 *   after?: any,
 * }} AccountListItemProps
 */

/**
 * @param {AccountListItemProps} props
 */
export const AccountListItem = forwardRef((props, ref) => {
    const classes = useStyles();
    const { title, subtitle, avatar, inset, after, ...listItemProps } = props;
    return (
        <ListItem {...listItemProps} ref={ref}>
            {avatar && (
                <ListItemAvatar>{avatar}</ListItemAvatar>
            )}
            <ListItemText inset={inset} className={classes.accountName} primary={title} secondary={subtitle}/>
            {after}
        </ListItem>
    );
});
