export const APPLICATION_ACTIONS = Object.freeze({
    SET_CURRENT_USER: 'set_application_user',
    SET_CURRENT_ACCOUNT: 'set_application_license_account',
    SET_CURRENT_LANGUAGE: 'set_application_language',
    SET_LOADING: 'set_application_loading',
    SET_FETCHING: 'set_application_fetching',
    FETCH_COMPLETE: 'application_fetch_complete',
    SET_AUTHENTICATED: 'set_application_authenticated',
    ADD_LOADING_ERROR: 'add_application_loading_error',
});

export const setCurrentUserAction = userData => ({
    type: APPLICATION_ACTIONS.SET_CURRENT_USER,
    payload: userData,
});

export const setCurrentAccountAction = account => ({
    type: APPLICATION_ACTIONS.SET_CURRENT_ACCOUNT,
    payload: account,
});

export const setCurrentLanguageAction = language => ({
    type: APPLICATION_ACTIONS.SET_CURRENT_LANGUAGE,
    payload: language,
});

/**
 * Set to determine whether the application finished authentication
 * @param {boolean} authenticated
 */
export const setAuthenticatedAction = (authenticated = true) => ({
    type: APPLICATION_ACTIONS.SET_AUTHENTICATED,
    payload: authenticated,
});

/**
 * Set to determine whether the initializiation is taking place
 * @param {boolean} loading
 */
export const setLoadingAction = (loading = true) => ({
    type: APPLICATION_ACTIONS.SET_LOADING,
    payload: loading,
});

/**
 * Set to determine whether sub resources are being fetched either after initialization or
 * after account switching. To be set to the amount of resources that are fetched
 * @param {number} fetching
 */
export const setFetchingAction = (fetching = true) => ({
    type: APPLICATION_ACTIONS.SET_FETCHING,
    payload: fetching,
});

/**
 * Dispatched once a resource to fetch completed, so that the loading state can be determined
 * from the amount of resources to go
 */
export const fetchCompleteAction = () => ({
    type: APPLICATION_ACTIONS.FETCH_COMPLETE,
});

/**
 * @param {string} messageCode Translation code
 * @param {object} parameters Parameters for translation
 */
export const addLoadingErrorAction = (messageCode, parameters) => ({
    type: APPLICATION_ACTIONS.ADD_LOADING_ERROR,
    payload: {
        messageCode,
        parameters,
    },
});
