import { Store } from '../store';
import { LicenseUuid, Product } from '../../types/licenses';

export const getProduct = (id: number) => (state: Store): Product =>
    state.products.find(product => product.id === id) ?? getProduct(0)(state);

export const getProducts = (ids: number[]) => (state: Store): Product[] =>
    state.products.filter(product => ids.includes(product.id));

export const getProductsByLicense = (state: Store): Record<LicenseUuid, Product[]> => {
    return state.licenses
        .reduce((c, license) => ({
            ...c,
            [license.uuid]: license.modules.map(moduleId => getProduct(moduleId)(state)),
        }), {});
};
