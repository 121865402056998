import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAuthenticationToken, isAuthenticated, logout, useAuth0Init } from '@axes4/react-common/src/auth/auth0-helper';
import { makeRequest } from '../../hooks/api';
import { setCurrentUserAction, setAuthenticatedAction } from '../../state/actions/application';
import { initilizeAccountsAction } from '../../state/actions/accounts';
import { getLoginLink, extendAuthentication } from '../../auth0';
import { getLocation } from '@axes4/react-common/src/util';
import { SessionStore } from '@axes4/react-common/src/auth/session-store';
import i18n from '../../i18n';
import { getConfig } from '../../config';
const auth0Credentials = getConfig().auth;

/** @type {import('auth0-js').AuthOptions} */
const auth0WebAuthConfig = {
    clientID: auth0Credentials.clientId,
    domain: auth0Credentials.customDomain,
    audience: auth0Credentials.audience,
    redirectUri: getLocation(),
    responseType: 'token id_token',
    scope: 'openid',
};

const awaitAuthStorageKey = 'await_auth';

/**
 * @typedef {{
 *   dispatch: import('redux').Dispatch,
 * }} InitializationHelperProps
 */

/**
 * @param {InitializationHelperProps} props
 */
export const InitializationHelper = props => {
    const { loading } = useAuth0Init(auth0WebAuthConfig, console.error);
    const initializeApp = () => {
        const token = getAuthenticationToken();
        if (token) {
            SessionStore.delete(awaitAuthStorageKey);
            props.dispatch(setAuthenticatedAction(true));
            makeRequest('id:/userinfo')
                .then(r => r.json())
                .then(d => {
                    if (d.user_metadata && d.user_metadata.language) {
                        i18n.changeLanguage(d.user_metadata.language);
                    }
                    props.dispatch(setCurrentUserAction(d));
                });
            props.dispatch(initilizeAccountsAction());
        }
    };
    useEffect(() => {
        if (loading) {
            return;
        }
        if (!SessionStore.has(awaitAuthStorageKey)) {
            window.setTimeout(() => {
                SessionStore.put(awaitAuthStorageKey, 'true');
                extendAuthentication((e) => {
                    console.error(e);
                    if (isAuthenticated()) {
                        logout(null)(null);
                        document.location.href = '/loggedout';
                    }
                })
                    .then(initializeApp)
                    .catch(() => {
                        document.location.href = getLoginLink();
                    });
            }, 300);
        } else {
            // clear awaitAuth flag after 10s to enable reload
            window.setTimeout(() => {
                SessionStore.delete(awaitAuthStorageKey);
            }, 10000);
            window.setTimeout(() => {
                initializeApp();
            }, 100);
        }
    }, [ loading ]);
    return null;
};

export const InitializationHelperContainer = connect()(InitializationHelper);
