import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { CardWithClearing } from '@axes4/react-common/src/components/Card';
import { CardContent, Button, Link } from '@material-ui/core';
import { EnhancedTable } from '@axes4/react-common/src/components/EnhancedTable';
import UsergroupIcon from '@axes4/react-icons/jsx/Users3';
import { deleteUsergroupAction } from '../../../state/actions/user-groups';
import { AddUsergroupDialogHandlerContainer } from './AddUsergroupDialog';
import { useTranslation } from 'react-i18next';
import { getAssignedLicenseIds } from '../../../state/actions/licenses';
import { Layout } from '../../common/App/Layout';
import { makeRequest } from '../../../hooks/api';
import { useConfirmation } from '../../../hooks/confirmation';

/**
 *
 * @param {import('../../../types/user').User[]} users
 * @returns {(groupIds: import('../../../types/util').Uuid[]) => {[key: import('../../../types/util').Uuid]: number}}
 */
export const countAssignedUsers = users => groupIds => {
    const result = {};
    users.forEach(user => {
        user.userGroupIds.forEach(groupId => {
            if (groupIds.indexOf(groupId) >= 0) {
                if (!(groupId in result)) {
                    result[groupId] = 0;
                }
                result[groupId]++;
            }
        });
    });
    return result;
};

/**
 * @typedef {{
 *   licenseAccount: import('../../../types/accounts').Account,
 *   deleteUsergroup: (usergroup: Pick<import('../../../types/usergroup').Usergroup, "id">) => void,
 *   usergroups: import('../../../types/usergroup').Usergroup[],
 *   assignedUserCount: {[usergroup: import('../../../types/util').Uuid]: number}
 *   getAssignedLicensesCount: {(usergroup: import('../../../types/util').Uuid) => number}
 * }} UsergroupOverviewListProps
 */

/**
 * @param {UsergroupOverviewListProps} props
 */
export const UsergroupOverviewList = props => {
    const { t } = useTranslation();
    const { confirm, render: renderConfirmation } = useConfirmation();
    const deleteUsergroup = usergroup => {
        makeRequest(`licensing:/accounts/${props.licenseAccount.uuid}/user-groups/${usergroup.id}`, {
            method: 'DELETE',
        })
            .then(r => {
                if (r.ok) {
                    props.deleteUsergroup(usergroup.id);
                }
            });
    };
    return (
        <Layout pageTitle={t('usergroupList.pageTitle')} pageIcon={<UsergroupIcon/>}>
            {renderConfirmation()}
            <AddUsergroupDialogHandlerContainer label={t('usergroupList.addUsergroupDialog.link')}/>
            <CardWithClearing>
                <CardContent>
                    <EnhancedTable
                        isCardContent
                        selectable
                        getSelectionStatusLabel={(itemCount, totalCount) => t('usergroupList.table.selectionStatus', { num: itemCount, total: totalCount })}
                        placeholder={t('usergroupList.table.placeholder')}
                        columns={[
                            {
                                label: t('usergroupList.table.columns.title'),
                                sortable: true,
                                sorter: (a, b) => a.group.title.localeCompare(b.group.title),
                            },
                            {
                                label: t('usergroupList.table.columns.assignedUsers'),
                                sortable: true,
                                sorter: (a, b) => Number.parseInt(a.columns[1].toString()) - Number.parseInt(b.columns[1].toString()),
                            },
                            {
                                label: t('usergroupList.table.columns.assignedLicenses'),
                                sortable: true,
                                sorter: (a, b) => Number.parseInt(a.columns[2].toString()) - Number.parseInt(b.columns[2].toString()),
                            },
                        ]}
                        data={props.usergroups.map(group => ({
                            selectable: true,
                            id: group.id,
                            checkboxLabel: t('usergroupList.table.selectItem', { item: group.title }),
                            columns: [
                                <Link key={group.id} to={`/groups/${group.id}`} title={t('usergroupList.table.linkTitle', { item: group.title })} component={RouterLink}>{group.title}</Link>,
                                props.assignedUserCount[group.id] || 0,
                                props.getAssignedLicensesCount(group.id).length,
                            ],
                            group: group,
                        }))}
                        tableHeadActions={selectedItems => {
                            const deleteSelected = () =>
                                confirm(t('usergroupList.table.confirmDelete', { groups: selectedItems.map(group => group.group.title).join(', ') }))
                                    .then(confirmed => confirmed && selectedItems.forEach(group => deleteUsergroup(group)));
                            return (
                                <>
                                    {(
                                        <Button
                                            disabled={selectedItems.length == 0}
                                            size="small"
                                            variant="contained"
                                            onClick={deleteSelected}
                                        >
                                            {t('usergroupList.table.deleteSelected')}
                                        </Button>
                                    )}
                                </>
                            );
                        }}
                    />
                </CardContent>
            </CardWithClearing>
        </Layout>
    );
};

const mapStateToProps = state => ({
    licenseAccount: state.application.account,
    usergroups: state.usergroups,
    assignedUserCount: countAssignedUsers(state.users)(state.usergroups.map(group => group.id)),
    getAssignedLicensesCount: getAssignedLicenseIds(state.licenseMapping)('usergroups'),
});

const mapDispatchToProps = {
    deleteUsergroup: deleteUsergroupAction,
};

export const UsergroupOverviewListContainer = connect(mapStateToProps, mapDispatchToProps)(UsergroupOverviewList);
