import { logout } from '@axes4/react-common/src/auth/auth0-helper';
import { getBaseUrl } from '../../util';
import auth0 from 'auth0-js';
import { getConfig } from '../../config';
const auth0Credentials = getConfig().auth;

/**
 * @param {{redirectUrl?: string}} props
 */
export const Logout = props => {
    const webAuth = new auth0.WebAuth({
        clientID: auth0Credentials.clientId,
        domain: auth0Credentials.customDomain,
    });
    logout(webAuth)(props.redirectUrl || getBaseUrl());
    return null;
};
