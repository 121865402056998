import { FlashMessageContainer } from '@axes4/react-common/src/components/FlashMessages';
import { DrawerHeader, Layout as BaseLayout, Navigation, PageHeader, RouterBreadcrumb } from '@axes4/react-common/src/components/Layout';
import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getPreferredAccountId } from '../../../state/reducers/application';
import { isPersonalAccount } from '../../../state/transformations/accounts';
import logoUri from '../../../static/img/logo_axes4_64.png';
import { LoginInformation } from '../Account/LoginInformation';
import { Loading } from '../EmptyState/Loading';
import { navigation, personalAccountNavigation } from './navigation';

/**
 * @typedef {{
 *   children: import('react').ReactChild|import('react').ReactChild[],
 *   currentUser: import('../../../types/accounts').ApplicationUser,
 *   currentAccount: import('../../../types/accounts').Account,
 *   authenticated: boolean,
 *   loading: boolean,
 *   fetching: boolean,
 *   pageTitle: import('@axes4/react-common/src/components/Layout/PageHeader').PageHeaderProps["title"],
 *   pageIcon: import('@axes4/react-common/src/components/Layout/PageHeader').PageHeaderProps["icon"],
 *   width?: Pick<import('@axes4/react-common/src/components/Layout/Layout').LayoutProps, "width">,
 *   loadingErrors?: Array<{messageCode: string, parameters?: {[key: string]: string}}>,
 * }} LayoutProps
 */

/**
 * @param {LayoutProps} props
 */
const Layout = props => {
    const [ redirectToHome, setRedirectToHome ] = useState(false);
    const [ previouslyPreferredAccount, setPreviouslyPreferredAccount ] = useState(getPreferredAccountId());
    const { t } = useTranslation();
    const PageIcon = props.pageIcon;
    const ready = !(!props.authenticated || props.loading || props.fetching);
    let loadingContent = null;
    let pageHeader;
    if (!ready) {
        let loadingText = null;
        if (!props.authenticated) {
            loadingText = t('loading.authenticating');
        } else if (props.loading) {
            loadingText = t('loading.loading');
        } else if (props.fetching) {
            loadingText = t('loading.fetching');
        }
        loadingContent = (
            <Loading
                text={loadingText}
                content={props.loadingErrors ? props.loadingErrors.map((e, i) => (
                    <Typography key={i} paragraph>{t(e.messageCode, e.parameters || {})}</Typography>
                )) : null}
            />
        );
        pageHeader = <PageHeader title={t('loading.pageTitle')}/>;
    } else {
        pageHeader = <PageHeader title={props.pageTitle} icon={PageIcon} />;
    }
    useEffect(() => {
        if (typeof props.pageTitle === 'string') {
            const separator = ' - ';
            const baseTitle = document.title.split(separator).reverse()[0];
            document.title = `${props.pageTitle}${separator}${baseTitle}`;
        }
    }, [ props.pageTitle ]);
    const preferredAccountId = getPreferredAccountId();
    useEffect(() => {
        if (preferredAccountId !== previouslyPreferredAccount) {
            setPreviouslyPreferredAccount(preferredAccountId);
            setRedirectToHome(true);
        }
    }, [ props.currentAccount ]);
    if (redirectToHome) {
        setTimeout(() => {
            setRedirectToHome(false);
        }, 100);
        return <Redirect to="/"/>;
    }
    const navigationItems = (isPersonalAccount(props.currentAccount) ? personalAccountNavigation : navigation)
        .map(item => ({ ...item, label: t(item.label) }));
    return (
        <BaseLayout
            drawerHeader={
                <DrawerHeader
                    title={t('application.title')}
                    logoUri={logoUri}
                    />
            }
            width={props.width || 800}
            drawerContent={ready && (
                <>
                    <LoginInformation
                        user={props.currentUser}
                        account={props.currentAccount}
                    />
                    <Navigation items={navigationItems}/>
                </>
            )}
            header={pageHeader}
            breadcrumb={ready && (
                <RouterBreadcrumb
                    indented
                    excludeLast
                    aria-label="breadcrumb"
                    routePartMapping={{
                        '/licenses': t('licenseList.pageTitle'),
                        '/users': t('userList.pageTitle'),
                        '/groups': t('usergroupList.pageTitle'),
                    }}
                />
            )}
        >
            <FlashMessageContainer/>
            {ready ? props.children : loadingContent}
        </BaseLayout>
    );
};

const mapStateToProps = state => ({
    currentUser: state.application.user,
    currentAccount: state.application.account,
    authenticated: state.application.authenticated,
    loading: state.application.loading,
    fetching: state.application.fetching > 0,
    loadingErrors: state.application.loadingErrors,
});

const LayoutContainer = connect(mapStateToProps)(Layout);

export { LayoutContainer as Layout };

