import { CardWithClearing } from '@axes4/react-common/src/components/Card';
import WaxSealIcon from '@axes4/react-icons/jsx/WaxSeal';
import { CardContent } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../common/App/Layout';
import { AddLicenseDialogHandler } from './AddLicenseDialog';
import { LicenseListContainer } from './LicenseList';
import { createLicenseAction } from '../../../state/actions/licenses';

/**
 * @typedef {{
 *   accounts: import('../../../types/accounts').Account[],
 *   account: import('../../../types/accounts').Account,
 *   handleLicenseCreation: (licenseData: import('../../../types/licenses').LicenseData<Date>) => void,
 * }} LicenseListOverviewProps
 */

/**
 * @param {LicenseListOverviewProps} props
 */
export const LicenseListOverview = (props) => {
    const { t } = useTranslation();
    return (
        <Layout pageTitle={t('licenseList.pageTitle')} pageIcon={<WaxSealIcon/>}>
            {props.account && (
                <AddLicenseDialogHandler
                    accounts={props.accounts}
                    account={props.account}
                    handleLicenseCreation={props.handleLicenseCreation}
                >
                    {t(`registerLicense.title.${props.account.type}`)}
                </AddLicenseDialogHandler>
            )}
            <CardWithClearing>
                <CardContent>
                    <LicenseListContainer/>
                </CardContent>
            </CardWithClearing>
        </Layout>
    );
};

const mapStateToProps = state => {
    return {
        accounts: state.accounts,
        account: state.application.account,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        handleLicenseCreation: licenseData => dispatch(createLicenseAction(licenseData)),
    };
};

export const LicenseListOverviewContainer = connect(mapStateToProps, mapDispatchToProps)(LicenseListOverview);
