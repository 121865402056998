import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de from './lang/de';
import deErrors from './lang/errors.de';
import en from './lang/en';
import enErrors from './lang/errors.en';

export const languages = {
    de: 'Deutsch',
    en: 'English',
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            'en': {
                translation: en,
                errors: enErrors,
            },
            'de': {
                translation: de,
                errors: deErrors,
            },
        },
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        nsSeparator: '::',
    });

export default i18n;
