import { USERS_ACTIONS, setUsersAction } from '../actions/users';
import { createFlashMessage } from './flashmessages';
import i18n from '../../i18n';
import { handledRequest } from '../../hooks/api';
import { getPreferredAccountId } from './application';
import { apiUserToDomain } from '../transformations/users';
import { fetchCompleteAction, addLoadingErrorAction } from '../actions/application';

export const users = (state = [], action) => {
    switch (action.type) {
        case USERS_ACTIONS.INITIALIZE:
            handledRequest(`licensing:/accounts/${action.payload.accountId || getPreferredAccountId()}/users`)
                .then(data => {
                    if (data) {
                        action.asyncDispatch(setUsersAction(data.map(apiUserToDomain)));
                    } else {
                        action.asyncDispatch(setUsersAction([]));
                    }
                    action.asyncDispatch(fetchCompleteAction());
                })
                .catch((e) => {
                    action.asyncDispatch(addLoadingErrorAction('loading.errorFetching', { resource: '"users"', status: e.statusCode }));
                    action.asyncDispatch(setUsersAction([]));
                    // action.asyncDispatch(fetchCompleteAction());
                });
            return state;
        case USERS_ACTIONS.SET:
            return action.payload;
        case USERS_ACTIONS.CREATE:
            return state.concat([ action.payload ]);
        case USERS_ACTIONS.UPDATE:
            return state.map(user =>
                user.id === action.payload.id
                ? action.payload
                : user
            );
        case USERS_ACTIONS.DELETE:
            return state.filter(user => user.id !== action.payload);
        case USERS_ACTIONS.REINVITE:
            createFlashMessage({
                variant: 'success',
                message: i18n.t('user.status.invitationSent', { email: action.payload.email }),
            });
            return state;
        default:
            return state;
    }
};
