import auth0 from 'auth0-js';
import { getLoginUri, isAuthenticated, extendSession } from '@axes4/react-common/src/auth/auth0-helper';
import { getConfig } from './config';
const auth0Credentials = getConfig().auth;
const { loginUrl } = getConfig().app;

export const getLoginLink = () => {
    const loginArguments = {
        client_id: auth0Credentials.clientId,
        connection: auth0Credentials.connection,
        audience: auth0Credentials.audience,
        redirect_uri: `${location.protocol}//${location.host}/login`,
        scope: 'openid',
    };
    return loginUrl + getLoginUri(loginArguments).substr(1);
};

export const extendAuthentication = (onError = console.error) => {
    const webAuth = new auth0.WebAuth({
        domain: auth0Credentials.customDomain,
        responseType: 'token id_token',
        clientID: auth0Credentials.clientId,
        connection: auth0Credentials.connection,
        audience: auth0Credentials.audience,
        scope: 'openid',
        redirectUri: `${location.protocol}//${location.host}/login`,
    });
    return extendSession(webAuth, onError, true, false)();
};

window.extendAuthentication = extendAuthentication;
window.isAuthenticated = isAuthenticated;
