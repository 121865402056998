export const partition = <T>(items: T[], predicate: (item: T) => boolean): T[] => {
    const result: [T[], T[]] = [ [], [] ];
    items.forEach(item => {
        if (predicate(item)) {
            result[0].push(item);
        } else {
            result[1].push(item);
        }
    });
    return [ ...result[0], ...result[1] ];
};
