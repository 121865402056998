// @ts-check
import { CardTitle, CardWithClearing } from '@axes4/react-common/src/components/Card';
import InformationIcon from '@axes4/react-icons/jsx/Information';
import WaxSealIcon from '@axes4/react-icons/jsx/WaxSeal';
import UserIcon from '@axes4/react-icons/jsx/User';
import UsergroupIcon from '@axes4/react-icons/jsx/Users3';
import { Box, Button, CardContent, makeStyles, Typography, Tooltip, Link } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { makeRequest } from '../../../hooks/api';
import { updateUsergroupAction } from '../../../state/actions/user-groups';
import { addToUsergroupAction, createUserAction, reinviteUserAction, removeFromUsergroupAction } from '../../../state/actions/users';
import { Layout } from '../../common/App/Layout';
import { LoadingView } from '../../common/EmptyState/Loading';
import { LicenseAssignmentOverview } from '../../common/LicenseMapping/LicenseAssignmentOverview';
import { SelectOrCreateUser } from '../UserList/SelectOrCreateUser';
import { UserList } from '../UserList/UserList';
import EmailIcon from '@axes4/react-icons/jsx/Mail';
import { UsergroupInfo } from './UsergroupInfo';
import { getProducts } from '../../../state/transformations/products';

const useStyles = makeStyles(theme => ({
    emptyChip: {
        '& > svg': {
            marginRight: -12,
        },
    },
    invitedIndicator: {
        marginLeft: theme.spacing(1),
        position: 'relative',
        bottom: -theme.spacing(0.5),
    },
}));

/**
 * @typedef {{
 *   licenseAccount: import('../../../types/accounts').Account,
 *   usergroup: import('../../../types/usergroup').Usergroup,
 *   updateUsergroup: (usergroup: import('../../../types/usergroup').Usergroup) => void,
 *   addToUsergroup: (user: import('../../../types/user').User, usergroupId: import('../../../types/util').Uuid) => void,
 *   removeFromUsergroup: (user: import('../../../types/user').User, usergroupId: import('../../../types/util').Uuid) => void,
 *   assignedUsers: import('../../../types/user').User[],
 *   addableUsers: import('../../../types/user').User[],
 *   licenseAssignments: import('../../../types/licenses').LicenseAssignment[],
 *   products: import('../../../types/licenses').Product[],
 *   onUserCreate: (user: import('../../../types/user').User) => void,
 *   reinviteUser: (user: import('../../../types/user').User) => void,
 * }} UsergroupDetailProps
 */

/**
 * @param {UsergroupDetailProps} props
 */
export const UsergroupDetail = props => {
    const { t } = useTranslation();
    const classes = useStyles({});
    if (!props.usergroup) {
        return <LoadingView/>;
    }
    /**
     * @param {import('../../../types/user').User} user
     */
    const addToGroup = (user) => {
        makeRequest(`licensing:/accounts/${props.licenseAccount.uuid}/user-groups/${props.usergroup.id}/members`, {
            method: 'POST',
            body: JSON.stringify([ user.id ]),
        })
            .then(r => {
                if (r.ok) {
                    props.addToUsergroup(user, props.usergroup.id);
                }
            });
    };
    /**
     * @param {import('../../../types/user').User} user
     */
    const removeFromGroup = (user) => {
        makeRequest(`licensing:/accounts/${props.licenseAccount.uuid}/user-groups/${props.usergroup.id}/members/${user.id}`, {
            method: 'DELETE',
        })
            .then(r => {
                if (r.ok) {
                    props.removeFromUsergroup(user, props.usergroup.id);
                }
            });
    };
    return (
        <Layout pageTitle={props.usergroup.title} pageIcon={<UsergroupIcon/>}>
            <CardWithClearing>
                <CardTitle icon={<InformationIcon/>} title={t('usergroupDetail.info.title')}/>
                <CardContent>
                    <UsergroupInfo
                        usergroup={props.usergroup}
                        assignedUserCount={props.assignedUsers.length}
                        updateUsergroup={props.updateUsergroup}
                    />
                </CardContent>
            </CardWithClearing>
            <CardWithClearing>
                <CardTitle
                    icon={<WaxSealIcon/>}
                    title={t('usergroupDetail.licenses.title')}
                />
                <CardContent>

                    {props.licenseAssignments.length > 0
                    ? (
                        <LicenseAssignmentOverview
                        licenseAssignments={props.licenseAssignments}
                        products={props.products}
                        chipLabelRenderer={() => ''}
                        chipPropsRenderer={({ className }) => ({
                            className: clsx(className, classes.emptyChip),
                        })}
                        chipTooltipRenderer={({ source }) => source}
                    />
                    )
                    : (
                        <>
                            <Typography paragraph>{t('usergroupDetail.licenses.noLicenses')}</Typography>
                            <Button variant="contained" color="primary" component={RouterLink} to="/licenses">{t('usergroupDetail.licenses.editLicensesLink')}</Button>
                        </>
                    )}
                </CardContent>
            </CardWithClearing>
            <CardWithClearing>
                <CardTitle
                    icon={<UserIcon/>}
                    title={t('usergroupDetail.users.title')}
                />
                <CardContent>
                    <Box marginBottom={3}>
                        <SelectOrCreateUser
                            options={props.addableUsers}
                            userExists={email => props.assignedUsers.findIndex(user => user.email === email) >= 0}
                            onSelect={user => addToGroup(user)}
                            onCreate={props.onUserCreate}
                            title={t('usergroupDetail.addUser.title')}
                            buttonLabel={t('usergroupDetail.addUser.button')}
                        />
                    </Box>
                    <UserList
                        users={props.assignedUsers}
                        columns={[
                            {
                                label: (/** @type {string} */(t('userList.table.columns.identifier'))),
                                sortable: true,
                                sorter: (a, b) => a.user.email.localeCompare(b.user.email),
                            },
                            {
                                label: (/** @type {string} */(t('userList.table.columns.name'))),
                                sortable: true,
                            },
                        ]}
                        placeholder={t('userList.table.placeholder')}
                        userListRow={user => [
                            (<Link key="link" component={RouterLink} to={`/users/${user.id}`}>
                                {user.email}
                                {!user.confirmed && (
                                    <Tooltip title={t('user.status.invited')}>
                                        <span className={classes.invitedIndicator}>
                                            <EmailIcon aria-label={t('user.status.invited')} color="primary" size="small"/>
                                        </span>
                                    </Tooltip>
                                )}
                            </Link>),
                            user.name,
                        ]}
                        deleteUserFunction={removeFromGroup}
                        deleteUserButton={t('usergroupDetail.users.removeSelected')}
                        deleteUserConfirmation={users => t('usergroupDetail.users.confirmRemove', { users })}
                        reinviteUser={props.reinviteUser}
                    />
                </CardContent>
            </CardWithClearing>
        </Layout>
    );
};

/**
 * @typedef {{
 *   groupId: import('../../../types/util').Uuid,
 * }} UsergroupDetailContainerProps
 */


/**
 * @param {import('../../../state/store').Store} state
 * @param {UsergroupDetailContainerProps} ownProps
 * @returns {Omit<UsergroupDetailProps, "updateUsergroup"|"addToUsergroup"|"removeFromUsergroup"|"onUserCreate"|"reinviteUser">}
 */
const mapStateToProps = (state, ownProps) => {
    const idString = ownProps.groupId.toString();
    const usergroup = state.usergroups.find(group => group.id.toString() === idString);
    const licenseAssignments = [];
    if (usergroup) {
        state.licenseMapping.forEach(mapping => {
            if ('usergroups' in mapping) {
                const groupMapping = mapping.usergroups.find(m => m.licenseUser.toString() == idString);
                if (groupMapping) {
                    const license = state.licenses.find(l => l.uuid.toString() === mapping.licenseUuid);
                    if (license) {
                        licenseAssignments.push({
                            license: license,
                            sourceType: 'usergroups',
                            source: usergroup.title,
                            sourceId: usergroup.id,
                            activeModules: groupMapping.modules,
                        });
                    }
                }
            }
        });
    }
    const availableModules = licenseAssignments
        .reduce((modules, assignment) => [ ...modules, ...assignment.license.modules ], []);
    return {
        licenseAccount: state.application.account,
        usergroup: usergroup,
        assignedUsers: state.users.filter(user => user.userGroupIds.findIndex(gi => gi.toString() === idString) >= 0),
        addableUsers: state.users.filter(user => user.type === 'default' && user.userGroupIds.findIndex(gi => gi.toString() === idString) < 0),
        products: getProducts(availableModules)(state),
        licenseAssignments,
    };
};

/**
 * @param {import('redux').Dispatch} dispatch
 * @returns {Pick<UsergroupDetailProps, "updateUsergroup"|"addToUsergroup"|"removeFromUsergroup"|"onUserCreate"|"reinviteUser">}
 */
const mapDispatchToProps = dispatch => {
    return {
        updateUsergroup: usergroup => dispatch(updateUsergroupAction(usergroup)),
        addToUsergroup: (user, usergroupId) => dispatch(addToUsergroupAction(user, usergroupId)),
        removeFromUsergroup: (user, usergroupId) => dispatch(removeFromUsergroupAction(user, usergroupId)),
        onUserCreate: (user) => {
            dispatch(createUserAction(user));
            dispatch(reinviteUserAction(user));
        },
        reinviteUser: user => dispatch(reinviteUserAction(user)),
    };
};

export const UsergroupDetailContainer = connect(mapStateToProps, mapDispatchToProps)(UsergroupDetail);
