import '@babel/polyfill';
import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './state/store';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from '@axes4/react-common/src/ui/theme';
import { App } from './components/pages/App';
import { CssBaseline } from '@material-ui/core';
import { PageTitleProvider } from '@axes4/react-common/src/hooks/layout';
import { isOldIE } from '@axes4/react-common/src/util';
import '@axes4/react-common/src/ui/roboto.css';

if (isOldIE()) {
    document.write('Please consider upgrading to a newer web browser');
    throw Error('Detected IE < 11, which is not supported');
}

document.body.appendChild(
    Object.assign(document.createElement('DIV'), {
        id: 'app',
    })
);

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <ReduxProvider store={store}>
            <PageTitleProvider>
                <Router>
                    <CssBaseline/>
                    <App/>
                </Router>
            </PageTitleProvider>
        </ReduxProvider>
    </MuiThemeProvider>,
    document.getElementById('app')
);
