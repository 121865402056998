import { useConfirmation as baseConfirmation, UseConfirmationOptions } from '@axes4/react-common/src/hooks/confirmation';
import { useTranslation } from 'react-i18next';

export const useConfirmation = (options: UseConfirmationOptions = {}) => {
    const { t } = useTranslation();

    const {
        confirmLabel = t('general.confirm'),
        cancelLabel = t('general.cancel'),
    } = options;

    return baseConfirmation({
        confirmLabel,
        cancelLabel,
    });
};
