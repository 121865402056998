import React from 'react';
import { useTranslation } from 'react-i18next';
import { EntityInformation } from '../../common/EntityInformation/EntityInformation';
import { Tooltip, makeStyles } from '@material-ui/core';
import { dateStringToDate } from '../../../util';
import { LicenseApiData, LicenseData, Product } from '../../../types/licenses';

const useStyles = makeStyles(theme => ({
    icon: {
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
    list: {
        margin: 0,
        padding: 0,
    },
    listItem: {
        display: 'inline',
    },
}));

export type LicenseInfoProps = {
    license: LicenseData|LicenseApiData;
    products: Product[];
    activatedUserCount: number;
}

export const LicenseInfo: React.FunctionComponent<LicenseInfoProps> = props => {
    const { t } = useTranslation();
    const classes = useStyles();
    const expireDate = props.license.expireDate
        ? typeof props.license.expireDate === 'string'
            ? dateStringToDate(props.license.expireDate).toLocaleString()
            : props.license.expireDate.toLocaleString()
        : t('licenseDetail.info.noExpireDate');

    return (
        <EntityInformation
            data={{
                [t('licenseDetail.info.key')]: props.license.uuid,
                [t('licenseDetail.info.expireDate')]: expireDate,
                [t('licenseDetail.info.userCountMethod')]: props.license.userCountMethod ? t(`licenseDetail.info.userType.${props.license.userCountMethod}`) : null,
                [t('licenseDetail.info.userCount')]: props.license.userCount || t('licenseDetail.info.unlimited'),
                [t('licenseDetail.info.activatedUserCount')]: props.activatedUserCount || '0',
                [t('licenseDetail.info.deviceCount')]: props.license.deviceCount || t('licenseDetail.info.unlimited'),
                [t('licenseDetail.info.devicesPerUserCount')]: props.license.devicesPerUserCount || t('licenseDetail.info.unlimited'),
                [t('licenseDetail.info.products')]: (
                    <ul className={classes.list}>
                        {props.products.map(product => (
                            <li key={product.id} className={classes.listItem}>
                                <Tooltip title={product.title} >
                                    <img src={product.icon} alt={product.title} className={classes.icon} />
                                </Tooltip>
                            </li>
                        ))}
                    </ul>
                ),
            }}
        />
    );
};
