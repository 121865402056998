import { CardTitle, CardWithClearing } from '@axes4/react-common/src/components/Card';
import InformationIcon from '@axes4/react-icons/jsx/Information';
import WaxSealIcon from '@axes4/react-icons/jsx/WaxSeal';
import UserIcon from '@axes4/react-icons/jsx/User';
import UsergroupIcon from '@axes4/react-icons/jsx/Users3';
import { Button, CardContent, List, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { sortUsergroupsByName } from '../../../state/actions/user-groups';
import { setRolesAction, setUsergroupsAction, updateUserAction } from '../../../state/actions/users';
import { Layout } from '../../common/App/Layout';
import { LoadingView } from '../../common/EmptyState/Loading';
import { LicenseAssignmentOverview } from '../../common/LicenseMapping/LicenseAssignmentOverview';
import { UsergroupListItem } from '../UsergroupList/UsergroupListItem';
import { UserInfo } from './UserInfo';
import { useEditMode } from '../../../hooks/editMode';
import { EditCardButtonWrapper } from '../../common/EditCardButton';
import { ErrorResponse, getNetworkErrorBody, handledRequest, makeRequest } from '../../../hooks/api';
import { getPreferredAccountId } from '../../../state/reducers/application';
import { getAuthenticatedUserId } from '@axes4/react-common/src/auth/auth0-helper';
import { Uuid } from '../../../types/util';
import { AppDispatch, Store } from '../../../state/store';
import { LicenseAssignment, LicenseUuid, Product } from '../../../types/licenses';
import { User } from '../../../types/user';
import { Role } from '../../../types/role';
import { Usergroup } from '../../../types/usergroup';
import { useConfirmation } from '../../../hooks/confirmation';
import { Account } from '../../../types/accounts';
import { MessageLevel, useFlashMessages } from '@axes4/react-common/src/hooks/flashmessage';
import { getProducts } from '../../../state/transformations/products';

const useStyles = makeStyles(theme => ({
    emptyChip: {
        '& > svg': {
            marginRight: -12,
        },
    },
    infoCardSubtitle: {
        paddingLeft: theme.spacing(12),
        marginTop: -theme.spacing(1),
        color: theme.palette.text.secondary,
    },
}));

export interface UserDetailProps {
    user: User;
    currentAccount: Account;
    updateUser: (user: User) => void;
    roles: Role[];
    setRoles: (user: User, roleIds: Uuid[]) => void;
    usergroups: Usergroup[];
    setUsergroups: (user: User, groupIds: Uuid[]) => void;
    licenseAssignments: LicenseAssignment[];
    products: Product[]
}

export const UserDetail: React.FunctionComponent<UserDetailProps> = props => {
    const { t, i18n } = useTranslation();
    const { confirm: confirmDeactivation, render: renderConfirmation } = useConfirmation();
    const classes = useStyles({});
    const [ groupUpdateBusy, setGroupUpdateBusy ] = useState(false);
    const { addMessage } = useFlashMessages();

    const {
        state: userGroupIds,
        set: setUserGroupIds,
        renderEditingButtons: groupsEditingModeButtons,
        renderTriggerButton: groupsEditingModeButton,
        commit: updateEditingMode,
        active: groupsEditingMode,
        disable: disableGroupsEditingMode,
    } = useEditMode(props.user ? props.user.userGroupIds : []);

    useEffect(() => {
        setUserGroupIds(props.user ? props.user.userGroupIds : []);
    }, [ props.user ]);

    const isCurrentUser = props.user && props.user.oAuth2userId === getAuthenticatedUserId();

    const isGroupActive = (groupId: string) => userGroupIds.indexOf(groupId) >= 0;
    const handleGroupToggle = (groupId: string) => () => {
        const nextGroups = isGroupActive(groupId) ? userGroupIds.filter(id => id !== groupId) : [ ...userGroupIds, groupId ];
        setUserGroupIds(nextGroups);
    };

    const handleGroupUpdateComplete = () => {
        setGroupUpdateBusy(false);
        props.setUsergroups(props.user, userGroupIds);
        disableGroupsEditingMode(true);
        updateEditingMode();
    };

    const handeGroupChange = () => {
        const groupsToAdd = userGroupIds.filter(group => props.user.userGroupIds.indexOf(group) < 0);
        const groupsToRemove = props.user.userGroupIds.filter(group => userGroupIds.indexOf(group) < 0);
        let necessaryRequests = groupsToAdd.length + groupsToRemove.length;
        if (necessaryRequests > 0) {
            setGroupUpdateBusy(true);
        } else {
            disableGroupsEditingMode(true);
        }
        groupsToAdd.forEach(group => {
            makeRequest(`licensing:/accounts/${getPreferredAccountId()}/user-groups/${group}/members`, {
                method: 'POST',
                body: JSON.stringify([ props.user.id ]),
            })
                .then(r => {
                    if (r.ok) {
                        if (--necessaryRequests === 0) {
                            handleGroupUpdateComplete();
                        }
                    }
                });
        });
        groupsToRemove.forEach(group => {
            makeRequest(`licensing:/accounts/${getPreferredAccountId()}/user-groups/${group}/members/delete`, {
                method: 'POST',
                body: JSON.stringify([ props.user.id ]),
            })
                .then(r => {
                    if (r.ok) {
                        if (--necessaryRequests === 0) {
                            handleGroupUpdateComplete();
                        }
                    }
                });
        });
    };

    const handleDeactivateLicense = useCallback(async (licenseId: LicenseUuid): Promise<void> => {
        if (!props.currentAccount?.uuid || !props.user?.oAuth2userId) {
            return;
        }
        const message = (
            <>
                {t('licenseAssignment.deactivate.confirmationMessage1')}
                <br/>
                <br/>
                {t('licenseAssignment.deactivate.confirmationMessage2')}
            </>
        );
        const confirmed = await confirmDeactivation(message as unknown as string, t('licenseAssignment.deactivate.confirmationTitle'));
        if (!confirmed) {
            return;
        }

        const licenseName = props.licenseAssignments.find(la => la.license.uuid === licenseId)?.license.name ?? licenseId;

        handledRequest(`licensing:/accounts/${props.currentAccount.uuid}/licenses/${licenseId}/deactivateUserByOAuthId`, {
            method: 'POST',
            body: JSON.stringify({
                oAuth2userId: props.user.oAuth2userId,
            }),
        })
            .then(() => {
                addMessage({
                    variant: MessageLevel.Success,
                    message: (
                        <span>
                            <Trans
                                i18nKey="licenseAssignment.deactivate.success"
                                values={{
                                    license: licenseName,
                                }}
                            />
                        </span>
                    ),
                });
            })
            .catch((e) => {
                if ('response' in e) {
                    getNetworkErrorBody<ErrorResponse>(e)
                        .then(b => {
                            let code = 99;
                            if (b?.error?.code) {
                                code = b.error.code;
                            }
                            let messageKey = `errors::codes.${code}`;
                            if (!i18n.exists(messageKey)) {
                                messageKey = 'translations::licenseAssignment.deactivate.error';
                            }
                            const message = (
                                <span>
                                    <Trans
                                        ns={messageKey.split('::')[0]}
                                        i18nKey={messageKey.split('::')[1]}
                                        values={{ license: licenseName }}
                                    />
                                </span>
                            );

                            const variant = (b?.httpStatus === 404 || (e.response as Response).status === 404)
                                ? MessageLevel.Info
                                : MessageLevel.Error;

                            addMessage({
                                variant,
                                message: message,
                            });
                        });
                } else {
                    addMessage({
                        variant: MessageLevel.Error,
                        message: (
                            <span>
                                <Trans
                                    i18nKey="licenseAssignment.deactivate.error"
                                    values={{
                                        license: licenseName,
                                    }}
                                />
                            </span>
                        ),
                    });
                }
                return false;
            });
    }, [ props.user?.oAuth2userId, props.currentAccount?.uuid, props.licenseAssignments ]);

    if (!props.user) {
        return <LoadingView/>;
    }

    return (
        <Layout pageTitle={props.user.name || props.user.email} pageIcon={<UserIcon/>}>
            <>{renderConfirmation()}</>
            <CardWithClearing>
                <CardTitle icon={<InformationIcon/>} title={t('userDetail.info.title')} />
                {isCurrentUser && <Typography variant="subtitle2" className={classes.infoCardSubtitle}>{t('userDetail.info.itsYou')}</Typography>}
                <CardContent>
                    <UserInfo
                        user={props.user}
                        onSave={props.updateUser}
                        roles={props.roles}
                        editable={!isCurrentUser && props.user.type !== 'agent'}
                    />
                </CardContent>
            </CardWithClearing>
            <CardWithClearing>
                <CardTitle
                    icon={<WaxSealIcon/>}
                    title={t('userDetail.licenses.title')}
                />
                <CardContent>
                    {props.licenseAssignments.length > 0
                    ? (
                        <LicenseAssignmentOverview
                            licenseAssignments={props.licenseAssignments}
                            products={props.products}
                            chipLabelRenderer={({ source, sourceType }) => sourceType === 'users' ? '' : source}
                            chipPropsRenderer={({ className }, { sourceType }) => ({
                                className: clsx(className, sourceType === 'users' && classes.emptyChip),
                            })}
                            chipTooltipRenderer={({ source }) => source}
                            onDeactivateLicense={handleDeactivateLicense}
                        />
                    )
                    : (
                        <>
                            <Typography paragraph>{t('userDetail.licenses.noLicenses')}</Typography>
                            <Button variant="contained" color="primary" component={RouterLink} to="/licenses">{t('userDetail.licenses.editLicensesLink')}</Button>
                        </>
                    )}
                </CardContent>
            </CardWithClearing>
            {props.user.type !== 'agent' && (
                <CardWithClearing>
                    <CardTitle
                        icon={<UsergroupIcon/>}
                        title={t('userDetail.usergroups.title')}
                    />
                    <EditCardButtonWrapper>{groupsEditingModeButton({ label: t('general.edit') })}</EditCardButtonWrapper>
                    {props.user.userGroupIds.length > 0 || groupsEditingMode ? (
                        <List>
                            {props.usergroups.filter(g => groupsEditingMode || isGroupActive(g.id)).map(usergroup => (
                                <UsergroupListItem
                                    key={usergroup.id}
                                    active={isGroupActive(usergroup.id)}
                                    group={usergroup}
                                    onToggle={handleGroupToggle(usergroup.id)}
                                    disabled={!groupsEditingMode || groupUpdateBusy}
                                />
                            ))}
                        </List>
                    ) : (
                        <CardContent>
                            <Typography>{t('userDetail.usergroups.noGroups')}</Typography>
                        </CardContent>
                    )}
                    {groupsEditingMode && (
                        <CardContent>
                            {groupsEditingModeButtons(handeGroupChange, groupUpdateBusy)}
                        </CardContent>
                    )}
                </CardWithClearing>
            )}
        </Layout>
    );
};

export interface UserDetailContainerProps {
    userId: Uuid;
}

const mapStateToProps = (state: Store, ownProps: UserDetailContainerProps) => {
    const userId = ownProps.userId;
    const userIdString = userId.toString();
    const licenseAssignments: LicenseAssignment[] = [];
    const user = state.users.find(user => user.id.toString() === userIdString);

    if (user) {
        state.licenseMapping.forEach(mapping => {
            if ('users' in mapping) {
                const userMapping = mapping.users.find(u => u.licenseUser.toString() === userIdString);
                if (userMapping) {
                    licenseAssignments.push({
                        license: state.licenses.find(l => l.uuid === mapping.licenseUuid),
                        sourceType: 'users',
                        source: user.email,
                        sourceId: user.id.toString(),
                        activeModules: userMapping.modules,
                    });
                }
            }
            if ('usergroups' in mapping) {
                mapping.usergroups.map(({ licenseUser: groupId, modules: activeModules }) => {
                    if (user.userGroupIds.indexOf(groupId) < 0) {
                        return;
                    }
                    const groupIdString = groupId.toString();
                    const group = state.usergroups.find(group => group.id.toString() === groupIdString);
                    if (!group) {
                        return;
                    }

                    licenseAssignments.push({
                        license: state.licenses.find(l => l.uuid === mapping.licenseUuid),
                        sourceType: 'usergroups',
                        source: group?.title ?? group.id.toString(),
                        sourceId: group.id.toString(),
                        activeModules,
                    });
                });
            }
        });
    }

    const availableModules = licenseAssignments
        .reduce((modules, assignment) => [ ...modules, ...(assignment?.license.modules ?? []) ], []);

    return {
        usergroups: sortUsergroupsByName(state.usergroups),
        roles: state.roles,
        products: getProducts(availableModules)(state),
        user,
        licenseAssignments,
        currentAccount: state.application.account,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): Pick<UserDetailProps, 'setUsergroups'|'setRoles'|'updateUser'> => ({
    setUsergroups: (user, usergroupIds) => dispatch(setUsergroupsAction(user, usergroupIds)),
    setRoles: (user, roleIds) => dispatch(setRolesAction(user, roleIds)),
    updateUser: user => dispatch(updateUserAction(user)),
});

export const UserDetailContainer = connect(mapStateToProps, mapDispatchToProps)(UserDetail);
