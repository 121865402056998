import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { split } from '@axes4/react-common/src/util/strings';
import { Chip, makeStyles, Tooltip, Link, ChipProps, IconButton } from '@material-ui/core';
import { omit } from '@axes4/react-common/src/util/objects';
import { VisuallyHidden } from '@axes4/react-common/src/components/Utility/VisuallyHidden';
import UserIcon from '@axes4/react-icons/jsx/User';
import DeactivateIcon from '@axes4/react-icons/jsx/WaxSealRemove';
import UsergroupIcon from '@axes4/react-icons/jsx/Users3';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { LicenseAssignment, LicenseData, LicenseUuid, Product } from '../../../types/licenses';
import { OutlineIconButton } from '../OutlineIconButton';

export interface LicenseAssignmentChipProps {
    to: string;
    icon: any;
    className: string;
}

type AssignmentInfo = Omit<LicenseAssignment, 'license' | 'activeModules'>;

export interface LicenseAssignmentOverviewProps {
    licenseAssignments: LicenseAssignment[];
    products: Product[];
    chipLabelRenderer?: (props: AssignmentInfo) => string;
    chipPropsRenderer?: (props: LicenseAssignmentChipProps, sourceData: AssignmentInfo) => Partial<ChipProps>;
    chipTooltipRenderer?: (props: AssignmentInfo) => string|false,
    onDeactivateLicense?: (license: LicenseUuid) => void;
}

const useStyles = makeStyles(theme => ({
    root: {
        marginLeft: -16,
        marginRight: -16,
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        '& tr > *': {
            padding: theme.spacing(2),
        },
    },
    cell: {
        width: '20%',
        borderLeft: `1px solid ${theme.palette.divider}`,
        '&&': {
            padding: theme.spacing(1),
        },
    },
    iconCell: {
        width: '1%',
    },
    deactivateIcon: {
        '& g path': {
            fill: '#000',
        },
    },
    row: {
        borderTop: `1px solid ${theme.palette.divider}`,
    },
    th: {
        textAlign: 'left',
        minWidth: 180,
    },
    chip: {
        marginBottom: theme.spacing(1),
        '& > span': {
            maxWidth: 90,
            display: 'inline',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
}));

const formatProductTitle = (title: string): JSX.Element => (
    <>
        <span style={{ fontWeight: 400 }}>{split(title, ' ', 1)[0]}</span>
        <strong style={{ display:'block' }}>{split(title, ' ', 1)[1]}</strong>
    </>
);


type BucketedLicenseAssignemnts = { [licenseId: string]: { [moduleId: number]: LicenseAssignment[] } };
const groupLicenseAssignmentsByLicense = (licenseAssignments: LicenseAssignment[]): BucketedLicenseAssignemnts => {
    const buckets: BucketedLicenseAssignemnts = {};
    licenseAssignments.forEach(assignment => {
        const licenseId = assignment.license.uuid;
        if (!(licenseId in buckets)) {
            buckets[licenseId] = {};
        }
        assignment.activeModules.forEach(moduleId => {
            if (!(moduleId in buckets[licenseId])) {
                buckets[licenseId][moduleId] = [];
            }
            buckets[licenseId][moduleId].push(assignment);
        });
    });
    return buckets;
};

const createLicenseMap = (licenseAssignments: LicenseAssignment[]): {[licenseId: string]: LicenseData} =>
    licenseAssignments.reduce((c, assignment) => (c[assignment.license.uuid] = assignment.license, c), {});

export const LicenseAssignmentOverview: React.FunctionComponent<LicenseAssignmentOverviewProps> = props => {
    const { t } = useTranslation();
    const groupedLicenses = useMemo(() => {
        return groupLicenseAssignmentsByLicense(props.licenseAssignments);
    }, [ props.licenseAssignments ]);
    const licenseMap = useMemo(() => {
        return createLicenseMap(props.licenseAssignments);
    }, [ props.licenseAssignments ]);
    const chipLabelRenderer: LicenseAssignmentOverviewProps['chipLabelRenderer'] = props.chipLabelRenderer || (({ source }) => source);
    const classes = useStyles({});

    const getDeactivateLabel = (license: string) =>
        t('licenseAssignment.deactivate.ariaLabel', { license });

    return (
        <div className={classes.root}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th scope="col">{t('licenseAssignment.columns.license')}</th>
                        {props.onDeactivateLicense && (
                            <th scope="col">
                                <VisuallyHidden>
                                    {t('licenseAssignment.columns.deactivate')}
                                </VisuallyHidden>
                            </th>
                        )}
                        {props.products.map(product => (
                            <th key={product.id} className={classes.cell} scope="col">
                                <div>
                                    <img src={product.icon} alt="" width="32" height="32"/>
                                </div>
                                {formatProductTitle(product.title)}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(groupedLicenses).map(([ licenseUuid, mappings ], i) => (
                        <tr key={i}>
                            <th className={clsx(classes.th, classes.row)} scope="row">
                                <Link component={RouterLink} to={`/licenses/${licenseUuid}`}>
                                    {licenseMap[licenseUuid].name}
                                </Link>
                            </th>
                            {props.onDeactivateLicense && (
                                <td className={clsx(classes.iconCell, classes.row)}>
                                    <Tooltip title={t('licenseAssignment.deactivate.tooltip')}>
                                        <OutlineIconButton
                                            label={getDeactivateLabel(licenseMap[licenseUuid].name)}
                                            onClick={() => props.onDeactivateLicense(licenseUuid)}
                                            className={classes.deactivateIcon}
                                            icon={<DeactivateIcon/>}
                                        />
                                    </Tooltip>
                                </td>
                            )}
                            {props.products.map(product => (
                                <td key={product.id} className={clsx(classes.cell, classes.row)}>
                                    {(mappings[product.id] || []).map((assignment, j) => {
                                        const sourceData = omit(assignment, [ 'license', 'activeModules' ]);
                                        const chipProps = {
                                            className: classes.chip,
                                            icon: assignment.sourceType === 'usergroups' ? <UsergroupIcon/> : <UserIcon/>,
                                            to: `/licenses/${licenseUuid}#${assignment.sourceType}_${assignment.sourceId}`,
                                        };
                                        let renderedChipProps = {};
                                        if (props.chipPropsRenderer) {
                                            renderedChipProps = props.chipPropsRenderer(chipProps, sourceData);
                                        }
                                        const tooltip = props.chipTooltipRenderer ? props.chipTooltipRenderer(sourceData) : false;
                                        const chip = (
                                            <Chip
                                                size="small"
                                                key={j}
                                                role={null}
                                                component={RouterLink}
                                                label={chipLabelRenderer(sourceData)}
                                                clickable
                                                aria-label={t(`licenseAssignment.${assignment.sourceType}Link`, { label: assignment.source })}
                                                {...chipProps}
                                                {...renderedChipProps}
                                            />
                                        );
                                        if (tooltip !== false) {
                                            return <Tooltip title={tooltip} key={j}>{chip}</Tooltip>;
                                        }
                                        return chip;
                                    })}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
