import { omit } from '@axes4/react-common/src/util/objects';
import { WithPartial } from '../../types/util';
import { User } from '../../types/user';

export type PotentialUserData = WithPartial<User, 'firstName' | 'lastName'>;

export const apiUserToDomain = (userData: PotentialUserData): User => ({
    ...userData,
    email: userData.email.toLowerCase(),
    name: `${userData.firstName || ''} ${userData.lastName || ''}`.trim(),
    userGroupIds: userData.userGroupIds || [],
    roles: userData.roles || [],
    oAuth2userId: userData.oAuth2userId || '',
    confirmed: userData.status === 2,
});

export const domainUserToApi = (user: User) => omit(user, [ 'confirmed', 'name' ]);
