module.exports = {
    codes: {
        200: 'Erfolg',
        2011: 'Keine passende Lizenz gefunden.',
        3000: 'Nicht authentifiziert.',
        3001: 'Nicht autorisiert.',
        3010: 'Lizenzkonto nicht gefunden.',
        3011: 'Ungültiger Lizenzkontoname.',
        3020: 'Benutzergruppe nicht gefunden.',
        3022: 'Ungültige Benutzergruppe.',
        3023: 'Der Name der Benutzergruppe existiert bereits.',
        3030: 'Benutzer wurde nicht gefunden.',
        3031: 'Einladungsemail konnte nicht gesendet werden.',
        3032: 'Ein Benutzer kann sich nicht selbst löschen.',
        3033: 'Ein Benutzer mit derselben OAuth2-BenutzerId existiert bereits.',
        3034: 'Die Deaktivierung eines Agentenbenutzers ist nicht möglich.',
        3040: 'Einladungs-Parameter fehlen.',
        3041: 'Einladungs-Parameter ungültig.',
        3042: 'Einladung nicht gefunden.',
        3043: 'Ein Benutzer mit dieser Email-Adresse existiert bereits in diesem Lizenzkonto.',
        3044: 'Ungültige Email-Adresse.',
        3051: 'Lizenz ist nicht kompatibel mit dem Typ des Lizenzkontos.',
        3052: 'Lizenz wurde nicht gefunden.',
        3053: 'Modul wurde nicht in der Lizenz gefunden.',
        3054: 'Lizenzschlüssel nicht verfügbar.',
        3056: 'Der Zeitunterschied zwischen Client und Server ist zu groß.',
        3058: 'Lizenz nicht gültig.',
        3061: 'Benutzergruppe mehrfach referenziert.',
        3062: 'Benutzer mehrfach referenziert.',
        3063: 'Lizenz ist dem Benutzer nicht zugewiesen.',
        3070: 'Rolle nicht gefunden.',
        3071: 'Ein Benutzer kann sich nicht selbst die Rollenzuweisung entziehen.',
        3072: 'Ein Benutzer kann sich selbst keine Rollen zuweisen.',
        3073: 'Der Benutzer hat diese Rolle bereits.',
        3074: 'Kann keine Role entfernen, welche der Benutzer nicht hat.',
        3080: 'BenutzerId war leer. Das ist nicht erlaubt und kann ein Zeichen von Miskonfiguration sein.',
        3081: 'Max. Anzahl Geräte für diese Lizenz erreicht.',
        3082: 'Max. Anzahl Benutzer für diese Lizenz erreicht.',
        3083: 'Max. Anzahl Geräte für diesen Benutzer und Lizenz erreicht.',
        3084: 'Versuch, ein nicht zugewiesenes Lizenzmodule zu aktivieren.',
        3085: 'Fehler beim Verarbeiten der Lizenzaktivierungs-Anforderung.',
        3090: 'Das Deaktivierungs-Limit wurde für den Benutzer für die Lizenz <strong>{{license}}</strong> erreicht.',
        3091: 'Konnte nicht deaktivieren, da keine Aktivierungen gefunden wurden.',
        3501: 'Die Lizenz unterstützt keine Deaktivierung.',
        403: 'Nicht autorisiert.',
        404: 'Die Ressource wurde nicht gefunden.',
        500: 'Ein interner Serverfehler ist aufgetreten.',
        5011: 'Lizenz wurde nicht gefunden.',
        5021: 'Lizenzkonto nicht gefunden.',
        6000: 'Ein unerwarteter Fehler ist aufgetreten.',
        6001: 'Sie sind kein Mitglied der angegebenen Domäne.',
        6002: 'Die Active Directory-Gruppe konnte nicht gefunden werden.',
        6003: 'Sie sind kein Mitglied der angegebenen Active Directory-Gruppe.',
        6004: 'Die Active Directory-Gruppe enthält mehr Benutzer als Ihre Lizenz erlaubt.',
        99: 'Ein Fehler ist aufgetreten.',
    },
    common: {
        originalError: 'Die interne Fehlermeldung lautet: {{message}}',
    },
};
