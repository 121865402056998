import { eventManager } from '@axes4/react-common/src/util/eventManager';
import { FLASHMESSAGE_CREATED_ACTION } from '@axes4/react-common/src/hooks/flashmessage';

/**
 * @param {Omit<import("@axes4/react-common/src/hooks/flashmessage").FlashMessage, "id">} message
 */
export const createFlashMessage = message => {
    const messageId = Date.now() + ('' + Math.random() * 1000).substr(2, 5);
    eventManager.emit(FLASHMESSAGE_CREATED_ACTION, {
        ...message,
        id: messageId,
    });
    return messageId;
};
