import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { multilineTranslate } from '../../../util';

/**
 * @typedef {{
 *   open: boolean,
 *   onClose: () => void,
 *   onSubmit: (accountName: string) => void,
 * }} AccountCreationDialogProps
 */

/**
 * @param {AccountCreationDialogProps} props
 */
export const AccountCreationDialog = props => {
    const { t } = useTranslation();
    const mt = multilineTranslate(t);
    const [ accountName, setAccountName ] = useState('');
    const [ isProcessing, setProcessing ] = useState(false);
    const handleSubmit = () => {
        setProcessing(true);
        props.onSubmit(accountName).then(() => setProcessing(false));
    };
    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="account-creation-dialog-title">
            <DialogTitle id="account-creation-dialog-title">{t('account.createAccount.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{mt('account.createAccount.text')}</DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    value={accountName}
                    onChange={e => setAccountName(e.target.value)}
                    label={t('account.createAccount.inputLabel')}
                    fullWidth
                />
                {isProcessing && (
                    <LinearProgress color="primary" />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} disabled={isProcessing}>{t('general.cancel')}</Button>
                <Button onClick={handleSubmit} disabled={isProcessing} color="primary">{t('account.createAccount.submit')}</Button>
            </DialogActions>
        </Dialog>
    );
};
