module.exports = {
    account: {
        createAccount: {
            inputLabel: 'Teamkonto Bezeichnung',
            link: 'Teamkonto erstellen',
            submit: 'Erstellen',
            text: 'Bitte geben Sie den Namen für das neue Team-Lizenzkonto an.',
            title: 'Team-Lizenzkonto erstellen',
        },
        info: {
            defaultLanguage: {
                description: 'In dieser Sprache werden neu eingeladene Nutzer addressiert.',
                label: 'Standardsprache',
            },
            description: {
                description: 'Zur besseren Unterscheidung Ihrer Lizenzkonten',
                label: 'Kurzbeschreibung',
            },
            introText: 'Hier finden Sie alle Einstellungen zum aktuell ausgewählten Lizenzkonto.',
            name: {
                label: 'Name',
            },
            title: 'Lizenzkonto',
        },
        messages: {
            created: 'Das Team-Lizenzkonto {{name}} wurde angelegt.',
            creationError: 'Das Team-Lizenzkonto konnte nicht erstellt werden.',
            error: 'Die Änderungen konnten nicht gespeichert werden.',
            saved: 'Die Änderungen wurden gespeichert.',
        },
        noAccountSelected: {
            text: 'Es ist noch kein Lizenzkonto ausgewählt. Bitte wählen Sie ein Lizenzkonto aus der Liste oder erstellen Sie ein neues.',
            title: 'Lizenzkonto auswählen',
        },
        pageTitle: 'Einstellungen',
        type: {
            personal: {
                title: 'Persönliches Lizenzkonto',
            },
            team: {
                title: 'Team-Lizenzkonto',
            },
        },
    },
    application: {
        title: 'Lizenzverwaltung',
    },
    errorPage: {
        description: 'Es ist ein Fehler aufgetreten.\nVersuchen Sie die Seite neu zu laden.\n\nSollte der Fehler bestehen bleiben, wenden Sie sich an den Support.',
        hideDetails: 'Fehlerdetails ausblenden',
        showDetails: 'Fehlerdetails einblenden',
        title: 'Interner Fehler',
    },
    general: {
        add: 'Hinzufügen',
        cancel: 'Abbrechen',
        changesSaved: 'Die Änderungen wurden gespeichert.',
        confirm: 'Bestätigen',
        delete: 'Löschen',
        discard: 'Verwerfen',
        discardChanges: 'Es gibt nicht gespeicherte Änderungen. Sollen diese verworfen werden?',
        edit: 'Bearbeiten',
        move: 'Verschieben',
        no: 'Nein',
        ok: 'OK',
        save: 'Speichern',
        switchView: 'Zu Ansicht "{{label}}" wechseln',
        yes: 'Ja',
    },
    licenseAssignment: {
        columns: {
            deactivate: 'Lizenz deaktivieren',
            license: 'Lizenz',
        },
        deactivate: {
            ariaLabel: '{{license}} deaktivieren',
            confirmationAction: 'Deaktivieren',
            confirmationMessage1: 'Wenn Sie auf "Deaktivieren" klicken, wird die ausgewählte Lizenz für den aktuellen Benutzer deaktiviert. Sie können die Lizenz anschliessend einem anderen Benutzer zuweisen.',
            confirmationMessage2: 'Beachten Sie, dass dieser Vorgang innerhalb eines Aktivierungszeitraums nur ein Mal pro Benutzer und Lizenz zulässig ist. (Der Aktivierungszeitraum beträgt üblicherweise 30 Tage.)',
            confirmationTitle: 'Lizenz für aktuellen Benutzer deaktivieren',
            error: 'Lizenz <strong>{{license}}</strong> konnte nicht deaktiviert werden.',
            success: 'Lizenz <strong>{{license}}</strong> wurde deaktiviert.',
            tooltip: 'Deaktivieren',
        },
        messages: {
            notSaved: 'Die Lizenzzuordnungen konnten nicht gespeichert werden.',
            updated: 'Die Lizenzzuordnungen wurden gespeichert.',
        },
        usergroupsLink: 'Benutzergruppe {{label}}, Zuweisung bearbeiten',
        usersLink: 'Benutzer {{label}}, Zuweisung bearbeiten',
    },
    licenseDetail: {
        info: {
            activatedUserCount: 'Anzahl aktivierte Benutzer',
            deviceCount: 'Max. Anzahl Geräte',
            devicesPerUserCount: 'Max. Anzahl Geräte pro Benutzer',
            expireDate: 'Ablaufdatum',
            key: 'Lizenzschlüssel',
            noExpireDate: 'Keines',
            products: 'Enthaltene Produkte',
            title: 'Lizenzinformationen',
            unlimited: 'Unbegrenzt',
            userCount: 'Max. Anzahl Benutzer',
            userCountMethod: 'Benutzertyp',
            userType: {
                1: 'axes4 ID',
                2: 'Windows Domain User',
                3: 'Manuell',
            },
        },
        mapping: {
            productDisabled: 'nicht aktiv',
            productEnabled: 'aktiv',
            table: {
                actionLabel: 'Aktion',
                dragHandleLabel: 'Verschieben',
                removeSelectedUsergroups: 'Benutzergruppen entfernen',
                removeSelectedUsers: 'Benutzer entfernen',
                selectAll: 'Alle auswählen',
                selectionStatus: '{{num}} von {{total}} ausgewählt',
                usergroupsTitle: 'Benutzergruppe',
                usersTitle: 'Benutzer',
            },
            title: 'Zuordnungen',
        },
        userLimitWarning: 'Dieser Lizenz sind mehr User zugeordnet als die maximale Anzahl erlaubt ({{actual}}/{{limit}}). Reduzieren Sie die Anzahl der zugeordneten User, um Probleme bei der Lizenzierung zu vermeiden.',
        usergroups: {
            addGroup: {
                label: 'Benutzergruppe auswählen',
                placeholder: 'Keine Benutzergruppen gefunden',
                submit: 'Zuordnung festlegen',
            },
            assignUsergroup: 'Benutzergruppe zuordnen',
            createGroup: 'Benutzergruppe anlegen',
            title: 'Benutzergruppen',
            usergroupSelection: {
                cancel: 'Abbrechen',
                existingUser: 'Benutzergruppe ist bereits zugeordnet',
                inputLabel: 'Name der Benutzergruppe',
                ok: 'Benutzergruppe hinzufügen',
                placeholder: 'Benutzergruppe {{input}} wird erstellt',
                text: 'Wählen Sie eine Benutzergruppe aus oder legen Sie eine neue an.',
                title: 'Dieser Lizenz eine Nutzergruppe zuordnen.',
            },
        },
        users: {
            addUser: {
                label: 'Benutzer auswählen',
                placeholder: 'Keine Benutzer gefunden',
                submit: 'Zuordnung festlegen',
            },
            assignUser: 'Benutzer zuordnen',
            inviteUser: 'Benutzer einladen',
            title: 'Benutzer',
            userSelection: {
                cancel: 'Abbrechen',
                existingUser: 'Benutzer ist bereits zugeordnet',
                inputLabel: 'E-Mail',
                invalidInput: 'Keine gültige E-Mail-Adresse',
                ok: 'Benutzer hinzufügen',
                placeholder: 'Benutzer {{input}} wird eingeladen',
                text: 'Wählen Sie einen Benutzer aus.',
                title: 'Dieser Lizenz einen Benutzer zuordnen',
            },
        },
    },
    licenseList: {
        pageTitle: 'Lizenzen',
        table: {
            columns: {
                assignedUsergroups: 'Zugewiesene Benutzergruppen',
                assignedUsers: 'Zugewiesene Benutzer',
                containedProducts: 'Enthaltene Produkte',
                title: 'Lizenz',
            },
            deleteSelected: 'Ausgewählte löschen',
            linkTitle: 'Lizenz {{item}} bearbeiten',
            placeholder: 'Keine Lizenzen gefunden',
            selectItem: '{{item}} auswählen',
            selectionStatus: '{{num}} von {{total}} ausgewählt',
        },
    },
    loading: {
        authenticating: 'Anmeldung läuft...',
        errorFetching: 'Die Ressource {{resource}} konnte nicht geladen werden ({{status}}).',
        fetching: 'Lizenzkonto wird geladen...',
        loading: 'Benutzer wird geladen...',
        pageTitle: 'Anwendung wird geladen',
        text: 'Inhalte werden geladen...',
    },
    loggedOut: {
        action: 'Zum axes4 Login',
        text: 'Ihre Sitzung ist abgelaufen. Melden Sie sich erneut an, um fortzufahren.',
        title: 'Abgemeldet',
    },
    login: {
        'loggedin as': 'Angemeldet als',
        logout: 'Abmelden',
        profileLink: 'Profil bearbeiten',
    },
    manualActivation: {
        activate: 'Aktivieren',
        activationErrors: 'Bei der Verarbeitung der Aktivierungsanfragen sind Fehler aufgetreten',
        description: 'Sie erhalten hier die Aktivierungsdateien für Ihre Offline-Geräte in drei Schritten.<br/>Voraussetzung: Sie haben dort eine Datei mit einer Offline-Aktivierungsanforderung generiert.<br/>',
        downloadFile: 'Speichere Datei "{{file}}"',
        downloadableFiles: 'Laden Sie die Aktivierungsdateien herunter',
        error: 'Es ist ein Fehler aufgetreten.',
        files: {
            dropzone: 'Dateien hier ablegen zum Auswählen',
            errors: {
                disallowedType: 'Der Typ der Datei "{{file}}" wird nicht unterstützt. Wählen Sie eine JSON Datei aus.',
                exists: 'Die Datei "{{file}}" ist bereits ausgewählt.',
                fileSize: 'Die Datei "{{file}}" ist zu groß. Prüfen Sie, ob sie die korrekte Datei ausgewählte haben.',
            },
            select: 'Dateien auswählen',
            selectedFiles: 'Ausgewählte Dateien',
        },
        loading: 'Aktivierungsdateien werden vorbereitet',
        pageTitle: 'Manuelle Aktivierung',
        steps: '<li>Wählen Sie die Datei mit der Aktivierungsanforderung aus. Sie können auch mehrere Dateien (von mehreren Offline-Geräten) auswählen.</li><li>Selektieren Sie die Produkte, welche Sie auf den Offline-Computern aktivieren möchten.</li><li>Klicken Sie auf <strong>Aktivieren</strong>. Sie erhalten je eine Aktivierungsdatei pro Computer und Produkt zum Download, die Sie per mobilem Datenträger auf den Offline-Computer übertragen können.</li>',
    },
    notFound: {
        button: 'Zurück zur Startseite',
        pageTitle: '404',
        text: 'Diese Seite existiert nicht.',
        title: 'Seite nicht gefunden',
    },
    registerLicense: {
        'account:type:incompatible': 'Die Lizenz ist nicht kompatibel zum Kontotyp.',
        activating: 'Lizenzschlüssel wird aktiviert\nDies kann einen Moment dauern.',
        alreadyRegistered: 'Der eingegebene Lizenzschlüssel ist bereits zugeordnet.',
        alreadyRegisteredAccount: 'Der eingegebene Schlüssel ist bereits dem Lizenzkonto "{{account}}" zugeordnet.',
        alreadyRegisteredThisAccount: 'Der eingegebene Lizenzschlüssel ist bereits diesem Lizenzkonto zugeordnet.',
        checkingKey: 'Schlüssel wird geprüft',
        description: 'Bitte geben Sie einen Lizenzschlüssel ein.',
        error: 'Die Lizenz konnte Ihrem Lizenzkonto nicht hinzugefügt werden.',
        invalidKey: 'Der eingegebene Lizenzschlüssel ist nicht gültig oder bereits registriert. Überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.',
        'license:type:incompatible:personal': 'Sie versuchen, eine persönliche Lizenz zu einem Team-Lizenzkonto hinzuzufügen. Dies ist nicht möglich. Bitte fügen Sie diese Lizenz zu einem persönlichen Lizenzkonto hinzu.',
        'license:type:incompatible:team': 'Sie versuchen, eine Team-Lizenz zu einem persönlichen Lizenzkonto hinzuzufügen. Dies ist nicht möglich. Bitte fügen Sie diese Lizenz zu einem Team-Lizenzkonto hinzu.',
        licenseKey: 'Lizenzschlüssel',
        oldKey: 'Dies ist ein alter Lizenzschlüssel. Wollen Sie diesen nun in einen neuen eintauschen und dem Lizenzkonto hinzufügen?',
        submit: 'Lizenzschlüssel prüfen',
        success: 'Die Lizenz {{name}} wurde Ihrem Lizenzkonto hinzugefügt.',
        title: {
            personal: 'Persönliche Lizenz hinzufügen',
            team: 'Team-Lizenz hinzufügen',
        },
        validKey: 'Wir haben Ihren Lizenzschlüssel gefunden. Wollen Sie diesen nun dem Lizenzkonto hinzufügen?\nDies kann nur einmal durchgeführt werden.',
    },
    user: {
        roles: {
            admin: 'Administrator',
            agent: 'Lizensierungsagent',
        },
        status: {
            active: 'Aktiv',
            invitationError: 'Es konnte keine Einladung verschickt werden.',
            invitationSent: 'Eine Einladung wurde an {{email}} verschickt.',
            invited: 'Eingeladen',
            label: 'Status',
            reinvite: 'Erneute Einladung verschicken',
        },
    },
    userDetail: {
        info: {
            email: 'E-Mail',
            itsYou: 'Ihr Konto',
            name: 'Name',
            noRoles: 'Keine Rollen zugewiesen',
            roles: 'Rollen',
            status: 'Status',
            title: 'Benutzerinformationen',
        },
        licenses: {
            editLicensesLink: 'Lizenzen verwalten',
            noLicenses: 'Dieser Nutzer ist keinen Lizenzen zugewiesen.',
            title: 'Lizenzen',
        },
        usergroups: {
            noGroups: 'Dieser Benutzer ist keinen Gruppen zugewiesen.',
            title: 'Benutzergruppen',
        },
    },
    userList: {
        addUserDialog: {
            add: 'Einladen',
            cancel: 'Abbrechen',
            description: 'Bitte geben Sie die E-Mail Adresse eines Benutzer ein, der hinzugefügt werden soll. Dieser Benutzer erhält daraufhin eine E-Mail, um sein axes4 Konto zu bestätigen.',
            email: {
                label: 'E-Mail Adresse',
            },
            title: 'Benutzer einladen',
        },
        inviteUserButton: 'Benutzer einladen',
        pageTitle: 'Benutzer',
        table: {
            columns: {
                assignedLicenses: 'Zugewiesene Lizenzen',
                groups: 'Benutzergruppen',
                identifier: 'Benutzer',
                name: 'Name',
                roles: 'Rollen',
                status: 'Status',
            },
            confirmDelete: 'Folgende Benutzer löschen: {{users}}',
            deleteSelected: 'Ausgewählte löschen',
            linkTitle: 'Benutzer {{item}} bearbeiten',
            placeholder: 'Keine Benutzer gefunden',
            reinviteSelected: 'Erneut einladen',
            selectItem: '{{item}} auswählen',
            selectionStatus: '{{num}} von {{total}} ausgewählt',
        },
    },
    usergroupDetail: {
        addUser: {
            button: 'Dieser Benutzergruppe einen Benutzer hinzufügen',
            title: 'Dieser Benutzergruppe einen Benutzer hinzufügen',
        },
        info: {
            assignedUsers: 'Zugewiesene Benutzer',
            label: 'Bezeichnung',
            title: 'Benutzergruppeninformation',
        },
        licenses: {
            editLicensesLink: 'Lizenzen verwalten',
            noLicenses: 'Dieser Nutzergruppe ist keine Lizenz zugewiesen.',
            title: 'Lizenzen',
        },
        users: {
            confirmRemove: 'Folgende Benutzer aus der Gruppe entfernen: {{users}}',
            removeSelected: 'Ausgewählte entfernen',
            title: 'Benutzer',
        },
    },
    usergroupList: {
        addUsergroupDialog: {
            add: 'Neue Benutzergruppe anlegen',
            cancel: 'Abbrechen',
            description: 'Bitte geben Sie einen Namen für die neue Benutzergruppe an.',
            link: 'Benutzergruppe anlegen',
            messages: {
                error: 'Fehler beim Erstellen der Benutzergruppe',
                success: 'Benutzergruppe wurde erstellt',
            },
            title: 'Benutzergruppe anlegen',
            value: {
                label: 'Benutzergruppen-Name',
            },
        },
        pageTitle: 'Benutzergruppen',
        table: {
            columns: {
                assignedLicenses: 'Zugewiesene Lizenzen',
                assignedUsers: 'Hinzugefügte Benutzer',
                title: 'Benutzergruppe',
            },
            confirmDelete: 'Folgende Benutzergruppen löschen: {{groups}}',
            deleteSelected: 'Ausgewählte löschen',
            linkTitle: 'Benutzergruppe {{item}} bearbeiten',
            placeholder: 'Keine Benutzergruppen gefunden',
            selectItem: '{{item}} auswählen',
            selectionStatus: '{{num}} von {{total}} ausgewählt',
        },
    },
};
