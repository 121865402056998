import { useEffect, useState } from 'react';
import { $merge } from 'plow-js';
import { getAuthenticationToken, isAuthenticated } from '@axes4/react-common/src/auth/auth0-helper';
import { getConfig } from '../config';
const { apiUrls } = getConfig().app;

export const createNetworkError = (message: string, statusCode: number, response: Response|null = null) => ({
    name: 'NetworkError',
    message,
    statusCode,
    response,
});
type NetworkError = ReturnType<typeof createNetworkError>;

export type ErrorResponse = {
    error: {
        code: number,
        message: string,
    },
    httpStatus?: number,
}
export const getNetworkErrorBody = <R = unknown>(e: NetworkError|null): Promise<R|null> =>
    e && e.response
        ? e.response.text().then(t => JSON.parse(t)).catch(() => null)
        : Promise.resolve(null);

export const getErrorMessage = (errorMessage: string, networkError: NetworkError|null = null, omitNetworkErrorMessage = false): Promise<string> =>
    getNetworkErrorBody<ErrorResponse>(networkError).then(errorBody => {
        let message = errorMessage;
        if (errorBody && errorBody.error && errorBody.error.code) {
            message += ` (${errorBody.error.code})`;
            if (errorBody.error.message) {
                message += `\n${errorBody.error.message}`;
            }
        } else if (networkError && networkError.statusCode) {
            message += ` (${networkError.statusCode})`;
            if (networkError.statusCode && !omitNetworkErrorMessage) {
                message += `\n${networkError.message}`;
            }
        }
        return message;
    });

export const resolveEndpoint = (endpoint: string): string => {
    const m = endpoint.match(/([\w-]+):\/(.*)$/);
    if (!m) {
        return endpoint;
    }
    const type = m[1];
    const path = m[2];
    return (apiUrls[type] || '') + '/' + path;
};

/**
 * @param {string} endpoint Api endpoint without api version
 * @param {RequestInit} options Additional request options
 */
export const makeRequest = (endpoint: string, options: RequestInit = {}): Promise<Response> => {
    const defaultOptions = {
        credentials: 'include',
        headers: {
        } as Record<string, string>,
    } satisfies RequestInit;
    const token = getAuthenticationToken();
    if (isAuthenticated() && token) {
        defaultOptions.headers.Authorization = `Bearer ${token}`;
    }
    const init = $merge([], options, defaultOptions);
    return fetch(resolveEndpoint(endpoint), init);
};

/**
 * @param {string} endpoint Api endpoint without api version
 * @param {RequestInit} options Additional request options
 */
export const handledRequest = <T>(endpoint: string, options: RequestInit = {}): Promise<T|null> => {
    return makeRequest(endpoint, options)
        .then(r => {
            if (r.ok) {
                // no content by definition
                if (r.status === 204) {
                    return null;
                }
                const contentLength = r.headers.get('Content-Length');
                if (contentLength === null || Number(r.headers.get('Content-Length')) > 0) {
                    return r.json();
                } else {
                    return null;
                }
            }
            throw createNetworkError(`Error requesting ${options.method || 'GET'} ${endpoint}`, r.status, r);
        })
        .then(d => {
            if (d) {
                if ('data' in d && d.data !== null) {
                    return d.data;
                }
                return d;
            }
            return null;
        });
};

type UseApiOptions = {
    fetchOnLoad?: boolean;
    requestOptions?: RequestInit;
}

type UseApiResponse = {
    startRequest: (options?: RequestInit) => Promise<Response>;
    response: Promise<Response>;
    status: number;
    successful: boolean;
    loading: boolean;
}

/**
 * @param endpoint API endpoint without api version
 * @param options
 */
export const useApi = (endpoint: string, options: UseApiOptions = {}): UseApiResponse => {
    const { fetchOnLoad = true, requestOptions = {} } = options;
    const [ loading, setLoading ] = useState<boolean|null>(false);
    const [ status, setStatus ] = useState<number|null>(null);
    const [ successful, setSuccessful ] = useState<boolean|null>(null);
    const [ response, setResponse ] = useState<Promise<Response>|null>(null);
    /**
     * @param {RequestInit?} options
     */
    const startRequest = (options = {}) => {
        setLoading(true);
        setStatus(null);
        setSuccessful(null);
        const request = makeRequest(endpoint, $merge([], requestOptions, options))
            .then(r => {
                setLoading(false);
                setStatus(r.status);
                setSuccessful(r.ok);
                return r;
            });
        setResponse(request);
        return request;
    };

    if (fetchOnLoad) {
        useEffect(() => {
            startRequest();
        }, []);
    }
    return {
        startRequest,
        response,
        status,
        successful,
        loading,
    };
};
