import React from 'react';
import { ButtonProps, makeStyles, Theme } from '@material-ui/core';
import { OutlineIconButton } from './OutlineIconButton';

const useStyles = makeStyles<Theme, { isCardContent?: boolean }>(theme => ({
    button: {
        position: 'absolute',
        right: theme.spacing(2),
        top: -theme.spacing(6),
    },
    wrapper: {
        position: 'relative',
        margin: props => props.isCardContent ? -16 : null,
    },
}));

/**
 * Wrapper around the EditCard Button for better positioning
 */
export const EditCardButtonWrapper: React.FunctionComponent<React.PropsWithChildren<{
     isCardContent?: boolean;
}>> = props => {
    const classes = useStyles(props);
    return <div className={classes.wrapper}>{props.children}</div>;
};

export type EditCardButtonProps = {
    buttonProps?: ButtonProps;
    icon?: React.ReactNode;
    label: NonNullable<React.ReactNode>;
    onClick: ButtonProps['onClick'];
    className?: string;
}

export const EditCardButton = React.forwardRef<HTMLButtonElement, EditCardButtonProps>((props, ref) => {
    const classes = useStyles({});

    return (
        <OutlineIconButton
            ref={ref}
            icon={props.icon}
            className={classes.button}
            label={props.label}
            onClick={props.onClick}
        />
    );
});
EditCardButton.displayName = 'EditCardButton';
