import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelectOrCreate, UseSelectOrCreateOptions } from '../../../hooks/selectOrCreate';
import { Dialog, DialogContent, DialogContentText, DialogActions, Button, makeStyles, DialogTitle } from '@material-ui/core';
import { useId } from '../../../hooks/useId';

const useStyles = makeStyles(() => ({
    root: {
        width: 500,
    },
}));

export type SelectOrCreateDialogProps<T extends { id: number|string }> = {
    open: boolean;
    content: React.ReactNode;
    title?: React.ReactNode;
    busy?: boolean;
    onCancel: () => void;
    onSelect: (option: T) => void;
    onCreate: (option: string) => void;
    validateCustomOption?: (option: string) => React.ReactNode|undefined;
    inputLabel: string;
    cancelLabel: string;
    submitLabel: string;
} & Omit<UseSelectOrCreateOptions<T>, 'label'>;

export const SelectOrCreateDialog = <T extends { id: number|string }>(props: SelectOrCreateDialogProps<T>) => {
    const classes = useStyles({});
    const { onCancel } = props;
    const [ hasError, setHasError ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const handleSubmit = useRef(null);
    const formId = useId('form');

    const {
        value,
        customValue,
        render: renderAutocomplete,
        hasValue,
        clear,
    } = useSelectOrCreate({
        options: props.options,
        label: props.inputLabel,
        autoFocus: true,
        getEntityLabel: props.getEntityLabel,
        getEntityDisabled: props.getEntityDisabled,
        placeholder: props.placeholder,
        autocompleteProps: {
            ...(props.autocompleteProps || {}),
        },
        textFieldProps: {
            ...(props.textFieldProps || {}),
            error: hasError,
            helperText: errorMessage,
        },
        disabled: props.disabled,
    });

    useEffect(() => {
        setHasError(false);
        setErrorMessage(null);
    }, [ customValue ]);

    useEffect(() => {
        clear();
        setHasError(false);
        setErrorMessage(null);
    }, [ props.open ]);

    const handleCancel = useCallback(() => {
        onCancel();
    }, [ onCancel ]);

    handleSubmit.current = () => {
        if (customValue || (!!value && value.id === -1)) {
            const newValue = customValue || value.newValue;
            let valid = true;
            if (props.validateCustomOption) {
                const validationResult = props.validateCustomOption(newValue);
                if (validationResult !== null && validationResult !== true) {
                    valid = false;
                    setHasError(true);
                    setErrorMessage(validationResult);
                }
            }
            if (valid) {
                props.onCreate(newValue);
            }
        } else if (value) {
            props.onSelect((/** @type {T} */(/** @type {unknown} */(value))));
        }
    };

    return (
        <Dialog open={props.open} onClose={props.onCancel} PaperProps={{ className: classes.root }}>
            {props.title && <DialogTitle>{props.title}</DialogTitle>}
            <DialogContent>
                <form id={formId} onSubmit={e => { e.preventDefault(); handleSubmit.current(); }}>
                    <DialogContentText>{props.content}</DialogContentText>
                    {renderAutocomplete()}
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} disabled={props.busy}>{props.cancelLabel}</Button>
                <Button form={formId} type="submit" color="primary" disabled={props.busy || !hasValue}>{props.submitLabel}</Button>
            </DialogActions>
        </Dialog>
    );
};

export type SelectOrCreateDialogHandlerProps<T extends { id: number|string }> = Omit<SelectOrCreateDialogProps<T>, 'onCancel'|'open'> & {
    /**
     * Label of the dialog trigger button
     */
    children: React.ReactNode;
};

export const SelectOrCreateDialogHandler = <T extends { id: number|string }>(props: SelectOrCreateDialogHandlerProps<T>) => {
    const [ open, setOpen ] = useState(false);
    const { onSelect, onCreate } = props;

    const handleCancel = useCallback(() => {
        if (!props.busy) {
            setOpen(false);
        }
    }, [ props.busy ]);

    const handleSelect = useCallback((value: T) => {
        onSelect(value);
        if (!props.busy) {
            setOpen(false);
        }
    }, [ props.busy, onSelect ]);

    const handleCreate = useCallback((value: string) => {
        onCreate(value);
        if (!props.busy) {
            setOpen(false);
        }
    }, [ onCreate, props.busy ]);

    const handleTrigger = useCallback(() => {
        setOpen(true);
    }, []);

    // TODO do we need custom handling before closing? e.g. how do we handle validation?
    return (
        <>
            <SelectOrCreateDialog
                {...props}
                open={open}
                onCancel={handleCancel}
                onSelect={handleSelect}
                onCreate={handleCreate}
            />
            <Button
                onClick={handleTrigger}
                variant="contained"
                color="primary"
            >
                {props.children}
            </Button>
        </>
    );
};
