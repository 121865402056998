import { createStore, compose, applyMiddleware } from 'redux';
import reducer from './reducers';
import { apiDateToDateObjects } from '../util';
import { products } from '@axes4/react-common/src/data/products';
import { asyncDispatchMiddleware } from './asyncDispatchMiddleware';
import { LicenseApiData, LicenseData, LicenseMapping, Product } from '../types/licenses';
import { Account } from '../types/accounts';
import { User } from '../types/user';
import { Usergroup } from '../types/usergroup';
import { Role } from '../types/role';
import { useDispatch, useSelector } from 'react-redux';

export type Store = {
    licenseMapping: LicenseMapping[];
    application: {
        account: Account;
        user: User;
        fetching: number;
        loading: boolean;
        authenticating: boolean;
        authenticated: boolean;
    },
    users: User[];
    licenses: LicenseData[];
    usergroups: Usergroup[];
    products: Product[];
    roles: Role[];
}

const initialState = {
    roles: [
        'admin',
    ],
    users: [
    ],
    usergroups: [
    ],
    products,
    licenseMapping: [
    ],
    licenses: [
    ],
} as Store;

const licenseApiDataToData = apiDateToDateObjects([ 'creationDate', 'expireDate', 'modificationDate', 'startDate' ]);
initialState.licenses = (initialState.licenses as unknown as LicenseApiData[]).map(licenseApiDataToData) as LicenseData[];

export const store = createStore(
    reducer,
    initialState,
    compose(
        applyMiddleware(asyncDispatchMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);

export const useAppSelector = useSelector<Store>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: typeof compose;
    }
}
