import React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    cell: {
        ...theme.typography.body2,
        padding: `${theme.spacing(0.5)}px 0`,
    },
    th: {
        textAlign: 'left',
        verticalAlign: 'top',
        fontWeight: theme.typography.fontWeightBold,
        paddingRight: theme.spacing(2),
    },
}));

/**
 * @typedef {{
 *   data: {[key: string]: React.ReactChild},
 * }} EntityInformationProps
 */

/**
 * @param {EntityInformationProps} props
 */
export const EntityInformation = props => {
    const classes = useStyles();
    return (
        <table>
            <tbody>
                {Object.entries(props.data).map(([ key, value ], i) => value !== null ? (
                    <tr key={i}>
                        <th className={clsx(classes.th, classes.cell)}>{key}</th>
                        <td className={classes.cell}>{value}</td>
                    </tr>
                ) : '')}
            </tbody>
        </table>
    );
};
