import { useEditMode as baseEditMode } from '@axes4/react-common/src/hooks/editMode';
import { useTranslation } from 'react-i18next';

export const useEditMode = <T>(initialData: T) => {
    const { t } = useTranslation();
    return baseEditMode<T>(initialData, {
        discardLabel: t('general.discard'),
        saveLabel: t('general.save'),
        confirmLabel: t('general.confirm'),
        cancelLabel: t('general.cancel'),
        confirmationMessage: t('general.discardChanges'),
    });
};
