import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { makeRequest } from '../../../hooks/api';
import { createUsergroupAction } from '../../../state/actions/user-groups';
import { createFlashMessage } from '../../../state/reducers/flashmessages';
import { apiUsergroupToDomain } from '../../../state/transformations/usergroups';
import { multilineTranslate } from '../../../util';
import { SelectOrCreateDialogHandler } from '../../common/AddThroughAutocomplete/SelectOrCreateDialog';
import { Usergroup } from '../../../types/usergroup';
import { Account } from '../../../types/accounts';
import { MessageLevel } from '@axes4/react-common/src/hooks/flashmessage';
import { AppDispatch, Store } from '../../../state/store';

export type SelectOrCreateUsergroupProps = {
    options: Usergroup[];
    licensingAccount: Account;
    usergroupExists: (title: string) => boolean;
    onCreate: (usergroup: Usergroup) => void;
    onSelect: (usergroup: Usergroup) => void;
}

const getEntityLabel = (item: Usergroup) => {
    return item.title ?? item.toString();
};

/**
 * @param {SelectOrCreateUsergroupProps} props
 */
export const SelectOrCreateUsergroup: React.FunctionComponent<SelectOrCreateUsergroupProps> = props => {
    const { t } = useTranslation();
    const mt = multilineTranslate(t);
    const handleCreate = title => {
        makeRequest(`licensing:/accounts/${props.licensingAccount.uuid}/user-groups`, {
            method: 'POST',
            body: JSON.stringify({ name: title }),
        })
            .then(r => {
                if (r.ok) {
                    return r.json();
                }
                createFlashMessage({
                    message: t('usergroupList.addUsergroupDialog.messages.error'),
                    variant: MessageLevel.Error,
                });
            })
            .then(d => {
                if (d.data) {
                    const domainGroup = apiUsergroupToDomain(d.data);
                        props.onCreate(domainGroup);
                        props.onSelect(domainGroup);
                        createFlashMessage({
                            message: t('usergroupList.addUsergroupDialog.messages.success'),
                            variant: MessageLevel.Success,
                        });
                        return domainGroup;
                } else {
                    createFlashMessage({
                        message: t('usergroupList.addUsergroupDialog.messages.error'),
                        variant: MessageLevel.Error,
                    });
                }
            });
    };

    const validateUsergroup = useCallback((title: string) => {
        if (props.usergroupExists(title)) {
            return t('licenseDetail.usergroups.usergroupSelection.existingUser');
        }
        return true;
    }, [ props.usergroupExists, t ]);

    return (
        <SelectOrCreateDialogHandler
            options={props.options}
            title={t('licenseDetail.usergroups.usergroupSelection.title')}
            content={mt('licenseDetail.usergroups.usergroupSelection.text')}
            inputLabel={t('licenseDetail.usergroups.usergroupSelection.inputLabel')}
            // TODO handle too long values
            placeholder={input => t('licenseDetail.usergroups.usergroupSelection.placeholder', { input })}
            // placeholder={t('licenseDetail.usergroups.usergroupSelection.placeholder')}
            submitLabel={t('licenseDetail.usergroups.usergroupSelection.ok')}
            cancelLabel={t('licenseDetail.usergroups.usergroupSelection.cancel')}
            onSelect={props.onSelect}
            onCreate={handleCreate}
            getEntityLabel={getEntityLabel}
            validateCustomOption={validateUsergroup}
        >
            {t('licenseDetail.usergroups.assignUsergroup')}
        </SelectOrCreateDialogHandler>
    );
};

export type SelectOrCreateUsergroupContainerProps = Omit<SelectOrCreateUsergroupProps, 'usergroupExists'|'onCreate'|'licensingAccount'>
    & Partial<Pick<SelectOrCreateUsergroupProps, 'usergroupExists'>>;

const mapStateToProps = (state: Store, ownProps: SelectOrCreateUsergroupContainerProps): Omit<SelectOrCreateUsergroupProps, 'onCreate'> => ({
    usergroupExists: title => state.usergroups.find(group => group.title === title) !== undefined,
    licensingAccount: state.application.account,
    ...ownProps,
});

const mapDispatchToProps = (dispatch: AppDispatch): Pick<SelectOrCreateUsergroupProps, 'onCreate'> => ({
    onCreate: usergroup => dispatch(createUsergroupAction(usergroup)),
});

export const SelectOrCreateUsergroupContainer = connect(mapStateToProps, mapDispatchToProps)(SelectOrCreateUsergroup);
