import GearwheelIcon from '@axes4/react-icons/jsx/Gearwheel';
import WaxSealIcon from '@axes4/react-icons/jsx/WaxSeal';
import UserIcon from '@axes4/react-icons/jsx/User';
import UsergroupIcon from '@axes4/react-icons/jsx/Users3';
import KeyIcon from '@axes4/react-icons/jsx/Key3';

const navigationItems = {
    licenses: {
        path: '/licenses',
        label: 'licenseList.pageTitle',
        icon: WaxSealIcon,
    },
    users: {
        path: '/users',
        label: 'userList.pageTitle',
        icon: UserIcon,
    },
    groups: {
        path: '/groups',
        label: 'usergroupList.pageTitle',
        icon: UsergroupIcon,
    },
    manualActivation: {
        path: '/manual-activation',
        label: 'manualActivation.pageTitle',
        icon: KeyIcon,
    },
    account: {
        path: '/account',
        label: 'account.pageTitle',
        icon: GearwheelIcon,
    },
};

export const personalAccountNavigation = [
    navigationItems.licenses,
    navigationItems.account,
];

export const navigation = [
    navigationItems.licenses,
    navigationItems.users,
    navigationItems.groups,
    navigationItems.manualActivation,
    navigationItems.account,
];
