import { USERGROUPS_ACTIONS, setUsergroupsAction } from '../actions/user-groups';
import { makeRequest, handledRequest } from '../../hooks/api';
import { getPreferredAccountId } from './application';
import { apiUsergroupToDomain, domainUsergroupToApi } from '../transformations/usergroups';
import { fetchCompleteAction, addLoadingErrorAction } from '../actions/application';
import { pick } from '@axes4/react-common/src/util/objects';

export const usergroups = (state = [], action) => {
    switch (action.type) {
        case USERGROUPS_ACTIONS.INITIALIZE:
            handledRequest(`licensing:/accounts/${action.payload.accountId}/user-groups`)
                .then(data => {
                    if (data) {
                        action.asyncDispatch(setUsergroupsAction(data.map(apiUsergroupToDomain)));
                    } else {
                        action.asyncDispatch(setUsergroupsAction([]));
                    }
                    action.asyncDispatch(fetchCompleteAction());
                })
                .catch(e => {
                    action.asyncDispatch(addLoadingErrorAction('loading.errorFetching', { resource: '"usergroups"', status: e.statusCode }));
                    action.asyncDispatch(setUsergroupsAction([]));
                    // action.asyncDispatch(fetchCompleteAction());
                });
            return state;
        case USERGROUPS_ACTIONS.SET:
            return action.payload;
        case USERGROUPS_ACTIONS.CREATE:
            /** @type {ReturnType<import('../actions/user-groups').createUsergroupAction>} action */
            return state.concat([ action.payload ]);
        case USERGROUPS_ACTIONS.UPDATE:
            makeRequest(`licensing:/accounts/${getPreferredAccountId()}/user-groups/${action.payload.id}`, {
                method: 'PUT',
                body: JSON.stringify(pick(domainUsergroupToApi(action.payload), [ 'name' ])),
            })
                .then(r => {
                    if (!r.ok) {
                        console.error('error updating usergroup');
                    }
                });
            return state.map(usergroup =>
                usergroup.id === action.payload.id
                ? action.payload
                : usergroup
            );
        case USERGROUPS_ACTIONS.DELETE:
            return state.filter(usergroup => usergroup.id !== action.payload);
        default:
            return state;
    }
};
