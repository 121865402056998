import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import BrokenLinkIcon from '@axes4/react-icons/jsx/LinkBroken';
import { Layout } from '../App/Layout';

export const NotFound = () => {
    const { t } = useTranslation();
    return (
        <Layout pageTitle={t('notFound.pageTitle')} pageIcon={<BrokenLinkIcon/>}>
            <Card>
                <CardContent>
                    <Typography variant="h5" component="h2">{t('notFound.title')}</Typography>
                    <Typography paragraph variant="body1">{t('notFound.text')}</Typography>
                    <Button component={RouterLink} to="/" variant="contained" color="primary">{t('notFound.button')}</Button>
                </CardContent>
            </Card>
        </Layout>
    );
};
