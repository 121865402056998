import React, { useState } from 'react';
import { EmptyView } from '@axes4/react-common/src/components/ErrorHandling';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Box, makeStyles } from '@material-ui/core';
import ExclamationIcon from '@axes4/react-icons/jsx/SignWarning';
import logoSrc from '../../static/img/logo_axes4_64.png';
import { multilineTranslate } from '@axes4/react-common/src/util/i18n';

const useStyles = makeStyles(theme => ({
    logo: {
        width: theme.spacing(6),
        height: theme.spacing(6),
        marginRight: theme.spacing(2),
        transform: `translateY(${theme.spacing(1)}px)`,
    },
}));


/**
 * @typedef {{
 *   details?: React.ReactNode,
 *   children?: React.ReactNode,
 * }} ErrorPageProps
 */

/**
 * @param {ErrorPageProps} props
 */
export const ErrorPage = props => {
    const [ showDetails, setShowDetails ] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const mt = multilineTranslate(t);
    return (
        <EmptyView
            layoutProps={{ noDrawer: true }}
            icon={ExclamationIcon}
            title={
                <>
                    <img src={logoSrc} alt="axes4 logo" className={classes.logo} />
                    {t('errorPage.title')}
                </>
            }
        >
            <Box textAlign="center">
                <Typography paragraph>{mt('errorPage.description')}</Typography>
            </Box>
            {props.children}
            {props.details && (
                <div>
                    <Box textAlign="right">
                        <Button
                            variant="outlined"
                            onClick={() => setShowDetails(s => !s)}
                            aria-expanded={showDetails ? 'true' : 'false'}
                            aria-controls="errorDetails"
                        >
                            {showDetails ? t('errorPage.hideDetails') : t('errorPage.showDetails')}
                        </Button>
                    </Box>
                    <div id="errorDetails">
                        {showDetails && (
                            <Box marginTop={2}>
                                {props.details}
                            </Box>
                        )}
                    </div>
                </div>
            )}
        </EmptyView>
    );
};
