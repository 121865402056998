import React from 'react';
import { apiDateToDate } from '@axes4/react-common/src/util/date';
import { TFunction } from 'i18next';

export const uuidv4 = (): string => {
    return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c: any) =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  };

export const dateStringToDate = apiDateToDate;


type ObjWithTypedProperties<K extends string, T> = {
    [Key in K]: T
} & {
    [x: string]: unknown
};

/**
 * Transforms the given properties on a given object into Date objects
 */
export const apiDateToDateObjects = <O extends ObjWithTypedProperties<K, string>, K extends string = string>(properties: K[]) =>
        (object: O): ObjWithTypedProperties<K, Date> =>
            properties.reduce<ObjWithTypedProperties<K, string|Date>>((carry, propertyName) => ({
                ...carry,
                [propertyName]: carry[propertyName] ? dateStringToDate(carry[propertyName] as string) : null,
            }), object) as ObjWithTypedProperties<K, Date>;

export const arrayEquals = <T, >(a: T[], b: T[]): boolean =>
    a.every((x, i) => b[i] === x);

export const multilineTranslate = (t: TFunction) => (...args: Parameters<TFunction>) => t.apply(t, args).split('\n').reduce((r, c, x) => r ? [ ...r, <br key={x}/>, c ] : [ c ], null);

export const getBaseUrl = (): string => `${location.protocol}//${location.host}`;
