import { LICENSE_MAPPING_ACTIONS, setLicenseMappingsAction } from '../actions/licenses';
import { handledRequest } from '../../hooks/api';
import { fetchCompleteAction, addLoadingErrorAction } from '../actions/application';
import { apiLicenseAssignmentToDomain } from '../transformations/licenses';
import { USERS_ACTIONS } from '../actions/users';
import { USERGROUPS_ACTIONS } from '../actions/user-groups';

/**
 * @param {import('../../types/licenses').LicenseMapping[]} state
 * @param {number} licenseId
 * @param {import('../../types/licenses').LicenseUserType} licenseUserType
 * @param {import('../../types/license.ts').LicenseMappingUsers} data
 * @returns {import('../../types/licenses').LicenseMapping[]}
 */
const updateLicenseMapping = (state, licenseId, licenseUserType, data) => {
    const existingLicense = state.find(license => license.licenseId === licenseId);
    let newLicense = existingLicense ? { ...existingLicense } : { licenseId };
    if (!(licenseUserType in newLicense)) {
        newLicense[licenseUserType] = {};
    }
    newLicense[licenseUserType] = data;
    if (existingLicense) {
        return state.map(license => license.licenseId === licenseId ? newLicense : license);
    } else {
        return [ ...state, newLicense ];
    }
};

/**
 * @param {import('../../types/licenses').LicenseMapping[]} state
 * @param {import('../../types/licenses').LicenseMapping} mapping
 * @returns {import('../../types/licenses').LicenseMapping[]}
 */
const overwriteLicenseMapping = (state, mapping) => {
    const licenseUuid = mapping.licenseUuid;
    let found = false;
    const newState = state.map(m => m.licenseUuid === licenseUuid && (found = true) ? mapping : m);
    return found ? newState : newState.concat([ mapping ]);
};

/**
 * @param {import('../../types/licenses').LicenseMapping[]} state
 * @param {number} licenseId
 * @param {import('../../types/licenses').LicenseUserType} licenseUserType
 * @param {import('../../types/util').Uuid} licenseUserId
 * @param {import('../../types/licenses').LicenseModule[]} modules
 */
const addLicenseMappingUser = (state, licenseId, licenseUserType, licenseUserId, modules = []) => {
    const existingLicense = state.find(license => license.licenseId === licenseId);
    let newLicense = existingLicense ? { ...existingLicense } : { licenseId };
    if (!(licenseUserType in newLicense)) {
        newLicense[licenseUserType] = {};
    }
    if (!(licenseUserId in newLicense[licenseUserType])) {
        newLicense[licenseUserType][licenseUserId] = modules;
    }
    if (existingLicense) {
        return state.map(license => license.licenseId === licenseId ? newLicense : license);
    } else {
        return [ ...state, newLicense ];
    }
};

/**
 * @param {import('../../types/licenses').LicenseMapping[]} state
 * @param {any} action
 */
export const licenseMapping = (state = [], action) => {
    switch (action.type) {
        case USERS_ACTIONS.DELETE:
            return state.map(mapping => ({
                ...mapping,
                users: mapping.users.filter(licenseUser => licenseUser.licenseUser.toString() !== action.payload.toString()),
            }));
        case USERGROUPS_ACTIONS.DELETE:
            return state.map(mapping => ({
                ...mapping,
                usergroups: mapping.usergroups.filter(licenseUser => licenseUser.licenseUser.toString() !== action.payload.toString()),
            }));
        case LICENSE_MAPPING_ACTIONS.INITIALIZE:
            handledRequest(`licensing:/accounts/${action.payload.accountId}/license-assignments`)
                .then(data => {
                    if (data) {
                        action.asyncDispatch(setLicenseMappingsAction(data.map(apiLicenseAssignmentToDomain)));
                    } else {
                        action.asyncDispatch(setLicenseMappingsAction([]));
                    }
                    action.asyncDispatch(fetchCompleteAction());
                })
                .catch(e => {
                    action.asyncDispatch(addLoadingErrorAction('loading.errorFetching', { resource: '"license-assignments"', status: e.statusCode }));
                    action.asyncDispatch(setLicenseMappingsAction([]));
                    // action.asyncDispatch(fetchCompleteAction());
                });
            return state;
        case LICENSE_MAPPING_ACTIONS.SET:
            return action.payload;
        case LICENSE_MAPPING_ACTIONS.OVERWRITE:
            return overwriteLicenseMapping(state, action.payload);
        case LICENSE_MAPPING_ACTIONS.UPDATE:
            return updateLicenseMapping(state, action.payload.licenseId, action.payload.type, action.payload.data);
        case LICENSE_MAPPING_ACTIONS.ADD_USER:
            return addLicenseMappingUser(state, action.payload.licenseId, action.payload.licenseUserType, action.payload.licenseUserId, action.payload.modules);
        default:
            return state;
    }
};
