module.exports = {
    codes: {
        200: 'Success',
        2011: 'No matching license found.',
        3000: 'Unauthenticated.',
        3001: 'Unauthorized.',
        3010: 'Account not found.',
        3011: 'Invalid account name.',
        3020: 'User group not found.',
        3022: 'Invalid user group name.',
        3023: 'User group name already exists.',
        3030: 'User not found.',
        3031: 'Could not send invitation email.',
        3032: 'A user can\'t delete himself.',
        3033: 'User with same OAuth2-UserId already exists.',
        3034: 'Deactivation of an agent user is not possible.',
        3040: 'Invitation parameter missing.',
        3041: 'Invitation parameter invalid.',
        3042: 'Invitation not found.',
        3043: 'A user with this email address is already existent in this account.',
        3044: 'Invalid email address.',
        3051: 'License not compatible with account type.',
        3052: 'License not found.',
        3053: 'Module not found in license.',
        3054: 'License key not available.',
        3056: 'The time difference between client and server is too large.',
        3058: 'License not valid.',
        3061: 'User group referenced multiple times.',
        3062: 'User referenced multiple times.',
        3063: 'License not assigned to user.',
        3070: 'Role not found.',
        3071: 'A user can\'t remove role assignments from himself.',
        3072: 'A user can\'t assign roles to himself.',
        3073: 'User already has role.',
        3074: 'Can\'t remove role on user which he doesn\'t have.',
        3080: 'UserId was set to empty which is not allowed and may be a sign of misconfiguration.',
        3081: 'Max. number of devices reached for license.',
        3082: 'Max. number of users reached for license.',
        3083: 'Max. number of devices reached for user and license.',
        3084: 'Attempt to activate an unassigned license module.',
        3085: 'Error while parsing license activation request.',
        3090: 'Deactivation limit reached for the user for license <strong>{{license}}</strong>.',
        3091: 'Could not deactivate because no activations were found.',
        3501: 'License does not support deactivation.',
        403: 'Unauthorized.',
        404: 'The resource could not be found.',
        500: 'An internal server error happened.',
        5011: 'License not found.',
        5021: 'Account not found.',
        6000: 'An unexpected error occurred.',
        6001: 'You are not a member of the specified domain.',
        6002: 'The Active Directory group could not be found.',
        6003: 'You are not a member of the specified Active Directory group.',
        6004: 'The Active Directory group contains more users than your license allows.',
        99: 'An error happened.',
    },
    common: {
        originalError: 'Die interne Fehlermeldung lautet: {{message}}',
    },
};
